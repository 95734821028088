
<!-- <app-header-five></app-header-five> -->
<!-- <app-header-three></app-header-three> -->
<app-header-one></app-header-one>

    <!-- Carousel Start -->
    <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner" *ngFor="let head of headers">
          <div class="carousel-item active" *ngIf="head.active">
            <img class="w-100 " src="{{environment.baseApiStrapi}}{{head.image.data.attributes.formats.small.url}}" alt="Image" />
            <div class="carousel-caption bgO">
              <div class="container">
                <div class="row responsivite1">
                  <div class="col-12 col-lg-6">
                    <div class="resp">
                        <h1 class="display-3 colorTitle mb-4 animated slideInDown p-1">
                            {{head.text}}
                        </h1>
                        <p class="tailleText text-white p-1">
                            {{head.sousTitre}}
                        </p>
                    </div>
                    <a href="" class="default-btn btnChange resp"
                      >{{head.bouton.label}}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="!head.active">
            <img class="w-100" src="{{environment.baseApiStrapi}}{{head.image.data.attributes.formats.small.url}}" alt="Image" />
            <div class="carousel-caption bgO">
              <div class="container">
                <div class="row responsivite1">
                  <div class="col-12 col-lg-6">
                    <div class="resp">
                        <h1 class="display-3 colorTitle mb-4 animated slideInDown p-1">
                            {{head.text}}
                        </h1>
                        <p class="tailleText text-white p-1 ">
                            {{head.sousTitre}}
                        </p>

                    </div>
                    <a href="" class="default-btn btnChange resp"
                      >{{head.bouton.label}}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="carousel-item">
            <img class="w-100" src="/assets/image/img/carousel-22.png" alt="Image" />
            <div class="carousel-caption bgO">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="">
                        <h1 class="display-3 text-white mb-4 animated slideInDown p-1">
                            Former
                        </h1>
                        <p class="fs-5 text-white mb-5 p-1 ">
                            Digit Conseil International vous aide à acquérir les compétences
                            nécessaires pour vous adapter aux changements du marché et
                            maximiser votre performance.
                        </p>

                    </div>
                    <a href="" class="default-btn py-3 px-5"
                      >Plus de détails</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img class="w-100" src="/assets/image/img/carousel-21.png" alt="Image" />
            <div class="carousel-caption bgO">
              <div class="container">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <div class="">
                        <h1 class="display-3 text-white mb-4 animated slideInDown p-1">
                            Assurer
                        </h1>
                        <p class="fs-5 text-white mb-5 p-1 ">
                            Digit Conseil International est un spécialiste du conseil en assurance et
                            en réassurance. Notre objectif est d’optimiser les couvertures et le
                            budget en assurance de nos clients et partenaires.
                        </p>
                    </div>
                    <a href="" class="default-btn py-3 px-5"
                      >Plus de détails</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- Carousel End -->

    <!-- About Start -->
    <div class="container-xxl py-5" id="apropos" *ngIf="apropos?apropos.active:false">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div
              class="position-relative overflow-hidden rounded ps-5 pt-5data-wow-delay h-100"
              style="min-height: 400px"
            >
              <img
                class="position-absolute w-100 h-100"
                src="{{environment.baseApiStrapi}}{{apropos.propos.image.data.attributes.formats.small.url}}"
                alt=""
                style="object-fit: cover"
              />
              <div
                class="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3"
                style="width: 200px; height: 200px"
              >
                <div
                  class="d-flex flex-column justify-content-center text-center bg-vert rounded h-100 p-3"
                >
                  <h1 class="text-white mb-0">{{apropos.propos.anExperience}}</h1>
                  <h2 class="text-white">Ans</h2>
                  <h5 class="text-white mb-0">Expériences</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="h-100">
              <h1 class="display-6 mb-5 color">
                {{apropos.propos.titre}}
              </h1>
              <div>
                  <p class="fs-10 text-black mb-3" [innerHTML]="apropos.propos.text">
                  </p>
              </div>
              <div class="row g-4 mb-4">
                <div class="col-sm-6">
                  <div>

                    <div class="d-flex align-items-center mb-2">
                        <div class="bg p-2">
                            <img
                              class="flex-shrink-0"
                              src="{{environment.baseApiStrapi}}{{apropos.propos.icon1.data.attributes.url}}"
                              alt="" style="width: 50px; height: 50px;"
                            />
                        </div>
                      <h5 class="mb-0 text-black m-2">{{apropos.propos.label1}}</h5>
                    </div>
                  </div>
                  <div>

                    <div class="d-flex align-items-center mb-2">
                        <div class="bg p-2">
                            <img
                              class="flex-shrink-0"
                              src="{{environment.baseApiStrapi}}{{apropos.propos.icon2.data.attributes.url}}"
                              alt="" style="width: 50px; height: 50px;"
                            />
                        </div>
                      <h5 class="mb-0 text-black m-2">{{apropos.propos.label2}}</h5>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-sm-6">
                </div> -->
              </div>
              <!-- <h2 class="display-6 mb-5 color">
                Notre Vision
              </h2>
              <p class="mb-4 text-black">
                {{app.dci.vision}}
              </p> -->
              <div class="border-top mt-4 pt-4">
                <div class="d-flex align-items-center">
                  <img
                    class="flex-shrink-0 rounded-circle me-3"
                    src="{{environment.baseApiStrapi}}{{apropos.propos.profil.data.attributes.url}}"
                    alt=""
                  />
                  <h5 class="mb-0 text-black">Appelez-nous: {{telephone}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->

    <!-- Facts Start -->
    <div class="container-fluid overflow-hidden my-5 px-lg-0" id="valeurs"  *ngIf="mission_bloc?mission_bloc.active:false">
      <div class="container facts px-lg-0">
        <div class="row g-0 mx-lg-0">
          <div class="col-lg-5 facts-text wow fadeIn" data-wow-delay="0.5s" style="position: relative;">
            <div class="h-75" style="position: absolute;">
                <img
                class="borderImg"
                src="{{environment.baseApiStrapi}}{{mission_bloc.mission.image.data.attributes.formats.small.url}}"
                alt=""
                style="height: 100%;"
              />
            </div>
          </div>
          <div class="col-lg-7 facts-counter wow fadeIn" data-wow-delay="0.5s">
            <div class="h-100 px-2 pe-lg-0" style="margin-left: 50px;padding-top: 2rem;padding-bottom: 1rem;">
              <div class="row g-5">
                <h1 class="text-white mb-1">Notre Mission</h1><span class="borderBottom"></span>
              <p class="text-light mb-2 fs-6" style="margin-top: 20px;">
                {{mission_bloc.mission.text}}
              </p>
                <h1 class="text-white mb-1">Nos Valeurs</h1><span class="borderBottom"></span>
                <ul style="list-style-type:none; margin-top: 20px;">
                    <li *ngFor="let valeur of mission_bloc.mission.valeurs" class="text-white mb-2">
                        <h3 class="mb-0 text-white"> <img src="{{environment.baseApiStrapi}}{{valeur.icon.data.attributes.url}}" alt="">{{valeur.titre}}</h3>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Facts End -->

    <div class="container-fluid py-5" id="services" *ngIf="service_bloc?service_bloc.active:false">
        <div class="">
          <div class="">
            <div class="" data-wow-delay="0.3s">
                <div id="header">
                    <h1 class="color mb-2">{{service_bloc.titre}}</h1>
                </div>
                <div class="" style="display: flex; align-items: center; justify-content: center;">
                    <div class="row">
                    <div *ngFor="let service of service_bloc.service" class="card col-lg-3 col-sm-6 wow fadeIn" [attr.data-wow-delay]="service.time">
                        <div class="card-image">
                            <img src="{{environment.baseApiStrapi}}{{service.image.data.attributes.formats.small.url}}">
                        </div>
                        <div class="card-text">
                            <div class="card-meal-type bg mb-3"><img
                                style="width: 50px;height: 50px;"
                                src="{{environment.baseApiStrapi}}{{service.icon.data.attributes.url}}"
                                alt=""
                            /></div>
                            <h2 class="card-title">{{service.titre}}</h2>
                            <p class="card-body">{{service.description}}</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    <!-- Features End -->

    <!-- Appointment Start -->
    <div class="container-fluid appointment my-5 py-3 wow fadeIn" data-wow-delay="0.3s" *ngIf="rendez_vous?rendez_vous.active:false">
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-7 wow fadeInRight" data-wow-delay="0.5s">
            <div>
                <h1 class="display-6 text-white">
                    {{rendez_vous.titre}}
                </h1>
                <p class="d-flex mb-5">
                    <span class="borderBottom" style="margin-left: 0px;width: 250px;"></span>
                </p>
            </div>
            <div>
                <p class="text-white mb-3 fs-5" *ngFor="let text of rendez_vous.textes">
                    {{text.text}}
                </p>
            </div>
            <div class="rounded">
                <button class="default-btn py-2 px-3 btnChange" type="submit" style="margin-left: 0px; margin-top: 50px;">
                    <img src="{{environment.baseApiStrapi}}{{rendez_vous.bouton.icon.data.attributes.url}}" alt="" style="width: 20px;">
                    {{rendez_vous.bouton.label}}
                </button>
            </div>
          </div>
          <div class="col-lg-5 wow fadeInLeft" data-wow-delay="0.5s">
            <div class="bg-white py-4 px-3 borderRaduis">
              <form>
                <div class="row g-3">
                  <div class="col-12 mt-5">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control inputForm"
                        id="gname"
                        placeholder="Gurdian Name"
                      />
                      <label for="gname">Votre Nom</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <input
                        type="email"
                        class="form-control inputForm"
                        id="gmail"
                        placeholder="Gurdian Email"
                      />
                      <label for="gmail">Votre Email</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control inputForm"
                        id="cname"
                        placeholder="Child Name"
                      />
                      <label for="cname">Votre Téléphone</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control inputForm"
                        id="cage"
                        placeholder="Child Age"
                      />
                      <label for="cage">Type de Service</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <textarea
                        class="form-control inputForm"
                        placeholder="Leave a message here"
                        id="message"
                        style="height: 130px"
                      ></textarea>
                      <label for="message">Message</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <button class="default-btn py-3 px-5 btnChange" style="margin-left: 0px;" type="submit">
                        Obtenir un rendez-vous
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Appointment End -->
    <!-- <div class="container-xxl py-5" id="team">
        <div class="container">
          <div class="text-center mx-auto" style="max-width: 500px">
            <h1 class="display-6 mb-5 color">
              Rencontrez les membres de notre équipe professionnelle</h1>
          </div>
          <div class="row g-4">
            <div class="col-lg-3 col-md-6 wow fadeInUp" *ngFor="let membre of app.dci.membres;let i = index" data-wow-delay="0.3s">
                <div class="card col-lg-3 col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                    <div class="card-image">
                        <img src="/assets/image/img/p2.jpeg">
                    </div>
                    <div class="card-text">
                        <p class="card-meal-type"><img style="margin-top: -50px;"
                            class="p-2 bg rounded-circle"
                            src="/assets/image/img/icon/icon-06-light.png"
                            alt=""
                        /></p>
                        <h2 class="card-title">INNOVER</h2>
                        <p class="card-body">Digit Conseil International s’engage à rester en tête de l'innovation technologique, afin de proposer à nos clients et partenaires des solutions novatrices et personnalisées qui répondent à leurs besoins. Notre approche résolument avant-gardiste nous permet de rester à l'avant-garde du progrès, assurant ainsi une valeur ajoutée exceptionnelle à nos services.</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- Team Start -->
    <div class="container-xxl py-5" id="team" *ngIf="membres_bloc?membres_bloc.active:false">
      <div class="container">
        <div class="text-center mx-auto" style="max-width: 500px">
          <h1 class="display-6 mb-5 color">
            Rencontrez les membres de notre équipe professionnelle</h1>
        </div>
        <div class="row g-4">
          <div class="col-lg-3 col-md-6 wow fadeInUp" *ngFor="let membre of membres_bloc.membres" [attr.data-wow-delay]="membre.time">
            <div class="team-item rounded">
              <!-- <img class="img-fluid" src="{{environment.baseApiStrapi}}{{membre.image.data.attributes.formats.thumbnail.url}}" alt="" /> -->
              <img class="img-fluid" src="{{environment.baseApiStrapi}}{{membre.image.data.attributes.url}}" alt="" />
              <div class="text-center p-4">
                <h5>{{membre.nom}}</h5>
                <span>{{membre.fonction}}</span>
              </div>
              <div class="team-text text-center bg-white p-4">
                <h5>{{membre.nom}}</h5>
                <p>{{membre.fonction}}</p>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-square btn-light m-1" target="_blank" *ngFor="let url of membre.reseauSociaux" href={{url.url}}
                    ><i class={{url.icon}}></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Team End -->
    <section class="feedback-area ptb-100 wow slideInRight" data-wow-duration="1s" data-wow-delay="1s" id="commentaires" *ngIf="isCommentaire">
        <div class="container">
            <div class="section-title">
                <span class="sub-title text-black">
                    Nos commentaires</span>
                <h2>Ce que dit le client</h2>
                <p>{{app.dci.commentaire[0].text}}</p>
            </div>

            <div class="feedback-slides">
                <div class="client-feedback">
                    <div>
                        <div class="item" *ngFor="let commentaire of app.dci.commentaire">
                            <div class="single-feedback">
                                <p>“{{commentaire.text}}”</p>
                            </div>
                        </div>

                    </div>

                    <button class="prev-arrow slick-arrow">
                        <i class='flaticon-left-chevron'></i>
                    </button>

                    <button class="next-arrow slick-arrow">
                        <i class='flaticon-right-chevron'></i>
                    </button>
                </div>

                <div class="client-thumbnails">
                    <div>
                        <div class="item" *ngFor="let client of app.dci.commentaire">
                            <div class="img-fill"><img src="{{client.image}}" alt="client"></div>
                            <div class="title">
                                <h3>{{client.nom}}</h3>
                                <span class="text-black">{{client.fonction}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="partner-area wow slideInLeft" data-wow-duration="1s" data-wow-delay="1s" id="partenaires" *ngIf="isPartenaire">
        <div class="container">
            <div class="partner-title">
                <h1 class="partenaire color">Nos Partenaires</h1>
            </div>
            <div class="partner-slides owl-carousel owl-theme">
                <div class="single-partner-item" *ngFor="let partenaire of app.dci.parteniares">
                    <a href={{partenaire.url}} target="_blank">
                        <img src="{{partenaire.image}}" alt="image" class="i">
                    </a>
                </div>
            </div>
        </div>
    </section>
