import { Component, OnInit } from '@angular/core';
import { CONSTANTES } from 'src/app/shared/model/constantes';
import { APP } from 'src/app/shared/model/model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    app = APP;
  logoImage = `/assets/image/img/Digit conseil logo/Logo Jpeg/CMJK pour l\'impression/${this.app.dci.logo}`;;
  constantes = CONSTANTES;
  constructor() { }

  ngOnInit(): void {
  }

}
