import {Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, TemplateRef,} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {SubscriptionService} from 'src/app/shared/service/subscription.service';
import {environment} from 'src/environments/environment';
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WizardComponent} from 'angular-archwizard';
import {UtilsService} from 'src/app/shared/service/utils.service';
import {Console} from 'console';
import {min} from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import {interval} from 'rxjs';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import {animate, style, transition, trigger} from '@angular/animations';
import * as moment from 'moment';
import {AccountService} from 'src/app/shared/service/account.service';
import {GenererPdfService} from "../../../shared/service/generer-pdf.service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { CONSTANTES } from 'src/app/shared/model/constantes';
import { APP } from 'src/app/shared/model/model';

@Component({
  selector: 'app-declaration-sinistre-new',
  templateUrl: './declaration-sinistre-new.component.html',
  styleUrls: ['./declaration-sinistre-new.component.scss'],
  animations: [
    trigger('fade', [
        transition('void => *', [
            style({opacity: 0}),
            animate(2500)
        ]),
        transition('* => void', [
            animate(2500),
            style({opacity: 0}),
        ])
    ]),
    trigger("slideInOut", [
        transition('void => *', [
            style({transform: "translateX(60%)", opacity: 0}),
            animate(
                "1500ms ease-in",
                // style({ transform: "translateX(0%)", opacity: 1 })
            ),
        ]),
        transition('* => void', [
            animate(
                "1500ms ease-out",
                // style({ transform: "translateX(60%)", opacity: 0 })
            ),
        ]),
    ]),
]

})
export class SinistresNewComponent implements OnInit  {
    app = APP;
    logoImage = `/assets/image/img/Digit conseil logo/Logo Jpeg/CMJK pour l\'impression/${this.app.dci.logo}`;;

 listAssurance = [
    {id:"ASSURANCE AUTOMOBILE", libelle:"ASSURANCE AUTOMOBILE"},
    {id:"ASSURANCE SANTE", libelle:"ASSURANCE SANTE"},
    {id:"ASSURANCE VIE", libelle:"ASSURANCE VIE"},
    {id:"ASSURANCE MRH", libelle:"ASSURANCE MRH"},
    {id:"ASSURANCE VOYAGE", libelle:"ASSURANCE VOYAGE"}
]
listGenre = [
    { name: 'Masculin', value: 'Masculin' },
    { name: 'Féminin', value: 'Féminin' },
  ];
  listTypePiece = [
    { name: 'CNI', value: 'CNI' },
    { name: 'CEDEAO', value: 'CEDEAO' },
    { name: 'PASSEPORT', value: 'PASSEPORT' }
  ];
  listTypeSinistre = [
    { name: 'DECES', value: 'DECES' },
    { name: 'INVALIDITE', value: 'INVALIDITE' },
  ];
  sendingRequest: boolean = false;
  model: any[];
  pwd = 'password';

  apiResponse: any = {
      title: '',
      text: '',
      type: ''
  };
  login: boolean = false;
  garantie: any = [];
  beneficiaryChoosen: boolean = false;
  paymentMethod: string = "Paiement par agence";
  beneficiaries = [];
  avalaibleDuration = [];
  paymentMethods = [];
  tmpPaymentMethods = [];
  numeroSous = "";
  refPro = "";
  initPaymentForm = {
      requestId: 0,
      numeroSouscription: '',
      status: '',
      montant: '',
  }
  nom;
  dateExpiration: any;
  // sendingRequest: boolean = false;
  assure: number = 1;
  phoneMask: string = "00 00 000 000";
  cinMask: string = "0 000 0000 00000";
  dateTime = Math.floor(Date.now() / 1000);
  today: any = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
  };
  date: any;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  sinistreForm = new FormGroup({
       declarant: new FormGroup({
         typeAssurance: new FormControl("", [Validators.required]),
          prenomDeclarant: new FormControl("", [Validators.required]),
          nomDeclarant: new FormControl("", [Validators.required]),
          dateNaissance: new FormControl(""),
          lieuNaissance: new FormControl(""),
          sexe: new FormControl("", [Validators.required]),
          typeDePiece: new FormControl("", [Validators.required]),
          numeroDePiece: new FormControl("", [Validators.required]),
          dateDexpiration: new FormControl("", [Validators.required]),
          adresseDeclarant: new FormControl("", [Validators.required]),
          telephoneDeclarant: new FormControl(""),
          email: new FormControl(""),
          profession: new FormControl(""),
          numImmat: new FormControl("", [Validators.required]),
          utilvehicul: new FormControl("", [Validators.required]),
          dateVisitetechnik: new FormControl("", [Validators.required]),
          numeroPolice: new FormControl("", [Validators.required]),
      }),
      sinistre: new FormGroup({
        typeSinistre: new FormControl(""),
        dateSinistre: new FormControl(""),
        details: new FormControl(""),
        domat: new FormControl(""),
        declaration: new FormControl(""),
        domcorporel: new FormControl(""),
        declarationCorporel: new FormControl("")
      }),
      sinistre1: new FormGroup({
        dateAccident: new FormControl(""),
        lieuAccident: new FormControl(""),
        circonstance: new FormControl("")
      }),
      sinistre2: new FormGroup({
        tiers: new FormControl(""),
        nomPropr: new FormControl(""),
        nomConduc: new FormControl(""),
        compagnie: new FormControl(""),
        marque: new FormControl(""),
        modele: new FormControl(""),
        immatProp: new FormControl(""),
        autreModele: new FormControl(""),
        autreMarque: new FormControl(""),

      })
  });
  forgotForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),

  });
  loginform = new FormGroup({
      username: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
  });
  inscriptionForm = new FormGroup({
      tel: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.pattern(this.emailPattern)]),
  });
  listJours = [{name: 'Tous les jours'}, {name: 'Lundi'}, {name: 'Mardi'}, {name: 'Mercredi'}, {name: 'Jeudi'}, {name: 'Vendredi'}];
  listHeures = [{name: "08h-10h"}, {name: "10h-12h"}, {name: "12h-14h"}, {name: "14h-16h"}, {name: "16h-18h"}];
  valeur: any;
  capital: any;
  prix: any;
  granddevis: any;
  _idAssurance: string;
  _typeAssurance: string;
  tabElementMaison = [{
      name: "Valeur du batiment",
      value: "valeur_batiment",
      type: "montant"
  }, {name: "Valeur du contenu", value: "valeur_contenu", type: "montant"}, {
      name: "Surface développée en m2",
      value: "surface",
      type: "text"
  }, {name: "Situation risque", value: "situation_risque", type: "text"}, {
      name: "Date début contrat",
      value: "effet",
      type: "date"
  }];
  tabElementAssure = [{name: "Prénom", value: "prenom", type: "text"}, {
      name: "Nom",
      value: "nom",
      type: "text"
  }, {name: "Date de naissance", value: "dateNaissance", type: "date"}, {
      name: "Numéro téléphone",
      value: "numeroTelephone",
      type: "text"
  }, {name: "Numéro CNI", value: "numeroCni", type: "text"}, {
      name: "Email",
      value: "email",
      type: "text"
  }, {name: "Adresse", value: "adresse", type: "text"}];
  mrh: boolean = false;
  mri: boolean = false;
  datevalid: boolean = false;
  pack: any = [];
  listModeles: any = [];
  @ViewChild('contentResult', {static: false}) contentResult: any;
  @ViewChild('contentResultcontact', {static: false}) contentResultmail: any;
  @ViewChild('updateAssures', { static: false }) modalAssure: any;
  @ViewChild('updateMaison', { static: false }) modalMaison: any;
  @ViewChild('contentResultass') contentResultass: any;
  @ViewChild('mrhDownload') mrhDownload: ElementRef;
  @ViewChild('btnDownload') btnDownload: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('contentResultforgot', {static: false}) modaforgot: any;
  @ViewChild('contentResultlogin', {static: false}) modalog: any;
  @ViewChild('contentResultsign', {static: false}) modasign: any;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('wizard', {static: false}) wizard: WizardComponent;
  @ViewChild('devisAEnvoye', { static: false }) devisAEnvoye: any;
  result: any = {};
  resultmrh: any = [];
  subPackag: any = {};
  packofpack: any = [];
  packmrh: any;
  Datemin: any;
  Datemax: any;
  assureur: any;
  resultass: any = [];
  invalid: boolean;
  base: any;
  devisend: boolean = false;
  domicilier: boolean;
  paiment: boolean = false;
  loaderVisible: boolean;
  loader: boolean = false;
  CERTIFICAT_DECES = "assets/images/noImage.png";
  CERTIFICAT_GENRE_MORT = "assets/images/noImage.png";
  RAPPORT_MEDICAL = "assets/images/noImage.png";
  RAPPORT_POLICE_OU_GENDARMERIE = "assets/images/noImage.png";
  CONSTAT = "assets/images/noImage.png";
  PHOTOS = "assets/images/noImage.png";
  listImages = [];
  listImagesSante = [];
  listImagesPhotos = [];
  userInconnu: string;
  gomrh: boolean;
  gomri: boolean;
  flotte: boolean = false;
  individuel: boolean = false;
  user: any;
  tokenid: any;
  listAgences: any = [];
  currentSouscripteur = "Pour moi-même";
  currentDom = "activeLivraison";
  currentRel = "";
  currentGarantie = "";
  currentAssureur:any = "";
  loadContact: boolean = false;
  newDate = new Date();
  sendingRequestAssureur: boolean = false;
  datas: any;
  detailsPdf: any;
  message:any;
  uuId: any;
  pointRelais: any;
  currentId;
  assureurId;
  primeAss:any;
  updateRequest: boolean = false;
  assureurAEnvoye;
  vehiculeCategories = [];
  isSouscription:boolean;
  file;
  titleFile;

  @ViewChild('closeBTN') closeBTN: ElementRef;
  noAssureur: boolean = false;
  natureLogement: boolean = true;
  fileList: any = [];
  loaderImage: boolean = false;
  constantes = CONSTANTES;


  // resultmrh: any;
  constructor(private subscriptionService: SubscriptionService,
              private location: Location,
              private route: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              private utilsService: UtilsService,
              private accountService: AccountService,
              private genererPdfService: GenererPdfService,
              private domSanitizer:DomSanitizer) {

      this.datas = this.route.snapshot.params['data'];
      this.route.queryParams.subscribe(params => {
          this.datas = params.data;
      });
  }

    // Settings configuration
mySettings: IMultiSelectSettings = {
  enableSearch: true,
  checkedStyle: 'fontawesome',
  buttonClasses: 'btn btn-default btn-block',
  dynamicTitleMaxItems: 3,
  displayAllSelectedText: true
};

// Text configuration
myTexts: IMultiSelectTexts = {
checkAll: 'Selectionner Tout',
uncheckAll: 'Décocher Tout',
checked: 'Element Selectionné',
checkedPlural: 'Element Selectionné',
searchPlaceholder: 'Chercher',
searchEmptyResult: 'Aucun elément trouvé...',
searchNoRenderText: 'Type in search box to see results...',
defaultTitle: 'Select',
allSelected: 'Tout selectionné',
};

  get idAssurance() {
      return this._idAssurance;
  }

  @Input()
  set idAssurance(id) {
      this._idAssurance = id;
      // this.fireFunctions();
  }

  get typeAssurance() {
      return this._typeAssurance;
  }

  @Input() set typeAssurance(type: string) {
      this._typeAssurance = type;
  }

  ngOnInit(): void {
      this.genererPdfService.genererPdf(this.datas).subscribe((resp) => {
          this.detailsPdf = resp['data'];
          let id_ass = this.detailsPdf.assureur.id;
          let ass = this.detailsPdf.assureur;
      });
      this.user = JSON.parse(localStorage.getItem('microFinance'));
      if (this.user)
          this.tokenid = this.user.id_token;
      let time = new Date().getTime();
      let pro = new Date().getTime() + 123890989;
      this.numeroSous = "SN" + time + "SC";
      this.refPro = "SN" + pro + "PR";
      this.getModelvoiture();

      this.date = this.today.day + '/' + this.today.month + '/' + this.today.year;
      // let data = (this.today.day + '/' + this.today.month + '/' + this.today.year);
      // this.mrhForm.get('packForm1').get('effet').setValue(new Date());
  }



  DeleteConfirm(i: number,type) {
    Swal.fire({
      title: 'Confirmation',
      text: 'Voulez-vous vraiment supprimer ce fichier ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Valider',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result["value"] == true) {
        if(type == 'constat'){
            this.listImages.splice(i, 1);
        }else if(type == 'photo'){
            this.listImagesPhotos.splice(i, 1);
        } else if(type = 'sante'){
            this.listImagesSante.splice(i, 1);
        }

      }
    });
  }
  //cette fonction permet de recuperer un fichier selectionner et de le convertir en base 64
  OnFileSelect(evt, type, name) {
    this.loader = true;
    const accept = ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX', '.docm', '.DOCM'];
    for (const file of evt.target.files) {
      const index = file.name.lastIndexOf(".");
      const strsubstring = file.name.substring(index, file.name.length);
      let ext = strsubstring;

      if (accept.indexOf(strsubstring) === -1) {
      } else {
        this.loader = true;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const docBase64Path = e.target.result;

          this[type + '_VALUE'] = docBase64Path;
          let image = '';
          if (ext === '.png' || ext === '.PNG' || ext === '.jpg' || ext === '.JPG' || ext === '.jpeg' || ext === '.JPEG') {
            image = docBase64Path;
          } else if (ext === '.pdf' || ext === '.PDF') {
            image = 'assets/images/pdf.png';
          } else if (ext === '.doc' || ext === '.DOC' || ext === '.docx' || ext === '.DOCX' || ext === '.docm' || ext === '.DOCM') {
            image = 'assets/images/doc.png';
          } else {
            image = docBase64Path;
          }
          this.saveFile1(evt.target.files[0]);
        };
        reader.readAsDataURL(file);
      }
    }

  }

  //cette fonction permet de recuperer un fichier selectionner et de le convertir en base 64
  selectOnFile(evt, type, name) {
    this.nom = name;
    this.loader = true;
    const accept = ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.pdf', '.PDF', '.doc', '.DOC', '.docx', '.DOCX', '.docm', '.DOCM'];
    for (const file of evt.target.files) {
      const index = file.name.lastIndexOf(".");
      const strsubstring = file.name.substring(index, file.name.length);
      let ext = strsubstring;

      if (accept.indexOf(strsubstring) === -1) {
        // this.snackBar.open("Ce fichier " + file.name + " n'est pas fichier au format doc ou pdf", 'OK', {
        //   verticalPosition: 'bottom',
        //   duration: 5000,
        //   panelClass: ['mycssSnackbarRed']
        // });
      } else {
        this.loader = true;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const docBase64Path = e.target.result;
          this[type + '_VALUE'] = docBase64Path;
          let image = '';
          if (ext === '.png' || ext === '.PNG' || ext === '.jpg' || ext === '.JPG' || ext === '.jpeg' || ext === '.JPEG') {
            image = docBase64Path;
          } else if (ext === '.pdf' || ext === '.PDF') {
            image = 'assets/images/pdf.png';
          } else if (ext === '.doc' || ext === '.DOC' || ext === '.docx' || ext === '.DOCX' || ext === '.docm' || ext === '.DOCM') {
            image = 'assets/images/doc.png';
          } else {
            image = docBase64Path;
          }
          if(name === 'CONSTAT'){
            this.listImages.push(docBase64Path);
          }else if(name === 'PHOTOS'){
            this.listImagesPhotos.push(docBase64Path);
          } else if(name === 'CERTIFICAT DE DECES' || name ==='CERTIFICAT DE GENRE MORT' || name ==='RAPPORT MEDICAL' || name ==='RAPPORT POLICE OU GENDARMERIE' ){
            this.listImagesSante.push(docBase64Path);
          }
          this.saveFile1(evt.target.files[0],type);
        };
        reader.readAsDataURL(file);
      }
    }
  }

    //cette fonction permet de stocker un fichier sur le server
    saveFile1(file, type?, image?) {
        this.loaderImage = true
        this.loader = false;
        if (type === 'CERTIFICAT_DECES') {
            // this.CERTIFICAT_DECES == image;
          }else if (type === 'CERTIFICAT_GENRE_MORT') {
            // this.CERTIFICAT_GENRE_MORT = image;
          }else if (type === 'RAPPORT_MEDICAL') {
            // this.RAPPORT_MEDICAL = image;
          }else if (type === 'RAPPORT_MEDICAL') {
            // this.RAPPORT_MEDICAL = image;
          }else if (type === 'RAPPORT_POLICE_OU_GENDARMERIE') {
            // this.RAPPORT_POLICE_OU_GENDARMERIE = image;
          }else if(type === 'CONSTAT'){
            // this.CONSTAT == image;
          }else if(type === 'PHOTOS'){
            // this.PHOTOS = image;
          }
          let dataFile = new FormData();
          dataFile.append('files', file);
          this.subscriptionService.saveFile(dataFile).subscribe((resp) => {
            if (resp) {
                this.loaderImage = false;
                this.utilsService.showToastSuccess("Fichier chargée avec succès", "");
                this.fileList.push({
                    'type':type,
                    'urlProd':resp['urlprod']
                })
            }
             else {
                this.loaderImage = false;
                this.utilsService.showToastError(resp['message'], "Erreur de chargement du fichier");
            }
        }, error => {
            this.loaderImage = false;
            let message = "Echec ";
            if (error && error.error) {
                message = error.error.errors[0].message;
            }
            this.utilsService.showToastError(message, "Erreur de chargement du fichier");

        });


    }

    showFilePDF(file,type){
        this.titleFile = type;
        this.file = this.domSanitizer.bypassSecurityTrustResourceUrl(file);
        this.modalService.open(this.devisAEnvoye, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
          if (result == 'subscribe') {
          }
        }, (reason) => {
        });
      }

  choixmrh() {
      this.gomrh = true;
      this.gomri = false;
      let token = JSON.parse(localStorage.getItem('microFinance'));
      if (token) {
          this.login = true;
          this.mrh = true;
          this.mri = false;
      } else {

          this.modalService.open(this.modalog, {
              ariaLabelledBy: 'modal-basic-title',
              size: <any>'lg'
          }).result.then((result) => {
              if (result == 'subscribe') {

              }
          }, (reason) => {
          });

      }
  }

  convertStringToNumber(val) {
      return parseFloat(val);
  }

  inscrireutil() {
      if (this.inscriptionForm.valid) {
          let data = {
              "telephone": this.inscriptionForm.get('tel').value,
              "email": this.inscriptionForm.get('email').value,
              "type": 5 + ""
          }
          this.loaderVisible = true;
          this.subscriptionService.sign(data).subscribe((resp) => {
              if (resp["responseCode"] == 200) {
                  this.utilsService.showToastSuccess("Les informations d'activation de votre compte vous ont été envoyées par email et/ou SMS.", "Inscription  réussie");
                  this.modalService.dismissAll(this.modasign);
                  this.loaderVisible = false;
                  this.inscriptionForm.reset();
              } else {
                  this.loaderVisible = false;
                  this.utilsService.showToastError(resp['message'], "Echec");
              }
              this.loaderVisible = false;
          }, error => {
              this.utilsService.showToastError(error.error.errors[0].message, "Echec");
              this.loaderVisible = false;
          });

      } else {
          this.checkValidity(this.inscriptionForm);
      }
  }

  checkValidity(g: FormGroup) {
      Object.keys(g.controls).forEach(key => {
          g.get(key).markAsDirty();
      });
      Object.keys(g.controls).forEach(key => {
          g.get(key).markAsTouched();
      });
      Object.keys(g.controls).forEach(key => {
          g.get(key).updateValueAndValidity();
      });
  }


  lanceforgot() {
      this.modalService.dismissAll(this.modalog);
      this.modalService.open(this.modaforgot, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'xl'
      }).result.then((result) => {
          if (result == 'subscribe') {

          }
      }, (reason) => {
      });
  }

  forgot() {
      let data = {
          // "telephone": this.inscriptionForm.get('tel').value,
          // "nom": this.inscriptionForm.get('nom').value,
          "email": this.inscriptionForm.get('email').value,
          // "type":""+this._idAssurance,
      }
      this.loaderVisible = true;
      this.subscriptionService.reset(data).subscribe((resp) => {
          if (resp["responseCode"] = 200) {
              this.utilsService.showToastSuccess("Un mail vous a été envoyé pour réinitialiser votre mot de passe", "");
              this.modalService.dismissAll(this.modasign);
          } else {
              this.utilsService.showToastError(resp['message'], "Echec");
          }
          this.loaderVisible = false;
      }, error => {
          let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
          if (error && error.error) {
              message = error.error.message;
          }
          this.utilsService.showToastError(message, "Echec");

          this.loaderVisible = false;
      })
  }

  choixmri() {
      this.modalService.dismissAll(this.modasign);
      this.gomri = true;
      this.gomrh = false;
      let token = JSON.parse(localStorage.getItem('microFinance'));
      if (token) {
          this.login = true;
          this.mri = true;
          this.mrh = false;
      } else {
          this.modalService.open(this.modalog, {
              ariaLabelledBy: 'modal-basic-title',
              size: <any>'lg'
          }).result.then((result) => {
              if (result == 'subscribe') {

              }
          }, (reason) => {
          });
      }
  }

  inscrire() {
      this.modalService.dismissAll(this.modalog);
      this.modalService.open(this.modasign, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'lg'
      }).result.then((result) => {
          if (result == 'subscribe') {

          }
      }, (reason) => {
      });
  }

  connecter() {
      this.userInconnu = "";
      if (this.loginform.valid) {
          this.loaderVisible = true;
          let data = {
              ...this.loginform.value
          }
          this.subscriptionService.login(data)
              .subscribe((res) => {
                  if (res['data']) {
                      const tokenObj = res['data'];
                      let infos = {
                          id: tokenObj.id,
                          matricule: tokenObj.matricule,
                          firstName: tokenObj.firstName,
                          lastName: tokenObj.lastName,
                          email: tokenObj.email,
                          telephone: tokenObj.telephone,
                          secteuractivite: tokenObj.secteuractivite,
                          profession: tokenObj.profession,
                          adresse: tokenObj.adresse,
                          genre:  tokenObj.genre
                      }
                      if (res['data']['access_token']) {
                          let detail = {
                              id_token: tokenObj.access_token,
                              refresh_token: tokenObj.refresh_token,
                              // roles: tokenObj.roles,
                              infos: infos,
                              authorities: tokenObj.authorities
                          };
                          localStorage.setItem('microFinance', JSON.stringify(detail));
                          localStorage.setItem('microFinanceBo', JSON.stringify(detail));
                          this.accountService.sendAccount(JSON.stringify(infos));
                          this.user = detail;
                          if (this.gomrh) {
                              this.mrh = true;
                              this.mri = false;
                          } else if (this.gomri) {
                              this.mri = true;
                              this.mrh = false;
                          }
                          this.login = true;
                          this.scroll();
                          // this.loaderVisible = false;
                          this.modalService.dismissAll(this.modalog);
                      } else {
                          if (this.gomrh) {
                              this.choixmrh();
                          } else if (this.gomri) {
                              this.choixmri();
                          }
                          this.userInconnu = res['message'];
                          this.loaderVisible = false;
                      }
                  } else {
                      this.loaderVisible = false;
                      this.userInconnu = res['message'];
                  }
                  this.loaderVisible = false;
              }, (err) => {
                  this.userInconnu = err['error']['message'];
                  this.loaderVisible = false;
              })
      } else {
          this.checkValidity(this.loginform);
      }

  }

  choixinit() {
      this.login = false;
      this.mri = false;
      this.mrh = false;
  }

  nextstep() {
      if (this.sinistreForm.get('declarant').get('prenomDeclarant').invalid || this.sinistreForm.get('declarant').get('nomDeclarant').invalid || this.sinistreForm.get('declarant').get('numeroPolice').invalid || this.sinistreForm.get('declarant').get('adresseDeclarant').invalid || this.sinistreForm.get('declarant').get('numImmat').invalid || this.sinistreForm.get('declarant').get('telephoneDeclarant').invalid || this.sinistreForm.get('declarant').get('utilvehicul').invalid || this.sinistreForm.get('declarant').get('dateVisitetechnik').invalid) {
          this.invalid = true;
          //  this.wizard.goToNextStep();
      } else {

          this.wizard.goToNextStep();
          window.scrollTo(500, 500);
          this.invalid = false;
          if(this.sinistreForm.get('sinistre2').get('autreMarque').value != ''){
            this.sinistreForm.get('sinistre2').get('marque').setValue(this.sinistreForm.get('sinistre2').get('autreMarque').value);
          }
          if(this.sinistreForm.get('sinistre2').get('autreModele').value != ''){
            this.sinistreForm.get('sinistre2').get('modele').setValue(this.sinistreForm.get('sinistre2').get('autreModele').value);
          }
      }
  }



  readmore(sp) {
      document.getElementById("mrh" + sp).style.display = "block";
      document.getElementById("less" + sp).style.display = "inline";
      document.getElementById("less" + sp).style.color = "white";
      document.getElementById("more" + sp).style.display = "none";
  }



  scroll() {
    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  souscrire() {
      this.scroll();
      this.modalService.dismissAll(this.modasign);
      this.gomrh = false;
      this.gomri = true;
      let token = JSON.parse(localStorage.getItem('microFinance'));
      if (token) {
          this.login = true;
          this.mrh = false;
          this.mri = true;
      } else {
          this.modalService.open(this.modalog, {
              ariaLabelledBy: 'modal-basic-title',
              size: <any>'lg'
          }).result.then((result) => {
              if (result == 'subscribe') {

              }
          }, (reason) => {
          });
      }
  }
  getModelvoiture() {
      this.subscriptionService.getModel().subscribe((resp) => {
          this.model = resp['data'];
      });
  }


  marque() {
    let dah = this.sinistreForm.get('sinistre2').get('marque').value;
    this.subscriptionService.getmodel(dah).subscribe((resp) => {
      this.listModeles = resp['data'];
    });
  }





  openModalResult() {
      this.modalService.open(this.contentResult, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'lg'
      }).result.then((result) => {
          if (result == 'subscribe') {
              this.wizard.goToNextStep();
              window.scrollTo(500, 500);
              let user = this.user.infos;
              this.currentSouscripteur = "Pour moi-même";
          }
      }, (reason) => {
      });
  }
  packForm(code) {
    //   this.sinistreForm.get('packForm').get('sub_package').setValue(code);
      let sousPack = this.sinistreForm.get('packForm').get('sub_package').value;
      this.subPackag = this.pack.sous_packs.find((sp) => sp.code == sousPack);
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
  }

  next() {
      window.scrollTo(500, 500);
  }



  saveData() {
      let data = {
          ...this.sinistreForm.get('declarant').value,
          ...this.sinistreForm.get('sinistre').value,
          ...this.sinistreForm.get('sinistre1').value,
          ...this.sinistreForm.get('sinistre2').value,
          'fileList':this.fileList
      };
      let dateSinitre;
      if(this.sinistreForm.get('sinistre1').get('dateAccident').value != ''){
        dateSinitre = this.sinistreForm.get('sinistre1').get('dateAccident').value;
      }
    //   else if(this.sinistreForm.get('sinistre').get('dateSinistre').value != '') {
    //     dateSinitre = this.sinistreForm.get('sinistre').get('dateSinistre').value;
    //   }
      else {
        dateSinitre = new Date();
      }

      let description ;
      let lieu;
      if(this.sinistreForm.get('declarant').get('typeAssurance').value == 'ASSURANCE AUTOMOBILE'){
        description  = this.sinistreForm.get('sinistre1').get('circonstance').value;
        lieu = this.sinistreForm.get('sinistre1').get('lieuAccident').value;
      } else{
        description = 'description';
        lieu = 'lieu'
      }

      let value = {
        'lieu':lieu,
        'date':dateSinitre,
        'description':description,
        "designationClient":this.user?.infos?.firstName +' '+ this.user?.infos.lastName,
        "telephone":this.user?.infos?.telephone,
        "email":this.user?.infos?.email,
        "adresse":this.user?.infos?.adresse,
        "numeroPolice":this.sinistreForm.get('declarant').get('numeroPolice').value,
        'content': JSON.stringify(data)
      }
      this.sendingRequest = true;
      this.subscriptionService.sinitreDeclaration(value).subscribe((resp) => {
          if (resp['responseCode'] == 200) {
              this.utilsService.showToastSuccess("Déclaration effectuée avec succès", "");
            //   window.open(resp['data'].url, '_blank');
              this.sinistreForm.reset();
              this.listImages = [];
              this.listImagesSante = [];
              this.listImagesPhotos = [];
              this.router.navigate(['/']);
          } else {
              this.utilsService.showToastError(resp['message'], "Erreur souscription");
          }
          this.sendingRequest = false;
      }, error => {
          let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
          if (error && error.error) {
              message = error.error.errors[0].message;
          }
          this.utilsService.showToastError(message, "Echec");
          this.sendingRequest = false;
      });
  }


  declaration(){
    // console.log('dec' , this.sinistreForm.value);
  }


  goTo(step) {
      window.scroll(500, 500);
      this.wizard.goToStep(step);
  }

  retour() {
    this.flotte = false;
    this.login = true;
    this.individuel = false;
    window.scroll(500,500);
  }

  enterStep(event) {
      for (let val of this.resultass) {
          if (val.code == 'NSIA') val['img'] = "../../../../../assets/images/nsia.png";
          else if (val.code == 'AXA') val['img'] = "../../../../../assets/images/axa.png";
          else if (val.code == 'AMSA') val['img'] = "../../../../../assets/images/amsa.png";
          else if (val.code == 'SALAMA') val['img'] = "../../../../../assets/images/salama.png";
              // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
          // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
          else val['img'] = "../../../../../assets/images/assurance.jpg";
      }

  }




  setValidatorsMethode(form, tab) {
      for (const key in form.controls) {
          if (tab.indexOf(key) != -1) {
              form.get(key).setValidators(Validators.required);
              form.get(key).updateValueAndValidity();
              form.get(key).setValue('');
          }
      }
  }

  clearValidatorsMethode(form, tab) {
      for (const key in form.controls) {
          if (tab.indexOf(key) != -1) {
              form.get(key).clearValidators();
              form.get(key).updateValueAndValidity();
              form.get(key).setValue('');
          }
      }
  }


  onDateSelect(event, groupName, controlName) {

      if (event) {
          let data = (event.year + '/' + event.month + '/' + event.day);
          this.sinistreForm.get('' + groupName).get('' + controlName).setValue(new Date(data));
          if (controlName == "effet") {
              this.Datemin = new Date(data);
              this.dateExpiration = moment(this.sinistreForm.get('packForm1').get('effet').value).add(1, 'years');
          }
      }
  }

  onDateSelect1(event, groupName, controlName) {
      if (event) {
          let dah = (event.year + '/' + event.month + '/' + event.day);
          if (controlName == "echeance") {
              this.Datemax = new Date(dah);
              if (this.Datemax > this.Datemin) {
                  this.sinistreForm.get('' + groupName).get('' + controlName).setValue(new Date(dah));
                  this.datevalid = false;
              } else {
                  this.datevalid = true
              }
          }
      }


  }

  onDateSelect3(event, groupName, controlName) {
      if (event)
          this.sinistreForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));

  }

  assuree() {
      if (this.sinistreForm.get('assure').get('dateNaissance').invalid || this.sinistreForm.get('assure').get('numeroTelephone').invalid || this.sinistreForm.get('assure').get('prenom').invalid || this.sinistreForm.get('assure').get('nom').invalid || this.sinistreForm.get('assure').get('numeroCni').invalid || this.sinistreForm.get('assure').get('adresse').invalid || this.sinistreForm.get('assure').get('genre').invalid || this.sinistreForm.get('assure').get('email').invalid) {
          this.invalid = true
          //  this.wizard.goToNextStep();
      } else {
        // @ts-ignore
          this.sinistreForm.get('souscripteur').patchValue(this.sinistreForm.get('assure').value);
          this.sinistreForm.get('souscripteur').get('dateNaissance').setValue(this.sinistreForm.get('assure').get('dateNaissance').value);
          this.sinistreForm.get('souscripteur').get('telephone').setValue(this.sinistreForm.get('assure').get('numeroTelephone').value);
          // this.saveDatamrh();
          this.wizard.goToNextStep();
          window.scrollTo(500, 500);
          this.invalid = false;
      }

  }




  ngAfterViewInit(): void {

  }

  getImageAssureur() {
      if (this.currentAssureur['code'] == 'NSIA') this.currentAssureur['img'] = "../../../../../assets/images/nsia.png";
      else if (this.currentAssureur['code'] == 'AXA') this.currentAssureur['img'] = "../../../../../assets/images/axa.png";
      else if (this.currentAssureur['code'] == 'AMSA') this.currentAssureur['img'] = "../../../../../assets/images/amsa.png";
      else if (this.currentAssureur['code'] == 'SALAMA') this.currentAssureur['img'] = "../../../../../assets/images/salama.png";
          // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
      // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
      else this.currentAssureur['img'] = "../../../../../assets/images/assurance.jpg";
  }


  //open modal
  openModal(modal){
      this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
          if (result == 'subscribe') {

          }
        }, (reason) => {
        });
  }

  retourPrev(){
    this.wizard.goToPreviousStep();
  }


  onChangeSinistre(event) {
    this.sinistreForm.get('declarant').get('prenomDeclarant').setValue(this.user.infos.firstName);
    this.sinistreForm.get('declarant').get('nomDeclarant').setValue(this.user.infos.lastName);
    this.sinistreForm.get('declarant').get('telephoneDeclarant').setValue(this.user.infos.telephone);
    this.sinistreForm.get('declarant').get('adresseDeclarant').setValue(this.user.infos.adresse);
    this.sinistreForm.get('declarant').get('email').setValue(this.user.infos.email);
    this.sinistreForm.get('declarant').get('profession').setValue(this.user.infos.profession);
  }

}
