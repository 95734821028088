export const environment = {
    production: false,
    baseApiUrl: 'https://dci.suntelecoms.com/api/',
    baseApiStrapi: 'https://dci.suntelecoms.com/backend',
    frontWebUrl: 'https://web-ma.suntelecoms.com/',
    apgUrl: 'https://dev.afripayway.com/payline/v1/',
    cinetpayUrl: 'https://api-checkout.cinetpay.com/v2/',
    assuranceApiUrl: 'http://assurance.suntelecoms.com/api/',
    appName: 'Optima Assurance',
    defaultCredentials: {
        username: 'webclient',
        password: 'webclient@SUN'
    },
    apg: {
        baseUrl: 'https://wdev.afripayway.com/payline/v1/',
        baseUrlPrelevement: 'https://wdev.afripayway.com/payline/',
        urlPrelevement: 'https://wdev.afripayway.com/payline/card/switch/prelevementBank',
        prelevementURL: '/card/switch/prelevementBank',
        terminalNumber: '423820000101',
        transactionCurrencyCode: 'XOF',
        initURL: '/payment/api/initiateOnlinePayment',
        checkStatusURL: '/payment/api/getStatus',
        returnUrl: `https://preprodonline.secusociale.sn/api/apg-callback`,
        secretKey: 'FC48ABF885F26C13B0BE46B54618BC',
        accountsToCreditIpress: "SN0110111700300990244206",
        accountsToCreditCss: "SN0110111700300990244270",
        initPayment: "/payment/api/initiateOnlinePayment",
    },

    cinetPay: {
        apikey: "4590292276418ea2b299537.06944627",
        site_id: "679608",
        transaction_id: "",
        amount: 0,
        currency: "XOF",
        description: "TEST INTEGRATION",
        customer_id: "",
        customer_name: "",
        customer_surname: "",
        customer_email: "",
        customer_phone_number: "",
        customer_address: "",
        customer_city: "",
        customer_country: "CI",
        customer_state: "CI",
        customer_zip_code: "99326",
        notify_url: "https://abidjanaise.suntelecoms.com/api/souscriptions/payment-notification",
        return_url: "https://abidjanaise.suntelecoms.com/api/souscriptions/payment-notification",
        channels: "ALL",
        lang: "FR"
    }
};
