<header class="header-area header-style-three">

    <!-- Start Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="top-header-logo">
                        <a routerLink="/">
                            <img class="tailleImage" class="logo" [src]="logo" alt="logo">
                        </a>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="top-header-right-side">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-email"></i>
                                </div>
                                <span>Adresse email:</span>
                                <a href={{app.dci.email}}>{{app.dci.email}}</a>
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <span>Appelez-nous:</span>
                                <a href="tel:{{app.dci.telephone}}">{{app.dci.telephone}}</a>
                            </li>

                            <li>
                                <a target="_blank" href={{app.dci.espacePro.url}} class="default-btn">{{app.dci.espacePro.label}}<span></span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="truz-responsive-nav">
            <div class="container">
                <div class="truz-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img class="tailleImage" src="/assets/image/img/Digit conseil logo/Logo Jpeg/CMJK pour l'impression/logo_2_3-06-removebg-preview.png" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="truz-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img class="tailleImage" src="/assets/image/img/Digit conseil logo/Logo Jpeg/CMJK pour l'impression/logo_2_3-06-removebg-preview.png" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item" *ngFor="let menu of app.dci.menu">
                                <a *ngIf="menu.sousMenu.length == 0" routerLink="/{{menu.url}}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{menu.titre}}</a>
                                <a *ngIf="menu.sousMenu.length !== 0" routerLink="/" class="nav-link">{{menu.titre}}<i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" *ngFor="let sousMenu of menu.sousMenu">
                                        <a *ngIf="!sousMenu.sousMenu" routerLink="/{{sousMenu.url}}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{sousMenu.titre}}</a>
                                        <a *ngIf="sousMenu.sousMenu" href="javascript:void(0)" class="nav-link">{{sousMenu.titre}}</a>
                                        <ul class="dropdown-menu" *ngIf="sousMenu.sousMenu">
                                            <li class="nav-item" *ngFor="let childrenItem of sousMenu.sousMenu">
                                                <a routerLink="{{childrenItem?.url}}" class="nav-link">
                                                    {{childrenItem?.titre  | titlecase}}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <div class="others-option">
                            <div class="option-item">
                                <!-- <div *ngIf="utilisateurs">
                                    <span class="log-ini mt-3 ml-5" *ngIf="utilisateurs">
                                        {{utilisateurs?.firstName}}
                                        {{utilisateurs?.lastName}}</span>

                                    <a target="_blank" placement="bottom" triggers="mouseenter:mouseleave" href="http://ma.suntelecoms.com/" class="log-in user" *ngIf="utilisateurs">
                                        <i class="fa fa-user  ml-2 "></i>
                                    </a>
                                </div> -->

                                <!-- <span class="log-ini" *ngIf="utilisateur">
                                    {{utilisateur?.infos?.firstName}}
                                    {{utilisateur?.infos?.lastName}}
                                </span> -->
                                <!-- <span class="log-out user" placement="bottom" [swal]="confirmDeconnexion" *ngIf="utilisateur">

                                    <a target="_blank" placement="bottom"
                                            triggers="mouseenter:mouseleave" href="https://bo.abidjanaise.suntelecoms.com/" class="log-in user">
                                            <i class="fa fa-power-off  ml-2 colorIcon"></i>
                                    </a>
                                </span> -->

                                <i class="close-btn flaticon-cross-out"></i>

                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">

                                            <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="burger-menu">
                                <i class="flaticon-menu"></i>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->

</header>

<swal #confirmDeconnexion title="Confirmation" text="Voulez-vous vraiment proceder à la déconnexion ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'Confirmer'" [cancelButtonText]="'ANNULER'" (confirm)="deconnexion()">
</swal>
