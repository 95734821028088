import { Component, OnInit } from '@angular/core';
import { APP } from 'src/app/shared/model/model';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrls: ['./home-five.component.scss']
})
export class HomeFiveComponent implements OnInit {
  app = APP;
  isCommentaire:boolean = true;
  isPartenaire:boolean = true;
  headers:any;
  apropos:any;
  environment = environment;
  telephone:string
  rendez_vous:any;
  mission_bloc:any;
  service_bloc:any;
  membres_bloc:any;
  partenaire_bloc:any;
  commentaire_bloc:any;
  constructor(private subscribeService:SubscriptionService) {
    this.subscribeService.getDataStrapi().subscribe((data:any)=>{
        if (data['data'].length !== 0) {
            this.headers = data['data'][0]['attributes']['headers'];
            this.apropos = data['data'][0]['attributes']['apropos'];
            this.telephone = data['data'][0]['attributes']['telephone'];
            this.rendez_vous = data['data'][0]['attributes']['rendez_vous'];
            this.mission_bloc = data['data'][0]['attributes']['mission_bloc'];
            this.service_bloc = data['data'][0]['attributes']['service_bloc'];
            this.membres_bloc = data['data'][0]['attributes']['membres_bloc'];
            this.partenaire_bloc = data['data'][0]['attributes']['partenaire_bloc'];
            this.commentaire_bloc = data['data'][0]['attributes']['commentaire_bloc'];
            this.isCommentaire = this.commentaire_bloc['active'];
            this.isPartenaire = this.partenaire_bloc['active'];
            let i = 0;
            this.app.dci.parteniares.forEach(element => {
                if (this.partenaire_bloc['partenaires'][i] !== undefined) {
                    element.image = environment.baseApiStrapi+this.partenaire_bloc['partenaires'][i]['image']['data']['attributes']['url'];
                    element.url = this.partenaire_bloc['partenaires'][i]['url'];
                }
                i++;
            });
            let j = 0;
            this.app.dci.commentaire.forEach(element => {
                if (this.commentaire_bloc['commentaire'][j] !== undefined) {
                    element.image = environment.baseApiStrapi+this.commentaire_bloc['commentaire'][j]['image']['data']['attributes']['url'];
                    element.fonction = this.commentaire_bloc['commentaire'][j]['fonction'];
                    element.nom = this.commentaire_bloc['commentaire'][j]['nom'];
                    element.text = this.commentaire_bloc['commentaire'][j]['text'];
                }
                j++;
            })
        }
    })
   }

  ngOnInit(): void {
  }

}
