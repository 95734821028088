<app-header-one></app-header-one>
<!-- <app-header-three></app-header-three> -->

<div class="page-title-area page-title-bg1 height_image bgO">
    <div class="d-table ">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-title-content">
                    <h1 class="text-white">ÉTUDE ET CONSEIL</h1>
                    <ul>
                        <!-- <li><a routerLink="/">Home</a></li> -->
                        <!-- <li>Identifier, Evaluer, Conseiller, Assurer</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 mb-5">
                    <div class="image text-center">
                        <img src="assets/image/img/img21ffff.png" alt="image" class="img1">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Pourquoi étude et conseil ?</h3>
                        <p class="fs-5">Nos services d'étude et de conseil sont conçus pour vous fournir des analyses approfondies
                            et des recommandations stratégiques. Que ce soit pour évaluer vos risques ou optimiser
                            vos processus, nous sommes là pour vous guider vers des décisions éclairées..</p>

                    </div>
                </div>


            </div>
        </div>

        <div class="insurance-details-desc">


            <div class="container">
                <div class="">
                    <div class="single-feedback-item">
                        <div class="feedback-desc">
                            <p>Nous examinons attentivement les aspects clés de votre
                                situation, identiﬁant les points forts et les faiblesses. En
                                utilisant des méthodologies éprouvées, nous recueillons des
                                données pertinentes et les analysons de manière exhaustive
                                aﬁn de fournir une vision complète de la situation.</p>
                        </div>

                        <div class="client-info">
                            <h3>Analyse Approfondie</h3>
                        </div>
                    </div>

                    <div class="single-feedback-item">
                        <div class="feedback-desc">
                            <p>Ces recommandations reposent sur une compréhension
                                approfondie des résultats de notre analyse, visant à maximiser
                                vos opportunités tout en minimisant les risques potentiels.</p>
                        </div>

                        <div class="client-info">
                            <h3>Recommandations Stratégiques</h3>
                        </div>
                    </div>
                </div>
            </div>
            <blockquote class="wp-block-quote">
                <p>Une entreprise sans stratégie est comme un puzzle sans image ; les pièces sont là,
                    mais l’ensemble demeure ﬂou.La stratégie est le tableau qui guide l’assemblage
                    révélant le panorama clair du succès à chaque pièce</p>
                <cite>Tom Cruise</cite>
            </blockquote>
        </div>
    </div>
</section>
