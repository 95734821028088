import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeInsuranceComponent } from './components/pages/home-insurance/home-insurance.component';
import { BusinessInsuranceComponent } from './components/pages/business-insurance/business-insurance.component';
import { HealthInsuranceComponent } from './components/pages/health-insurance/health-insurance.component';
import { TravelInsuranceComponent } from './components/pages/travel-insurance/travel-insurance.component';
import { CarInsuranceComponent } from './components/pages/car-insurance/car-insurance.component';
import { LifeInsuranceComponent } from './components/pages/life-insurance/life-insurance.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { CaseStudyDetailsComponent } from './components/pages/case-study-details/case-study-details.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { EventsComponent } from './components/pages/events/events.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { MrhComponent } from './components/pages/home-mrh/home-mrh.component';
import { VoyagesComponent } from './components/pages/home-voyage/home-voyage.component';
import { SantesComponent } from './components/pages/home-sante/home-sante.component';
import { ViesComponent } from './components/pages/home-vie/home-vie.component';
import { SinistresComponent } from './components/pages/declaration-sinistre/declaration-sinistre.component';
import { SinistresNewComponent } from './components/pages/declaration-sinistre-new/declaration-sinistre-new.component';
import { MrhOfflineComponent } from './components/pages/home-mrh-offline/home-mrh-offline.component';
import { VoyagesOfflineComponent } from './components/pages/home-voyage-offline/home-voyage-offline.component';
import { SantesOfflineComponent } from './components/pages/home-sante-offline/home-sante-offline.component';
import { ResponsabiliteCivileComponent } from './components/pages/home-rce-offline/home-rce-offline.component';
import { TrcOfflineComponent } from './components/pages/home-trc-offline/home-trc-offline.component';
import { ApgSuccessComponent } from './components/pages/apg-success/apg-success.component';
import { CinetPayComponent } from './components/pages/cinetpay-success/cinetpay-success.component';
import { MrpOfflineComponent } from './components/pages/home-mrp-offline/home-mrp-offline.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { HomeFourNewComponent } from './components/pages/home-four-new/home-four-new.component';
import { ServicesDetailsComponent } from './components/pages/courtage-details/services-details.component';
import { ContactDciComponent } from './components/pages/contact-dci/contact-dci.component';
import { AccompagnementEntrepriseDetailsComponent } from './components/pages/accompagnement-entreprise-details/accompagnement-entreprise-details.component';
import { AccompagnementDigitalDetailsComponent } from './components/pages/accompagnement-digital-details/accompagnement-digital-details.component';
import { EtudeConseilDetailsComponent } from './components/pages/etude-conseil-details/etude-conseil-details.component';
import { ParcoursFormationComponent } from './components/pages/parcours-formation/parcours-formation.component';




const routes: Routes = [
    {path: '', component: HomeFiveComponent},
    {path: 'accueil', component: HomeFiveComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    {path: 'services-one', component: ServicesOneComponent},
    {path: 'services-two', component: ServicesTwoComponent},
    {path: 'home-insurance', component: HomeInsuranceComponent},
    // {path: 'devis/6', component: MrhComponent},
    {path: 'devis/6', component: MrhOfflineComponent},
    // {path: 'devis/7', component: SantesComponent},
    {path: 'devis/7', component: SantesOfflineComponent},
    // {path: 'devis/8', component: VoyagesComponent},
    {path: 'devis/8', component: VoyagesOfflineComponent},
    {path: 'devis/16', component: HomeFourNewComponent},
    {path: 'devis/5', component: HomeFourNewComponent},
    {path: 'devis/23', component: ViesComponent},
    {path: 'devis/24', component: ResponsabiliteCivileComponent},
    {path: 'devis/25', component: TrcOfflineComponent},
    {path: 'devis/27', component: MrpOfflineComponent},
    {path: 'declaration-sinistre', component: SinistresNewComponent},
    {path: 'declaration-sinistre-new', component: SinistresNewComponent},
    {path: 'case-study', component: CaseStudyComponent},
    {path: 'case-study-details', component: CaseStudyDetailsComponent},
    {path: 'events', component: EventsComponent},
    {path: 'events-details', component: EventsDetailsComponent},
    {path: 'courtage-details', component: ServicesDetailsComponent},
    {path: 'accompagnement-entreprise-details', component: AccompagnementEntrepriseDetailsComponent},
    {path: 'accompagnement-digital-details', component: AccompagnementDigitalDetailsComponent},
    {path: 'etude-conseil-details', component: EtudeConseilDetailsComponent},
    {path: 'blog-grid', component: BlogGridComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'activate-account', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    // {path: 'contact', component: ContactComponent},
    {path: 'contact', component: ContactDciComponent},
    {path: 'apg-paiement',component: ApgSuccessComponent},
    {path: 'payment-notification',component: CinetPayComponent},
    {path: 'management',component: ParcoursFormationComponent},
    {path: 'communication',component: ParcoursFormationComponent},
    {path: 'intelligence-financiere',component: ParcoursFormationComponent},
    {path: 'gestion-mutuelle-sociale',component: ParcoursFormationComponent},
    {path: 'gestion-projet',component: ParcoursFormationComponent},
    {path: 'informatique',component: ParcoursFormationComponent},
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
