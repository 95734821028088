<app-header-three></app-header-three>
<div class="main-banner-section item-bg6 height_image">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>ASSURANCE AUTOMOBILE</h1>
                    <p class="black">Automobiles Votre véhicule vous est indispensable ! Choisissez la formule la plus adaptée grâce à l’assistance de votre conseiller.</p>
                    <div class="btn-box">
                        <a class="default-btn btnO">Me faire rappeler<span></span></a>
                        <!-- <a class="optional-btn rappel">Obtenir un devis<span></span></a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/car.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/umbrella-white.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/home.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/heart.png" alt="image"></div>
</div>
<div class="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
    <div class="p-4">
      <div class="row g-5">
        <div class="col-lg-4">
          <!-- <h1 class="display-6 mb-5">
            We're Award Winning Insurance Company
          </h1>
          <p class="mb-5">
            Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed
            stet lorem sit clita duo justo magna dolore erat amet. Tempor erat
            elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet
            diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit
            clita duo justo magna.
          </p>

          <div class="bg-light rounded p-3">
            <div class="d-flex align-items-center bg-white rounded p-3">
              <img
                class="flex-shrink-0 rounded-circle me-3"
                src="img/profile.jpg"
                alt=""
              />
              <h5 class="mb-0">Call Us: +012 345 6789</h5>
            </div>
          </div> -->
        <div class="about-content">
            <h2 class="text-center c-garantie">Nos Garanties</h2>
        </div>
        <div class="about-inner-area">
            <div class="row card-title">
                <div class="col-lg- col-md-6 col-sm-6" *ngFor="let garantie of garanties">
                    <div class="about-text-box c-y card wow fadeInUp" data-wow-delay="0.5s">
                        <h3>{{garantie?.nom}}</h3>
                        <p>{{garantie?.description}}.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="col-lg-8 wow fadeIn" data-wow-delay="0.5s">


          <div class="bg-light rounded p-5">
            <form [formGroup]="simulationVehiculeForm" class="auto">
                <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">
                    <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Vehicule">
                        <div class="form-container">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                                            <option value="" disabled selected>Catégorie de voiture</option>
                                            <option *ngFor="let v of vehiculeCategories" [value]="v.id">{{v?.libelle}}
                                            </option>
                                             <!-- <option *ngFor="let v of [1,2,3]" [value]="v.id">{{v}}
                                            </option> -->
                                        </select>
                                        <label for="cars" class="form__label">Catégorie de voiture<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <!-- type de véhicule -->
                                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && transportP">
                                    <div class="form" placement="top" ngbPopover="Type de véhicule" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" placeholder=" " class="form__select" name="typeVehiculePublic" formControlName="typeVehiculePublic" name="typeVehiculePublic" #typeVehiculePublic>
                                            <option value="" disabled selected>Type de véhicule</option>
                                            <option *ngFor="let typeV of transportPublicList" [value]="typeV.id">{{typeV?.libelle}}
                                            </option>
                                        </select>
                                        <label for="cars" class="form__label">Type de véhicule <span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehiculePublic').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <!-- type de véhicule -->
                                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">
                                    <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-balance-scale form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="number" formControlName="poids" id="poidsVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="poidsVehicule" class="form__label">Poids du véhicule<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && moto">
                                    <div class="form" ngbPopover="Cylindrée" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-balance-scale form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="number" formControlName="cylindree" id="cylindree" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="cylindree" class="form__label">Cylindrée<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('cylindree').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">

                                    <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-gas-pump form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="tcarb" class="form__select" formControlName="typeMoteur">
                                            <option value="" disabled selected="selected">Type de carburant</option>
                                            <option *ngFor="let typeCarbu of carburantList" [value]="typeCarbu?.value">{{typeCarbu?.name}}</option>
                                        </select>
                                        <label for="cars" class="form__label">Type de carburant<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">

                                    <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-balance-scale form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                                        <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                                        <label for="place" class="form__label">Nombre de places<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">
                                    <div class="form" [ngbPopover]="showCharges ? 'Charge Utile' : 'Puissance'" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-bolt form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="number" min="1" formControlName="puissance" id="puissanceAuto" class="form__input" autocomplete="off" placeholder=" " />
                                        <label *ngIf="!showCharges" for="puissanceAuto" class="form__label">Puissance<span class="text-red">
                                                *</span></label>
                                        <label *ngIf="showCharges" for="puissanceAuto" class="form__label">Charge Utile<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                                    <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="immatriculation" id="immatriculationV" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="immatriculationV" class="form__label">Immatriculation du véhicule<span
                                                class="text-red"></span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                                    <div class="form" ngbPopover="Numéro de châssis du véhicule" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="numeroChassis" id="numeroChassis" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="numeroChassis" class="form__label">Numéro de châssis du véhicule<span
                                                class="text-red"></span></label>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && shoModel">
                                    <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select placeholder=" " class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                                            <option value="" disabled selected="selected"> Marque du vehicule</option>
                                            <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}
                                            </option>
                                        </select>
                                        <label for="mySelect" class="form__label">Marque du vehicule<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && simulationVehiculeForm.get('marque').valid && shoModel && !autreMarqueSelected">
                                    <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select placeholder=" " class="form__select" id="cars" formControlName="modele" (change)="modele()">
                                            <option value="" disabled selected="selected"> Modèle du vehicule</option>
                                            <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}
                                            </option>
                                        </select>
                                        <label for="cars" class="form__label">Modèle du vehicule<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <!--start new field -->
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && (!shoModel || autreMarqueSelected)">
                                    <div class="form" ngbPopover="Autre marque du vehicule" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="autreMarque" id="marqueVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="marqueVehicule" class="form__label">Autre marque du véhicule<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('autreMarque').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && (!shoModel || autreMarqueSelected || autreModelSelected)">
                                    <div class="form" ngbPopover="Autre modèle du vehicule" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-car form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="autreModele" id="modeleVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="modeleVehicule" class="form__label">Autre modèle du véhicule<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('autreModele').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <!--end new field -->


                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">

                                    <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-calendar form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="dateCirculation" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker"
                                            (click)="debut.toggle()" />
                                        <label for="dateCirculation" class="form__label">Date de mise en circulation<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                                        Ce champ est obligatoire
                                    </div>

                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                    <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-calendar form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                                            (click)="dateEffet.toggle()" />
                                        <label for="effet" class="form__label">Date d'effet du contrat<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                    <div>
                                        <div class="form" placement="top" ngbPopover="Durée du contrat" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-hourglass-2 form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <select placeholder=" " class="form__select" id="cars" (change)="checkDuree()" name="contract-duration" formControlName="duree">
                                                <option value="" disabled selected>Durée du contrat</option>
                                                <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">
                                                    {{d.displayMe}}</option>
                                            </select>
                                            <label for="cars" class="form__label">Durée du contrat<span class="text-red">
                                                    *</span></label>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('duree').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                    <div class="form" placement="top" ngbPopover="Date fin du contrat" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-calendar form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'dateEcheance')" ngbDatepicker #dateEcheance="ngbDatepicker" [value]="this.simulationVehiculeForm.get('dateEcheance').value | date: 'dd/MM/yyyy'"
                                            (click)="dateEcheance.toggle()" />
                                        <label for="effet" class="form__label">Date fin du contrat<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('dateEcheance').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="valider()">Suivant <i class="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </aw-wizard-step>

                    <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Garanties" *ngIf="garantieShow">
                        <div class="form-container c-form-container">
                            <div class="row">
                                <div class="col-12 text-center p-2 mrhgarantie">
                                    <i class="fa fa-check-circle mr-2 text-success"></i>
                                    <b class="c-respon">Responsabilité civile des conducteurs (Garantie par défaut)</b>
                                </div>
                            </div>
                            <div *ngIf="garanties?.length > 0" class="row">
                                <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                                    <div class="value" *ngIf="g.sousGarantie.length==0">

                                        <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                        <label for="{{g.code}}"> {{g?.nom}} </label>
                                        <span class="garantDescription" placement="right" triggers="click" [autoClose]="'outside'" [ngbTooltip]="g.description"> ?</span>
                                        <span class="garantDescription" *ngIf="g.code === 'bris' && (complete || collision || plafone)" placement="right"> (Garantie gratuite)</span>
                                    </div>
                                    <div class="value" *ngIf="g.sousGarantie.length>0">
                                        <label for="" class="label-SG"> {{g?.nom}}</label>
                                        <div *ngFor="let sg of g.sousGarantie" class="sgGarantie" triggers="click" [autoClose]="'outside'" [ngbTooltip]="sg.description">
                                            <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                            <label for="{{g.code}}"> {{sg?.nom}}</label>
                                            <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> <i
                                                    class="fa fa-hand-o-right form__icon_option"></i>
                                                     <label for="{{g.description}}">{{sg?.description}}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                                Chargemet en cours ...
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete || collision || plafone">
                                    <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-credit-card-alt form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (blur)="setValeurVenale()" (keyup)="onBlurEventneuf($event)" />
                                        <label for="neuf" class="form__label">Valeur à neuf<span class="text-red">
                                                *</span></label>
                                    </div>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                    Ce champ est obligatoire
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                    <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                        <label for="venal" class="form__label">Valeur vénale<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                    <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                        La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="popValue">
                            <ng-template #popValeur>
                                <div class="card" *ngIf="incendie || collision || vol || plafone || complete">
                                    <div class="card-header">
                                        Veuillez renseigner le(s) champs suivant(s)
                                    </div>
                                    <div class="card-body">
                                        <div *ngIf="complete">
                                            <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-credit-card-alt form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                                <label for="neuf" class="form__label">Valeur à neuf<span class="text-red">
                                                        *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div *ngIf="incendie || collision || vol || plafone">
                                            <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-money form__icon c-icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                                <label for="venal" class="form__label">Valeur vénale<span class="text-red">
                                                        *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                            <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                                La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn " awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="processSimulation()"><i class="fa fa-spinner fa-pulse"
                                        *ngIf="sendingRequest"></i> Suivant <i class="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </aw-wizard-step>

                    <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur">
                        <!-- assureurs all -->
                        <div class="" *ngIf="!assureurOtherShow">
                            <div class="container justify-content-center">
                                <div class="noAssureur" *ngIf="sendingRequestAssureur && resultSimulationNew.length == 0">
                                    <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                    <p class="text-center text-ass">Chargement en cours...</p>
                                </div>
                                <div class="noAssureur" *ngIf="!sendingRequestAssureur && resultSimulationNew.length == 0">
                                    <p class="text-center text-ass c-aucune">Aucune proposition</p>
                                </div>
                                <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.</div>
                                <ng-select *ngIf="false" class="select-ass" multiple="true" [(ngModel)]="selectedAss" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" (change)="selectAssureur()" [dropdownPosition]="'bottom'" [ngModelOptions]="{standalone: true}">
                                    <ng-option *ngFor="let ass of listAssureurs" [value]="ass.id">
                                        <img [src]="ass.logo" class="img-assureur" [alt]="ass.nom">
                                        <span class="text-assureur">{{ass.nom}}</span> </ng-option>
                                </ng-select>
                                <!-- <div *ngFor="let ass of listAssureurs">
                                    <img [src]="ass.logo" class="img-assureur" [alt]="ass.nom">
                                </div> -->

                                <div class="justify-content-center">
                                    <!-- <div class="row"> -->
                                    <section class="pricing-area ptb-70 pb-70">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let res of resultSimulationNew">
                                                    <div class="single-pricing-box">
                                                        <div class="pricing-header bg-{{res?.assureur?.code}}">
                                                            <img class="img-fluid" [src]="res?.assureur?.logo" [alt]="res?.assureur?.nom" height="177px" />
                                                            <!-- <h3>{{res?.assureur?.nom}}</h3> -->
                                                        </div>
                                                        <h3 class="titre_assureur">{{res?.assureur?.nom}}</h3>
                                                        <div class="c-price" *ngFor="let it of res?.simulation">
                                                            <sub *ngIf="it.key=='Prime Totale TTC'">{{it?.value |number:'1.0-0':'fr'}} &nbsp;
                                                                  FCFA</sub>
                                                        </div>

                                                        <div class="book-now-btn" (click)="goToNext(res)">
                                                            <span class="default-btn">Souscrire en ligne</span>
                                                        </div>

                                                        <ul class="pricing-features-list">
                                                            <li class="tehgr" (click)="rappeler()">Etre rappelé</li>
                                                            <!-- <li class="tehgr" (click)="devisParMail(res)">Devis par E-mail</li> -->
                                                            <!-- <li class="tehgr" (click)="devisParWatshap(res)">Devis par watsapp</li> -->

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="book-now-btn etudeComparatif" (click)="showEtudeComparative()" *ngIf="resultSimulationNew.length !== 0">
                                            <span class="default-btn">Etude Comparative</span>
                                        </div>
                                    </section>
                                    <div class="noAssureur" *ngIf="sendingRequest">
                                        <i class="fa fa-spinner fa-pulse"></i><br>
                                        <p class="text-center">Chargement devis en cours...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                                </div>
                            </div>
                        </div>
                        <!-- assureurs all -->

                        <!-- assureur transport urbain -->
                        <div class="" *ngIf="assureurOtherShow">
                            <div class="container justify-content-center">
                                <div class="noAssureur" *ngIf="sendingRequestAssureur && tarificationList.length == 0">
                                    <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                    <p class="text-center text-ass">Chargement en cours...</p>
                                </div>
                                <div class="noAssureur" *ngIf="!sendingRequestAssureur && tarificationList.length == 0">
                                    <p class="text-center text-ass c-aucune">Aucune proposition</p>
                                </div>
                                <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.</div>
                                <div class="justify-content-center">
                                    <section class="pricing-area ptb-70 pb-70">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let res of tarificationList">
                                                    <div class="single-pricing-box">
                                                        <div class="pricing-header bg-{{res?.assureur?.nom}}">
                                                            <img class="img-fluid" [src]="res?.assureur?.uuid" [alt]="res?.assureur?.nom" height="177px" />
                                                        </div>
                                                        <h3 class="titre_assureur">{{res?.assureur?.nom}}</h3>
                                                        <div class="c-price">
                                                            <sub>{{res?.primeTTC | number:'1.0-0':'fr'}} &nbsp;
                                                              FCFA</sub>
                                                        </div>

                                                        <div class="book-now-btn" (click)="goToNext(res)">
                                                            <span class="default-btn">Souscrire en ligne</span>
                                                        </div>

                                                        <ul class="pricing-features-list">
                                                            <li class="tehgr" (click)="rappeler()">Etre rappelé</li>
                                                            <!-- <li class="tehgr" (click)="processSimulation2(res)">Télécharger</li> -->
                                                            <!-- <li class="tehgr" (click)="devisParMail(res)">Devis par E-mail</li> -->
                                                            <!-- <li class="tehgr" (click)="devisParWatshap(res)">Devis par watsapp</li> -->

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="book-now-btn etudeComparatif" (click)="showEtudeComparative()" *ngIf="listAssureurs.length !== 0">
                                        <span class="default-btn">Etude Comparative</span>
                                    </div> -->
                                    </section>


                                    <div class="noAssureur" *ngIf="sendingRequest">
                                        <i class="fa fa-spinner fa-pulse"></i><br>
                                        <p class="text-center">Chargement devis en cours...</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                                </div>
                            </div>
                        </div>
                        <!-- assureur transport urbain -->

                        <!-- proposition -->
                        <div class="recap-form-container" *ngIf="false" #autoDownload id="autoDownload">
                            <div class="mesImage">
                                <div class="imgLogo">
                                    <img [src]="currentAssureur.img" alt="">
                                </div>
                            </div>
                            <div class="info-devis">
                                <div class="proposition">
                                    <div class="pro-title"> Proposition automobile</div>
                                    <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                        <div class="title-div">
                                            <span>Références du souscripteur1</span>
                                            <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.email}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.telephone}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.adresse}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.profession}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.secteuractivte}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{currentAssureur.nom}} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                        <div class="title-div">
                                            <span> Références Proposition</span>
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{user.infos.adresse}} </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{simulationVehiculeForm.get('date_effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                                </div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{dateExpiration | date:'dd/MM/yyyy'}} -->
                                                    {{simulationVehiculeForm.get('dateEcheance')?.value | date:'dd/MM/yyyy'}}
                                                </div>

                                                <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div> -->

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{currentCategorie}} </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                        <div class="title-div">
                                            Identification du véhicule
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('immatriculation').value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">{{simulationVehiculeForm.get('marque').value}} </div>
                                                        <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreMarque').value != '' ">{{simulationVehiculeForm.get('autreMarque').value}} </div> -->
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('puissance').value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">Valeur à neuf
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                            {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                        </div>
                                                        <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            <span class="fa fa-check"></span>
                                                        </div> -->
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                            {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('poids').value}} </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('modele').value}}
                                                        </div>
                                                        <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreModele').value !=''">
                                                            {{simulationVehiculeForm.get('autreModele').value}}
                                                        </div> -->
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                            Valeur vénale
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                            {{simulationVehiculeForm.get('valeurVenal').value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="proposition2">
                                    <div class="pro-title"> Garanties souscrites et Primes d’assurance (en FCFA)</div>
                                </div>
                                <div class="row c-row">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                        <div class="title-div">
                                            Nature des garanties
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                    {{garantie.key}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                        <div class="title-div">
                                            Primes d’assurance
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                    <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                        <div class="title-div">
                                            Franchises par sinistre
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                    <span class="c-col-val-neant">Néant</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="description-ass">
                                    Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                                        {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                                </div>
                                <div class="row ">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                    <div *ngFor="let item of primeAss">
                                                        <span *ngIf="item.key!='Prime Totale TTC'">{{item.key}}</span>
                                                        <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.key}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                                                    <div class="val" *ngFor="let item of primeAss">
                                                        <span *ngIf="item.key!='Prime Totale TTC'">{{item.value |
                                                            number:'1.0-0':'fr'}} FCFA</span>
                                                        <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}
                                                            FCFA</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="disposition">
                                    <div class="title-disp">
                                        Dispositions complémentaires
                                    </div>
                                    <div class="desc-disp">
                                        La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                                        <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                        <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.
                                        </div>
                                        <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                                    </div>
                                </div>
                            </div>
                            <div class="footer-devis row">
                                <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                    <div class="fist-line">
                                        <b class="name-devis">{{currentAssureur.nom}}</b> est une
                                        <b>{{currentAssureur.typeSociete}}</b> avec conseil d'administration au capital de
                                        <b>{{convertStringToNumber(currentAssureur.capital) |
                                            number:"1.0-0":'fr'}}</b>. Elle a été créée en
                                        <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                                        <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à : <b>Mermoz
                                            Pyrotechnie Lot 3</b>.
                                    </div>
                                    <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                                        <b>{{currentAssureur.email}}</b>
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                    <img class="footer-img" [src]="currentAssureur.img" alt="">
                                </div>
                            </div>

                            <div *ngIf="isSouscription">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn">Retour
                                    <i class="fa fa-chevron-left"></i></button>
                                <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger
                                    <i class="fa fa-download"></i></button>
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn">
                                  Souscrire <i class="fa fa-angle-double-right"></i>
                                </button>
                            </div>
                            <!-- </div> -->
                        </div>
                        <!-- proposition -->

                    </aw-wizard-step>

                    <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré">
                        <div class="form-container" formGroupName="assure">
                            <div class="row assurance-for justify-content-right">

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" placement="top" popoverTitle="Description" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-intersex form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" formControlName="choix" placeholder=" " class="form__select" name="radioFruit" (change)="setAssure(simulationVehiculeForm.get('assure').get('choix').value)">
                                            <option selected value="1">Pour moi-même</option>
                                            <option value="2">Pour le compte d'une personne physique</option>
                                            <!-- <option value="3">Pour le compte d'une personne morale</option> -->
                                        </select>
                                        <label for="cars" class="form__label">Veuillez choisir<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('choix').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                            </div>
                            <div class="row no-margin">
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">
                                    <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="prenom" id="prenom" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="prenom" class="form__label">Prénom<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">
                                    <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="nom" id="nom" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <!-- personne moral -->
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value == '3'">
                                    <div class="form" ngbPopover="Raison Sociale" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="raisonSociale" id="raisonSociale" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="raisonSociale" class="form__label">Raison Sociale<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('raisonSociale').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value == '3'">
                                    <div class="form" ngbPopover="Régistre de commerce" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="registreCommerce" id="registreCommerce" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="registreCommerce" class="form__label">Régistre de commerce<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('registreCommerce').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value == '3'">
                                    <div class="form" ngbPopover="Ninea" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="ninea" id="ninea" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="ninea" class="form__label">Ninea<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('ninea').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>


                                <!-- personne moral -->


                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-phone form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" id="telephone" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="telephone" class="form__label">Numéro téléphone<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-envelope form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="email" id="email" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                                        Ce champ est obligatoire ou invalide
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">

                                    <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="profession" id="profession" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="profession" class="form__label">Profession<span class="text-red">
                                                *</span></label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-home form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="adresse" id="adresse" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="adresse" class="form__label">Adresse<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                        <i class="fa fa-briefcase form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="secteuractivite" id="secteur" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="secteur" class="form__label">Secteur activité<span class="text-red">
                                                *</span></label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">
                                    <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                        <i class="fa-solid fa-venus form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="genre">
                                            <option value="" selected disabled>Sexe</option>
                                            <option value="M">Masculin</option>
                                            <option value="F">Féminin</option>
                                        </select>
                                        <label for="cars" class="form__label">Sexe <span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="assuree()">
                                    Suivant <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>
                                </button>
                            </div>
                        </div>
                    </aw-wizard-step>

                    <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
                        <section class="case-study-area ptb-70 pb-70 bg-fafafa">
                            <div class="row mb-4 justify-content-center">
                                <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                            </div>
                            <div class="container">
                                <div class="row c-cards">
                                    <div class="col-lg-4 col-md-6 col-sm-6" (click)="domicile()">
                                        <div class="single-case-study-box1 badge-content" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                            <div class="case-study-image bg1"></div>
                                            <div class="case-study-img-hover bg1"></div>
                                            <div class="case-study-info">
                                                <span class="category">Domicile <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Domicile'"></i></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6" (click)="relais()">
                                        <div class="single-case-study-box1 badge-content" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                            <div class="case-study-image bg2"></div>
                                            <div class="case-study-img-hover bg2"></div>
                                            <div class="case-study-info">
                                                <span class="category">Point relais  <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Relais'"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                                <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="mySelect" placeholder=" " class="form__select" formControlName="pointRelaisControl">
                                    <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                                    <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                                </select>
                                    <label for="mySelect" class="form__label">Point de relais<span class="text-red">
                                        *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                                <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="adresseLivraison" class="form__label">Adresse de livraison<span
                                        class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </section>
                        <div class="row mb-4 justify-content-center">
                            <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                            <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                <div class="badge">
                                    Domicile
                                </div>
                                <img width="135px" src="assets/images/domicile.jpg" alt="">
                            </div>
                            <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                <img width="135px" src="assets/images/relais.jfif" alt="">
                                <div class="badge">
                                    Point relais
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="form-group">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="adresseLivraison" class="form__label">Adresse<span class="text-red">
                                            *</span></label>
                                </div>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                            <div class="form-group">
                                <div class="input-group relais" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                    <div class="input-group-append">
                                        <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                    </div>
                                    <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                                        <option value="" disabled selected="selected">Sélectionner un point de relais
                                        </option>
                                        <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="row mb-4 justify-content-center">
                            <h6 class="col-12 text-center colorChoisir">Choisir un moyen de paiement</h6>
                            <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                                <div class="badge">
                                    {{pay?.nom}}
                                </div>
                                <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </aw-wizard-step> -->

                    <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement">
                        <section class="team-area ptb-50 pb-50">
                            <div class="row mb-2 justify-content-center">
                                <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                            </div>
                            <div class="container mt-5">
                                <div class="row c-cards">
                                    <div class="col-md-3 cardB" (click)="domicile()">
                                        <div class="image-container">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Domicile'"></i>
                                                </div>
                                            </div> <img src="assets/images/domicile.jfif" class="img-fluid rounded thumbnail-image tailImgB">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <h5 class="dress-name">Domicile</h5>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 cardB" (click)="relais()">
                                        <div class="image-container">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Relais'"></i>
                                                </div>
                                            </div> <img src="assets/images/relais.jfif" class="img-fluid rounded thumbnail-image tailImgB">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <h5 class="dress-name">Point relais</h5>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                                <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="mySelect" placeholder=" " class="form__select" formControlName="pointRelaisControl">
                                    <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                                    <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                                </select>
                                    <label for="mySelect" class="form__label">Point de relais<span class="text-red">
                                        *</span></label>
                                </div>
                                <div class="error  text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                                <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="adresseLivraison" class="form__label">Adresse de livraison<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </section>

                        <div class="row mb-2 justify-content-center">
                            <h6 class="col-12 text-center colorChoisir">Choisir un moyen de paiement</h6>
                        </div>
                        <section class="team-area ptb-50 pb-50">
                            <div class="container mt-5">
                                <div class="row">
                                    <div class="col-md-3 cardB" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)">
                                        <div class="image-container">
                                            <div class="first">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('moyenDePaiement').value == pay.code"></i>
                                                </div>
                                            </div> <img [src]="pay.logo" class="img-fluid rounded thumbnail-image tailImgB">
                                        </div>
                                        <div class="product-detail-container p-2">
                                            <div class="d-flex justify-content-center align-items-center">
                                                <h5 class="dress-name">{{pay?.nom}}</h5>
                                                <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="row justify-content-center">
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                            <div class="col-5 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </aw-wizard-step> -->


                    <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Récapitulatif">
                        <div class="recap-form-container">
                            <div class="mesImage">
                                <div class="imgLogo" *ngIf="!assureurOtherShow">
                                    <img [src]="rest?.assureur?.logo" [alt]="rest?.assureur?.nom">
                                </div>
                                <div class="imgLogo" *ngIf="assureurOtherShow">
                                    <img [src]="rest?.assureur?.uuid" [alt]="rest?.assureur?.nom">
                                </div>
                                <div class="imgLogo">
                                    <img [src]="logoImage" alt="Logo Abidjanaise">
                                </div>
                            </div>
                            <div class="info-devis">
                                <div class="proposition">
                                    <div class="pro-title">Devis automobile</div>
                                    <div class="sub-title">{{numeroSous}}</div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                        <div class="title-div">
                                            <span>Souscripteur</span>
                                            <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="goToStepValue(3)"></span> -->
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm?.get('assure')?.get('nom')?.value}} -->
                                                    {{user.infos.firstName}} {{user.infos.lastName}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{simulationVehiculeForm?.get('assure')?.get('email')?.value}} -->
                                                    {{user.infos.email}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}} -->
                                                    {{user.infos.telephone}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} -->
                                                    {{user.infos.adresse}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{simulationVehiculeForm?.get('assure')?.get('profession')?.value}} -->
                                                    {{user.infos.profession}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{simulationVehiculeForm?.get('assure')?.get('secteuractivite')?.value}} -->
                                                    {{user.infos.secteuractivite}}
                                                </div>
                                                <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{currentAssureur?.nom}} </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                        <div class="title-div">
                                            <span>Références Proposition</span>
                                            <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModalIV()"></span> -->
                                        </div>
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>
                                                <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                                </div> -->
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('assure')?.get('choix')?.value != 3">
                                                    {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm.get('assure').get('nom').value}}
                                                </div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('assure')?.get('choix')?.value == 3">
                                                    {{simulationVehiculeForm?.get('assure')?.get('raisonSociale')?.value}}
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{simulationVehiculeForm?.get('assure')?.get('email')?.value}}
                                                </div>

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}}
                                                </div>

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy'}} </div>

                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                                </div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    <!-- {{dateExpiration | date:'dd/MM/yyyy'}} -->
                                                    {{simulationVehiculeForm.get('dateEcheance')?.value | date:'dd/MM/yyyy'}}
                                                </div>
                                                <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                        <div class="title-div">
                                            <span>Caractéristiques du véhicule </span>
                                            <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos du véhicule" triggers="mouseenter:mouseleave" (click)="goToStepValue(0)"></span> -->
                                        </div>
                                        <div class="contenu-div" *ngIf="!assureurOtherShow">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('marque').value !='' ">
                                                            {{simulationVehiculeForm.get('marque').value}}
                                                        </div>
                                                        <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreMarque').value !='' ">
                                                            {{simulationVehiculeForm.get('autreMarque').value}}
                                                        </div> -->
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{currentCategorie}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('puissance').value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                            Valeur à neuf
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                            {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            <span class="fa fa-check"></span>
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                            {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('poids').value"> Charge utile (Kg)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('poids').value">
                                                            {{simulationVehiculeForm.get('poids').value}} </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('modele').value !=''">
                                                            {{simulationVehiculeForm.get('modele').value}}
                                                        </div>
                                                        <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreModele').value !=''">
                                                            {{simulationVehiculeForm.get('autreModele').value}}
                                                        </div> -->
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                            Valeur vénale
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                            {{simulationVehiculeForm.get('valeurVenal').value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="contenu-div" *ngIf="assureurOtherShow">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('immatriculation')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('autreMarque')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('puissance')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                            {{simulationVehiculeForm?.get('typeMoteur')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('nombrePlace')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('poids')?.value}} </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_mis_en_circulation')?.value |date:'dd/MM/yyyy'}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('autreModele')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_mis_en_circulation')?.value | date:'dd/MM/yyyy'}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="proposition2" *ngIf="!assureurOtherShow">
                                    <div class="pro-title"> Garanties souscrites et Primes d’assurance (en FCFA)</div>
                                </div>
                                <!-- new new new new new new new new new new  -->
                                <div class="row" *ngIf="!assureurOtherShow">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                    <div class="title-div-gar">
                                                        Nature des garanties
                                                    </div>
                                                    <div *ngFor="let garantie of garantieAss">
                                                        {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                    <div class="title-div-gar1">
                                                        Primes d’assurance
                                                    </div>
                                                    <div class="val" *ngFor="let garantie of garantieAss">
                                                        <span>{{garantie?.value |number:'1.0-0':'fr'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="description-ass" *ngIf="!assureurOtherShow">
                                    Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                                </div>
                                <div class="row" *ngIf="!assureurOtherShow">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                    <div *ngFor="let item of primeAss">
                                                        <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key == 'Prime RC'
                                                            ? 'Responsabilité Civile' : item?.key}} (FCFA)</span>
                                                        <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime
                                                            TTC (FCFA)</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                    <div class="val" *ngFor="let item of primeAss">
                                                        <span *ngIf="item.key!='Prime Totale TTC'">{{item.value |
                                                            number:'1.0-0':'fr'}}</span>
                                                        <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="assureurOtherShow">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">
                                        <div class="contenu-div">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                    <div>
                                                        <span class="valPrime">Prime TTC (FCFA)</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                    <div class="val">
                                                        <span class="valPrime">{{rest?.primeTTC | number:'1.0-0':'fr'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="!assureurOtherShow">
                                    <div class="title-dispo-footerL col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                        Assureur : {{currentAssureur?.nom}}
                                    </div>
                                    <div class="title-dispo-footerR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                        Date emission : {{newDate |date:'dd/MM/yyyy' }} E-MAIL : {{app.dci.email}}
                                    </div>
                                </div>
                                <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="assureurOtherShow">
                                    <div class="title-dispo-footerL col-md-6 col-lg-5 col-sm-12 col-xs-12">
                                        Assureur : {{rest?.assureur?.nom}}
                                    </div>
                                    <div class="title-dispo-footerR col-md-6 col-lg-7 col-sm-12 col-xs-12">
                                        Date emission : {{newDate |date:'dd/MM/yyyy' }} E-MAIL : {{app.dci.email}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-4 c-btnWidth">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                            <div class="col-4 c-btnWidthV2" style>
                                <button style="width: 180px !important;" type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="telechergerDevis()">Télécharger
                                <i class="fa fa-download"></i></button>
                            </div>
                            <div class="col-4 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest"> <i
                                class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                            </div>
                        </div>
                    </aw-wizard-step>
                </aw-wizard>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
