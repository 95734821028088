<header class="header-area">

    <!-- Start Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <ul class="top-header-nav">
                        <li>
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <span>Adresse email:</span>
                            <a>{{constantes.EMAIL}}</a>
                        </li>
                        <li>
                            <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <span>Téléphone:</span>
                                <a href="tel:{{constantes.TEL}}">{{constantes.TEL}}</a>
                        </li>
                    </ul>
                </div> -->
                <div class="col-lg-8 col-md-12">
                    <div class="top-header-right-side">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-email"></i>
                                </div>
                                <span>Adresse email:</span>
                                <a href={{email}}>{{email}}</a>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <span>Appelez-nous:</span>
                                <a href="tel:{{telephone}}">{{telephone}}</a>
                            </li>

                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="top-header-right-side rightAlign">

                        <div *ngIf="utilisateurs">
                            <span class="log-ini mt-3 ml-5" *ngIf="utilisateurs">
                                {{utilisateurs?.firstName}}
                                {{utilisateurs?.lastName}}</span>

                            <a target="_blank" placement="bottom" triggers="mouseenter:mouseleave" href="http://ma.suntelecoms.com/" class="log-in user" *ngIf="utilisateurs">
                                <i class="fa fa-user  ml-2 "></i>
                            </a>
                        </div>

                        <span class="log-ini" *ngIf="utilisateur">
                            {{utilisateur?.infos?.firstName}}
                            {{utilisateur?.infos?.lastName}}
                        </span>
                        <span class="log-out user" placement="bottom" [swal]="confirmDeconnexion" *ngIf="utilisateur">

                            <a target="_blank" placement="bottom"
                                    triggers="mouseenter:mouseleave" href="https://bo.abidjanaise.suntelecoms.com/" class="log-in user">
                                    <i class="fa fa-power-off  ml-2 colorIcon"></i>
                            </a>
                        </span>

                        <!-- <i class="search-btn flaticon-search"></i> -->
                        <!-- <i class="close-btn flaticon-cross-out"></i> -->

                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">

                                    <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="truz-responsive-nav">
            <div class="container">
                <div class="truz-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img class="tailleImage" src="/assets/image/img/Digit conseil logo/Logo Jpeg/CMJK pour l'impression/logo_2_3-06-removebg-preview.png" alt="logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="truz-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img class="tailleImage" [src]="logoImage" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <!-- <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Accueil</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Souscription<i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" *ngFor="let childrenItem of products">
                                        <a routerLink="{{childrenItem?.path}}" class="nav-link" *ngIf="childrenItem.type === 'link'">
                                            {{(childrenItem?.code === 'ASSURANCE MRH') ? ('Assurance MRH') : (childrenItem?.code === 'ASSURANCE SANTE') ? ('Assurance Santé') : (childrenItem?.code === 'ASSURANCE RCE') ? ('Responsabilté Civile Entreprise') : (childrenItem?.code === 'ASSURANCE TRC') ? ('Tous Risques Chantiers') : (childrenItem?.title  | titlecase)}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/" class="nav-link">Sinistre<i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/declaration-sinistre" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Déclaration</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul> -->
                        <ul class="navbar-nav">
                            <li class="nav-item" *ngFor="let menu of menu">
                                <a *ngIf="menu.sousMenu.length == 0" routerLink="/{{menu.url}}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{menu.titre}}</a>
                                <a *ngIf="menu.sousMenu.length !== 0" routerLink="/" class="nav-link">{{menu.titre}}<i class="flaticon-down-arrow"></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" *ngFor="let sousMenu of menu.sousMenu">
                                        <a *ngIf="sousMenu.sousMenu.length === 0" routerLink="/{{sousMenu.url}}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{sousMenu.titre}}</a>
                                        <a *ngIf="sousMenu.sousMenu.length !== 0" href="javascript:void(0)" class="nav-link">{{sousMenu.titre}}</a>
                                        <ul class="dropdown-menu" *ngIf="sousMenu.sousMenu">
                                            <li class="nav-item" *ngFor="let childrenItem of sousMenu.sousMenu">
                                                <a routerLink="{{childrenItem?.url}}" class="nav-link">
                                                    {{childrenItem?.titre  | titlecase}}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div class="others-option" *ngIf="espacePro">
                            <div class="option-item">
                                <ul>
                                    <li>
                                        <a target="_blank" href={{espacePro.url}} class="default-btn btnChange" style="margin-top: 20px;">{{espacePro.label}}</a>
                                    </li>
                                </ul>
                            </div>

                            <!-- <div class="burger-menu">
                                <i class="flaticon-menu"></i>
                            </div> -->
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->

</header>

<swal #confirmDeconnexion title="Confirmation" text="Voulez-vous vraiment proceder à la déconnexion ?" icon="question" [showCancelButton]="true" [reverseButtons]="true" [focusCancel]="false" [confirmButtonText]="'Confirmer'" [cancelButtonText]="'ANNULER'"
    (confirm)="deconnexion()">
</swal>
