import { Component, OnInit } from '@angular/core';
import { CONSTANTES } from 'src/app/shared/model/constantes';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }
  constantes = CONSTANTES;


  ngOnInit(): void {
  }

}
