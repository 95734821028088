<app-header-one></app-header-one>
<!-- <app-header-three></app-header-three> -->

<div class="page-title-area page-title-bg1 height_image bgO">
    <div class="d-table ">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-title-content">
                    <h1 class="text-white">ACCOMPAGNEMENT EN PROCESSUS DE TRANSFORMATION DIGITALE</h1>
                    <ul>
                        <!-- <li><a routerLink="/">Home</a></li> -->
                        <!-- <li>Identifier, Evaluer, Conseiller, Assurer</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">

                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Pourquoi le processus de transformation digitale ?</h3>
                        <p class="fs-5">La transformation digitale est vitale pour toute entreprise quelque soit son secteur d’activité
                            ou sa taille. En effet, les nouvelles technologies facilitent les processus métier et sont
                            conçues pour offrir une meilleure expérience client d’une part et optimiser les coûts d’autre
                            part.</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/image/img/img21fff.png" alt="image" class="img1">
                    </div>
                </div>
            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>En effet :</h3>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> Nous vous guidons à chaque étape du processus,</li>
                        <li><i class="fas fa-check"></i> Nous assurons une transition ﬂuide et réussie vers le numérique.</li>
                        <!-- <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                            <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                        </ul>
                    </div>

                <div class="col-lg-6 col-md-6">
                        <ul class="features-list">
                        <li><i class="fas fa-check"></i> Nous évaluons vos besoins et mettons en œuvre les solutions les plus adaptées</li>
                        <!-- <li><i class="fas fa-check"></i> conﬁez nous vos contentieux dans le règlement des sinistres</li> -->
                        <!-- <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>
            </div>

            <!-- <h3>Setting the mood with incense</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img1.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img3.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->

            <blockquote class="wp-block-quote">
                <p>Une entreprise sans stratégie est comme un puzzle sans image ; les pièces sont là,
                    mais l’ensemble demeure ﬂou.La stratégie est le tableau qui guide l’assemblage
                    révélant le panorama clair du succès à chaque pièce</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            <div class="about-inner-area">

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text-box">
                            <h3>INNOVER</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text-box">
                            <h3>CONSEILLER</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text-box">
                            <h3>TRANSFORMER</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->

            <!-- <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/health-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/img1.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Health Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/life-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Life Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/img3.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
