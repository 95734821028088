import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor (private httpClient: HttpClient) {
    }

    getDevis(data){
        return this.httpClient.post(environment.baseApiUrl +'parametrage-sante/get-devis',data);
    }
    getTypeFormuleAssure(){
        return this.httpClient.get(environment.baseApiUrl +'type-formule-assure');
    }
    getFormuleSante(){
        return this.httpClient.get(environment.baseApiUrl +'formules/get-formule-sante');
    }

    login2 () {
        return this.httpClient.post(environment.baseApiUrl + 'login', environment.defaultCredentials).pipe(response => response);
    }
    reset(body){
        return this.httpClient.post(environment.baseApiUrl + 'users/request-reset-password', body).pipe(response => response);
    }
    reinitialiser(body){
        return this.httpClient.post(environment.baseApiUrl + 'users/reset-password', body).pipe(response => response);
    }
    login(body){
        return this.httpClient.post(environment.baseApiUrl + 'login', body).pipe(response => response);
    }
    getFormules () {
        return this.httpClient.get(environment.baseApiUrl + 'formules?max=1000000&offset=0').pipe(response => response);
    }
    getPAys () {
        return this.httpClient.get(environment.baseApiUrl + 'pays?max=1000000&offset=0').pipe(response => response);
    }
    getmodel(make){
        return this.httpClient.get(environment.baseApiUrl + 'vehicle-model/get-model-by-make?make='+make).pipe(response => response);
    }
    getDuration () {
        return this.httpClient.get(environment.baseApiUrl + 'durees?max=1000000&offset=0').pipe(response => response);
    }
    getassureurs(body){
        return this.httpClient.post(environment.baseApiUrl + 'parametrage-mrh/get-assureur', body).pipe(response =>response);
    }
    etreRappele(body){
        return this.httpClient.post(environment.baseApiUrl + 'rappel-devis', body).pipe(response =>response);
    }
    getRappel() {
        return this.httpClient.get(environment.baseApiUrl + 'rappel-devis/rappel').pipe(response => response);
    }
    envoiDevisMail(body){
        return this.httpClient.post(environment.baseApiUrl + 'souscriptions/get-devis', body).pipe(response =>response);
    }
    assureur(){
        return this.httpClient.get(environment.baseApiUrl + 'assureurs?max=100000&offset=0').pipe(response =>response);
    }
    getPaymentMethods () {
        return this.httpClient.get(environment.baseApiUrl + 'moyens-de-paiement').pipe(response => response);
    }
    getListAgences () {
        return this.httpClient.get(environment.baseApiUrl + 'agences?max=100000&offset=0').pipe(response => response);
    }
    typegarantiemrh(){
        return this.httpClient.get(environment.baseApiUrl + 'type-garantie/get-type-garanties?type=MRH').pipe(response => response);
    }
    getProducts () {
        return this.httpClient.get(environment.baseApiUrl + 'type-produit').pipe(response => response);
    }

    getProduct () {

        return this.httpClient.get(environment.baseApiUrl + 'produits?max=100000000&offset=0').pipe(response => response);
    }

    saveSouscription (data) {
        return this.httpClient.post(environment.baseApiUrl + 'souscriptions', data).pipe(response => response);
    }

    saveSouscriptionOffline (data) {
        return this.httpClient.post(environment.baseApiUrl + 'autres-risques', data).pipe(response => response);
    }

    sign(data){
        return this.httpClient.post(environment.baseApiUrl + 'souscripteurs',data).pipe(response => response);
    }

    registerSinistre (data) {
        return this.httpClient.post(environment.baseApiUrl + 'sinistres?max=100000000&offset=0', data).pipe(response => response);
    }

    checkSinistre (data) {
        return this.httpClient.post(environment.baseApiUrl + 'contrats/check-validity', data).pipe(response => response);
    }
    saveContact (data) {
        return this.httpClient.post(environment.baseApiUrl + 'contacts', data).pipe(response => response);
    }

    initiateOnlinePayment (data) {
        return this.httpClient.post(environment.apgUrl+'/payment/api/initiateOnlinePayment', data).pipe(response => response);
    }
    initiateOnlinePaymentCinetpay (data) {
        return this.httpClient.post(environment.cinetpayUrl+'/payment', data).pipe(response => response);
    }

    checkPaymentCinetpay (data) {
        return this.httpClient.post(environment.cinetpayUrl+'/payment/check', data).pipe(response => response);
    }

    getVehiculeCategories () {
        return this.httpClient.get(environment.baseApiUrl + 'categorie-vehicule?max=100&offset=0').pipe(response => response);
    }
    getTransportPublicType() {
        return this.httpClient.get(environment.baseApiUrl + 'types-vehicule?max=100&offset=0').pipe(response => response);
    }
    getTarification(data) {
        return this.httpClient.post(environment.baseApiUrl + 'tarifications-auto/search',data).pipe(response => response);
    }
    getDurees () {
        return this.httpClient.get(environment.baseApiUrl + 'durees?max=100000000&offset=0&sort=duree').pipe(response => response);
    }
    getFuelType () {
        return this.httpClient.get(environment.baseApiUrl + 'vehicule/fueltype/all').pipe(response => response);
    }

    getGaranties () {
        return this.httpClient.get(environment.baseApiUrl + 'garantie'+'?max='+100 +'&offset=' +0).pipe(response => response);
    }
    getTypeGaranties () {
        return this.httpClient.get(environment.baseApiUrl + 'type-garantie/list-garanties'+'?max='+100 +'&offset=' +0).pipe(response => response);
    }

    vieProducts () {
        // http://localhost:9000/api/vie/products
        return this.httpClient.get<any[]>(environment.baseApiUrl + 'vie/products').pipe(response => response);
    }

    vieSimulation (simulation: Simulation) {
        return this.httpClient.post(environment.baseApiUrl + 'pricings/vie', simulation).pipe(response => response);
    }

    simulatePrime (data: any) {
        return this.httpClient.post(environment.baseApiUrl + 'garantie/get-price', data).pipe(response => response);
    }
    simulatePrimeMono (data: any) {
        return this.httpClient.post(environment.baseApiUrl + 'garantie/get-price-mono', data).pipe(response => response);
    }
    simulatePrimeVoyage (data: any) {
        return this.httpClient.post(environment.baseApiUrl + 'tarification-voyages/get-pricing-assureur', data).pipe(response => response);
    }
    getContract (data: any) {
        return this.httpClient.post(environment.assuranceApiUrl + 'pack/automobile/get-contract', data).pipe(response => response);
    }

    sinitreDeclaration (data: any) {
        return this.httpClient.post(environment.baseApiUrl + 'sinistres', data).pipe(response => response);
    }

    saveFile(data) {
        return this.httpClient.post(environment.baseApiUrl + "store-file", data).pipe(response => response);
    }

    souscriptions (data: any) {
        return this.httpClient.post(environment.baseApiUrl + 'souscriptions', data).pipe(response => response);
    }

    reqGet (uri: string) {
        return this.httpClient.get(environment.baseApiUrl + uri).pipe(response => response);
    }

    reqPost (uri: string, data: any) {
        return this.httpClient.post(environment.baseApiUrl + uri, data).pipe(response => response);
    }

    getPacks () {
        return this.httpClient.get(environment.assuranceApiUrl + 'packs').pipe(response => response);
    }
    getPacksmrh(){
        return this.httpClient.get(environment.baseApiUrl + 'garantie-mrh').pipe(response => response);
    }
    getprice(data){
        return this.httpClient.post(environment.baseApiUrl + 'parametrage-mrh/get-price', data).pipe(response => response);
    }
    getModel(){
      return this.httpClient.get(environment.baseApiUrl + 'vehicle-model/get-make').pipe(response => response);
    }

    initPaiementBack (data: any) {
        return this.httpClient.post(environment.baseApiUrl + 'payment-initialisation', data).pipe(response => response);
    }
    refreshToken (data: any) {
        return this.httpClient.get(environment.baseApiUrl + "users/refresh-token?refresh_token="+data).pipe(response => response);
    }

    sendMail(data){
        return this.httpClient.post(environment.baseApiUrl + 'souscriptions/send-devis',data).pipe(response => response);
    }
    getPointRelais (data: any) {
        return this.httpClient.get(environment.baseApiUrl + "point-relais/relais-by-assureur?assureur="+data).pipe(response => response);
    }
    getSouscription(id) {
        return this.httpClient.get(environment.baseApiUrl + "souscriptions/"+id).pipe(response => response);
    }
    meRappeller(data){
        return this.httpClient.post(environment.baseApiUrl + 'me-rappeller', data).pipe(response =>response);
    }
    remonterPlainte(data){
        return this.httpClient.post(environment.baseApiUrl + 'plainte', data).pipe(response =>response);
    }

    listParams() {
        return this.httpClient.get(environment.baseApiUrl + 'params-gens').pipe(response => response);
    }

    getDataStrapi() {
        return this.httpClient.get(environment.baseApiStrapi+'/api/dci-sites?populate=deep').pipe(response => response);
    }


    mockedInsurances () {
        let insurances = [
            // {
            //     img: 'assets/images/app_landing1/preview_movie.png',
            //     title: 'Assurance Automobile',
            //     description: '',
            //     slug: 'automobile'
            // },
            // {
            //     img: 'assets/images/app_landing1/video-pic.png',
            //     title: 'Assurance MRH',
            //     description: '',
            //     slug: 'mrh'
            // },
            // {
            //     img: 'assets/images/app_landing1/insurance-accident.jpg',
            //     title: 'Assurance Sante',
            //     description: '',
            //     slug: 'sante'
            // },
            // {
            //     img: 'assets/images/app_landing1/assurance-voyage.png',
            //     title: 'Assurance Voyage',
            //     description: '',
            //     slug: 'voyage'
            // },
            // {
            //     img: 'assets/images/app_landing1/mobile.png',
            //     title: 'Assurance Retraite',
            //     description: '',
            //     slug: 'retraite'
            // },
            // {
            //     img: 'assets/images/app_landing1/mobile.png',
            //     title: 'Assurance Vie',
            //     description: '',
            //     slug: 'vie'
            // }
        ];
        return new BehaviorSubject<any>(insurances);
    }

    mockedTestimonials () {
        let testimonials = [
            {
                img: 'assets/images/user-avatar.png',
                title: 'Très rapide',
                description: 'On gagne en temps et en argent',
                poste: 'Directeur Marketing SUN TELECOMS',
                slug: 'automobile'
            },
            {
                img: 'assets/images/user-avatar.png',
                title: 'Efficacité et dynamisme',
                description: 'Une équipe dynamique et un service client toujours disponible',
                poste: 'Directeur Marketing SUN TECH',
                slug: 'mrh'
            },
            {
                img: 'assets/images/user-avatar.png',
                title: 'La meilleure assurance',
                description: 'Merci pour cette belle initiative. C\'est super',
                poste: 'Directeur Marketing SOLARI',
                slug: 'sante'
            },
            {
                img: 'assets/images/user-avatar.png',
                title: 'Bravo à vous',
                description: 'J\'ai obtenu mon assurance en 1 jour. ',
                poste: 'Directeur Marketing SYFULUS',
                slug: 'voyage'
            },
            {
                img: 'assets/images/user-avatar.png',
                title: 'Garantie à 100%',
                description: 'Les garanties sont fiables à 100%',
                poste: 'Directeur Marketing COURONNE D\'OR',
                slug: 'retraite'
            },
            {
                img: 'assets/images/user-avatar.png',
                title: 'Garantie à 100%',
                description: 'Les garanties sont fiables à 100%',
                poste: 'Directeur Marketing COURONNE D\'OR',
                slug: 'vie'
            }
        ];
        return new BehaviorSubject<any>(testimonials);
    }

    mockedPartners () {
        let partners = [
            {
                img: 'assets/images/nsia.png',
            },
            {
                img: 'assets/images/bhs.png',
            },
            {
                img: 'assets/images/sgbs.png',
            },
            {
                img: 'assets/images/apg.png',
            },
            {
                img: 'assets/images/sun.png',
            },
            {
                img: 'assets/images/cofina.png',
            },
            {
                img: 'assets/images/bicis.png',
            }
        ];
        return new BehaviorSubject<any>(partners);
    }
}

export interface ISimulation {
    capitalGaranti: number
    dureeContrat: number
    age: number
    primeAnnuelle: number
    produit: string
}

export class Simulation implements ISimulation {
    age: number;
    capitalGaranti: number;
    dureeContrat: number;
    primeAnnuelle: number;
    produit: string;

    constructor (age: number,
                 capitalGaranti: number,
                 dureeContrat: number,
                 primeAnnuelle: number,
                 produit: string) {
        this.age = age;
        this.capitalGaranti = capitalGaranti;
        this.primeAnnuelle = primeAnnuelle;
        this.produit = produit;
        this.dureeContrat = dureeContrat;
    }
}
