<app-header-one></app-header-one>
<!-- <app-header-three></app-header-three> -->

<div class="page-title-area page-title-bg1 height_image bgO">
    <div class="d-table ">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-title-content">
                    <h1 class="text-white">ACCOMPAGNEMENT EN STRATÉGIE D’ENTREPRISE</h1>
                    <ul>
                        <!-- <li><a routerLink="/">Home</a></li> -->
                        <!-- <li>Identifier, Evaluer, Conseiller, Assurer</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="insurance-details-area ptb-100">
    <div class="container">
        <div class="insurance-details-header">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="image text-center">
                        <img src="assets/image/img/img21ff.png" alt="image" class="img1">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="content">
                        <h3>Pourquoi la stratégie d’entreprise ?</h3>
                        <p class="fs-5">Dans le contexte dynamique et incertain des affaires d'aujourd'hui,
                            l'élaboration d'une stratégie d'entreprise solide est essentielle pour
                            toute entreprise.
                            Sans une vision claire et proactive, les déﬁs et les opportunités qui se
                            présentent peuvent submerger, laissant l'entrepreneur réagir plutôt
                            qu'agir.
                            En travaillant sur une stratégie d'entreprise bien déﬁnie, l'entrepreneur
                            peut prendre les devants, anticiper les changements du marché et
                            diriger son entreprise vers la réussite.</p>

                    </div>
                </div>


            </div>
        </div>

        <div class="insurance-details-desc">
            <h3>En effet :</h3>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> nous vous accompagnons dans la réalisation et/ou la mise à jour
                            de votre plan d’action stratégique</li>
                        <!-- <li><i class="fas fa-check"></i> nous construisons un plan d’assurance adapté à vos risques assurables</li> -->
                        <!-- <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i> nous vons aidons à mettre à jour votre structure organisationnelle
                            en fonction de la stratégie retenue</li>
                        <!-- <li><i class="fas fa-check"></i> conﬁez nous vos contentieux dans le règlement des sinistres</li> -->
                        <!-- <li><i class="fas fa-check"></i> A Career Overview opportunity Available</li>
                        <li><i class="fas fa-check"></i> A good Work Environment For work</li> -->
                    </ul>
                </div>
            </div>

            <!-- <h3>Setting the mood with incense</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

            <ul class="wp-block-gallery columns-3">
                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img1.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img2.jpg" alt="image">
                    </figure>
                </li>

                <li class="blocks-gallery-item">
                    <figure>
                        <img src="assets/img/services-image/img3.jpg" alt="image">
                    </figure>
                </li>
            </ul>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->

            <blockquote class="wp-block-quote">
                <p>Une entreprise sans stratégie est comme un puzzle sans image ; les pièces sont là,
                    mais l’ensemble demeure ﬂou.La stratégie est le tableau qui guide l’assemblage
                    révélant le panorama clair du succès à chaque pièce</p>
                <cite>Tom Cruise</cite>
            </blockquote>
            <div class="about-inner-area">
            <h3>Notre Solution :</h3>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text-box">
                            <h3>VISION</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text-box">
                            <h3>ACTION</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text-box">
                            <h3>RÉALISATION</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->

            <!-- <div class="truz-post-navigation">
                <div class="prev-link-wrapper">
                    <div class="info-prev-link-wrapper">
                        <a routerLink="/health-insurance">
                            <span class="image-prev">
                                <img src="assets/img/services-image/img1.jpg" alt="image">
                                <span class="post-nav-title">Prev</span>
                            </span>
                            <span class="prev-link-info-wrapper">
                                <span class="prev-title">Health Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="next-link-wrapper">
                    <div class="info-next-link-wrapper">
                        <a routerLink="/life-insurance">
                            <span class="next-link-info-wrapper">
                                <span class="next-title">Life Insurance</span>
                                <span class="meta-wrapper">
                                    <span class="date-post">January 21, 2020</span>
                                </span>
                            </span>
                            <span class="image-next">
                                <img src="assets/img/services-image/img3.jpg" alt="image">
                                <span class="post-nav-title">Next</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
