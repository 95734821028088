import {Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, TemplateRef,} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {SubscriptionService} from 'src/app/shared/service/subscription.service';
import {environment} from 'src/environments/environment';
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WizardComponent} from 'angular-archwizard';
import {UtilsService} from 'src/app/shared/service/utils.service';
import {Console} from 'console';
import {min} from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import {interval} from 'rxjs';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import {animate, style, transition, trigger} from '@angular/animations';
import * as moment from 'moment';
import {AccountService} from 'src/app/shared/service/account.service';
import {GenererPdfService} from "../../../shared/service/generer-pdf.service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { CONSTANTES } from 'src/app/shared/model/constantes';
import { APP } from 'src/app/shared/model/model';


@Component({
  selector: 'app-home-mrh',
  templateUrl: './home-mrh.component.html',
  styleUrls: ['./home-mrh.component.scss'],
  animations: [
    trigger('fade', [
        transition('void => *', [
            style({opacity: 0}),
            animate(2500)
        ]),
        transition('* => void', [
            animate(2500),
            style({opacity: 0}),
        ])
    ]),
    trigger("slideInOut", [
        transition('void => *', [
            style({transform: "translateX(60%)", opacity: 0}),
            animate(
                "1500ms ease-in",
                // style({ transform: "translateX(0%)", opacity: 1 })
            ),
        ]),
        transition('* => void', [
            animate(
                "1500ms ease-out",
                // style({ transform: "translateX(60%)", opacity: 0 })
            ),
        ]),
    ]),
]

})
export class MrhComponent implements OnInit  {
    app = APP;
    logoImage = `/assets/image/img/Digit conseil logo/Logo Jpeg/CMJK pour l\'impression/${this.app.dci.logo}`;;

  sendingRequest: boolean = false;
  model: any[];
  pwd = 'password';

  apiResponse: any = {
      title: '',
      text: '',
      type: ''
  };
  login: boolean = false;
  garantie: any = [];
  beneficiaryChoosen: boolean = false;
  paymentMethod: string = "Paiement par agence";
  beneficiaries = [];
  avalaibleDuration = [];
  paymentMethods = [];
  tmpPaymentMethods = [];
  numeroSous = "";
  refPro = "";
  initPaymentForm = {
      requestId: 0,
      numeroSouscription: '',
      status: '',
      montant: '',
  }
  dateExpiration: any;
  // sendingRequest: boolean = false;
  assure: number = 1;
  phoneMask: string = "00 00 000 000";
  cinMask: string = "0 000 0000 00000";
  dateTime = Math.floor(Date.now() / 1000);
  today: any = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
  };
  date: any;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mrhForm = new FormGroup({
      moyenDePaiement: new FormControl("paiement_agence", [Validators.required]),
      livraison: new FormControl('Domicile', [Validators.required]),
      assureur: new FormControl("", [Validators.required]),
      canal: new FormControl(1, [Validators.required]),
      pack: new FormControl('mrh'),
      agence: new FormControl(""),
      isSubscriber: new FormControl(true),
      pointRelaisControl: new FormControl("", [Validators.required]),
      adresseLivraison: new FormControl("", [Validators.required]),
      packForm: new FormGroup({
          pack: new FormControl('pack_auto_mrh'),
          sub_package: new FormControl(""),
          qualite_souscripteur: new FormControl("", [Validators.required]),
          loyer_mensuel: new FormControl(""),
          pieces: new FormControl("", [Validators.required]),
          surface: new FormControl("", []),
          valeur_batiment: new FormControl(""),
          valeur_contenu: new FormControl(0, [Validators.required]),
          situation_risque: new FormControl("", [Validators.required]),
      }),
      packForm1: new FormGroup({
          pack: new FormControl('pack_auto_mrh'),
          sub_package: new FormControl(""),
          qualite_souscripteur: new FormControl('proprietaire', [Validators.required]),
          loyer_mensuel: new FormControl(""),
          pieces: new FormControl("2", [Validators.required]),
          surface: new FormControl("", []),
          valeur_batiment: new FormControl("", [Validators.required]),
          effet: new FormControl("", [Validators.required]),
          valeur_contenu: new FormControl(0),
          situation_risque: new FormControl("", [Validators.required]),
          niveauImmeuble: new FormControl("", [Validators.required]),
          numeroEtage: new FormControl("", []),
      }),

      vehiculeForm: new FormGroup({
          marque: new FormControl("", [Validators.required]),
          // modele: new FormControl("", [Validators.required]),
          matricule: new FormControl("", [Validators.required]),
          puissance: new FormControl("", [Validators.required]),
          nb_place: new FormControl("", [Validators.required]),
          date_mis_en_circulation: new FormControl("", [Validators.required]),
      }),
      contractForm: new FormGroup({
          valeur_neuf: new FormControl("", [Validators.required]),
          valeur_venal: new FormControl("", [Validators.required]),
          effet: new FormControl("", [Validators.required]),
          echeance: new FormControl("", [Validators.required]),
      }),
      assure: new FormGroup({
          prenom: new FormControl("", [Validators.required]),
          nom: new FormControl("", [Validators.required]),
          dateNaissance: new FormControl(""),
          profession: new FormControl(""),
          numeroTelephone: new FormControl("", [Validators.required]),
          numeroCni: new FormControl(""),
          adresse: new FormControl("", [Validators.required]),
          secteuractivite: new FormControl("", [Validators.required]),
          genre: new FormControl("", [Validators.required]),
          email: new FormControl("", [Validators.pattern(this.emailPattern)]),
          choix: new FormControl("", [Validators.required])
      }),
      souscripteur: new FormGroup({
          prenom: new FormControl("", [Validators.required]),
          nom: new FormControl("", [Validators.required]),
          genre: new FormControl(""),
          email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
          dateNaissance: new FormControl(""),
          telephone: new FormControl("", [Validators.required]),
          numeroCni: new FormControl("")
      }),

      personForm: new FormGroup({
          first_name: new FormControl("", [Validators.required]),
          last_name: new FormControl("", [Validators.required]),
          phone: new FormControl("", [Validators.required]),
          address: new FormControl("", [Validators.required]),

      })
  });
  forgotForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),

  });
  loginform = new FormGroup({
      username: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
  });
  inscriptionForm = new FormGroup({
      tel: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.pattern(this.emailPattern)]),
  });
  etrerappeleForm = new FormGroup({
      date: new FormControl("", [Validators.required]),
      heure: new FormControl([], [Validators.required]),
      code: new FormControl("", [Validators.required]),
      type: new FormControl("MRH", [])
    });

    simulationVehiculeForm = new FormGroup({
      livraison: new FormControl('domicile', [Validators.required]),
      moyenDePaiement: new FormControl("paiement_agence", [Validators.required]),
      assureur: new FormControl("", [Validators.required]),
      agence: new FormControl(""),
      isSubscriber:new FormControl(true),
      canal: new FormControl(1, [Validators.required]),
      pack: new FormControl("auto"),
      immatriculation: new FormControl("", [Validators.required]),
      marque: new FormControl("", [Validators.required]),
      modele: new FormControl("", [Validators.required]),
      categorieVehicule: new FormControl("", [Validators.required]),
      typeVehicule: new FormControl("", [Validators.required]),
      valeurNeuf: new FormControl("", [Validators.required]),
      valeurVenal: new FormControl("", [Validators.required]),
      duree: new FormControl("", [Validators.required]),
      typeMoteur: new FormControl("", [Validators.required]),
      poids: new FormControl("", [Validators.required]),
      nombrePlace: new FormControl(5, [Validators.required]),
      first_name: new FormControl("", [Validators.required]),
      last_name: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
      date_mis_en_circulation: new FormControl("", [Validators.required]),
      date_effet: new FormControl(new Date(), [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      address: new FormControl("", [Validators.required]),
      // carte_grise: new FormControl(null ,[Validators.required]),
      // profession: new FormControl(null ,[Validators.required]),
      puissance: new FormControl("", [Validators.required]),
      assure: new FormGroup({
        prenom: new FormControl("", [Validators.required]),
        nom: new FormControl("", [Validators.required]),
        dateNaissance: new FormControl(""),
        profession: new FormControl(""),
        numeroTelephone: new FormControl("", [Validators.required]),
        numeroCni: new FormControl(""),
        secteuractivite : new FormControl("", [Validators.required]),
        adresse: new FormControl("", [Validators.required]),
        // lateralite: new FormControl("", [Validators.required]),
        genre: new FormControl("", [Validators.required]),
        choix: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.pattern(this.emailPattern)])
      }),
      souscripteur: new FormGroup({
        prenom: new FormControl("", [Validators.required]),
        nom: new FormControl("", [Validators.required]),
        genre: new FormControl(""),
        email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
        dateNaissance: new FormControl(""),
        telephone: new FormControl("", [Validators.required]),
        numeroCni: new FormControl("")
      }),
      pointRelaisControl: new FormControl("", [Validators.required]),
      adresseLivraison: new FormControl("", [Validators.required]),
    });
  pers
  // key = "676AC4CC226201685E89ADB0C9B4510D";
  key = "FC48ABF885F26C13B0BE46B54618BC";
  paymentForm = {
      timestamp: this.dateTime,
      returnUrl: 'https://web-ma.suntelecoms.com/api/contrats/save-contrat',
      auth: '',
      amount: '',
      requestId: this.dateTime,
      // terminalNumber: 160820000101,
      terminalNumber: 423820000101,
      transactionCurrencyCode: 'XOF',
      customerLastName: '',
      customerFirstName: '',
      customerAddress: '',
      customerCountry: '',
      customerEmail: '',
      customerPhone: '',
      customerState: '',
      customerCity: '',
      customerZipCode: '',
      ville: '',
      montantSaisie: '',
  }
  personcontactForm = new FormGroup({
      first_name: new FormControl("", [Validators.required]),
      last_name: new FormControl("", [Validators.required]),
      telephone: new FormControl("", [Validators.required]),
      jour: new FormControl("", [Validators.required]),
      heure: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.pattern(this.emailPattern)]),
      designation: new FormControl('mrh')
  })

  rappellerForm = new FormGroup({
    nom: new FormControl("", [Validators.required]),
    prenom: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    telephone: new FormControl("", [Validators.required]),
    disponibilite: new FormControl("", [Validators.required]),
    heureDisponibilite: new FormControl("", [Validators.required])
  });
  sendingRequestRappel: boolean = false;

   heureDisponibiliteList = [{ name: "08H-10H", value: "08H-10H",filtre:'Matin'},{ name: "10H-12H", value: "10H-12H",filtre:'Matin'},{ name: "12H-14H", value: "12H-14H",filtre:'Matin'},{ name: "15H-16H", value: "15H-16H",filtre:'Soir'},{ name: "16H-17H", value: "16H-17H",filtre:'Soir'},{ name: "17H-18H", value: "17H-18H",filtre:'Soir'}];
   heureDisponibiliteListTemoin = []
   disponibiteList = [{ name: "Matin", value: "Matin"},{ name: "Soir", value: "Soir"},{ name: "Toute la journée", value: "Toute la journee"}]

  listJours = [{name: 'Tous les jours'}, {name: 'Lundi'}, {name: 'Mardi'}, {name: 'Mercredi'}, {name: 'Jeudi'}, {name: 'Vendredi'}];
  listHeures = [{name: "08h-10h"}, {name: "10h-12h"}, {name: "12h-14h"}, {name: "14h-16h"}, {name: "16h-18h"}];
  valeur: any;
  capital: any;
  prix: any;
  granddevis: any;
  _idAssurance: string;
  _typeAssurance: string;
  tabElementMaison = [{
      name: "Valeur du batiment",
      value: "valeur_batiment",
      type: "montant"
  }, {name: "Valeur du contenu", value: "valeur_contenu", type: "montant"}, {
      name: "Surface développée en m2",
      value: "surface",
      type: "text"
  }, {name: "Situation risque", value: "situation_risque", type: "text"}, {
      name: "Date début contrat",
      value: "effet",
      type: "date"
  }];
  tabElementAssure = [{name: "Prénom", value: "prenom", type: "text"}, {
      name: "Nom",
      value: "nom",
      type: "text"
  }, {name: "Date de naissance", value: "dateNaissance", type: "date"}, {
      name: "Numéro téléphone",
      value: "numeroTelephone",
      type: "text"
  }, {name: "Numéro CNI", value: "numeroCni", type: "text"}, {
      name: "Email",
      value: "email",
      type: "text"
  }, {name: "Adresse", value: "adresse", type: "text"}];
  mrh: boolean = false;
  mri: boolean = false;
  datevalid: boolean = false;
  pack: any = [];
  @ViewChild('contentResult', {static: false}) contentResult: any;
  @ViewChild('contentResultcontact', {static: false}) contentResultmail: any;
  @ViewChild('updateAssures', { static: false }) modalAssure: any;
  @ViewChild('updateMaison', { static: false }) modalMaison: any;
  @ViewChild('contentResultass') contentResultass: any;
  @ViewChild('mrhDownload') mrhDownload: ElementRef;
  @ViewChild('btnDownload') btnDownload: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('contentResultforgot', {static: false}) modaforgot: any;
  @ViewChild('contentResultlogin', {static: false}) modalog: any;
  @ViewChild('contentResultsign', {static: false}) modasign: any;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('wizard', {static: false}) wizard: WizardComponent;
  @ViewChild('devisAEnvoye', { static: false }) devisAEnvoye: any;
  @ViewChild('etreRappeller', { static: false }) etreRappeller: any;
  result: any = {};
  resultmrh: any = [];
  subPackag: any = {};
  packofpack: any = [];
  packmrh: any;
  Datemin: any;
  Datemax: any;
  assureur: any;
  resultass: any = [];
  invalid: boolean;
  base: any;
  devisend: boolean = false;
  domicilier: boolean;
  paiment: boolean = false;
  loaderVisible: boolean;
  userInconnu: string;
  gomrh: boolean;
  gomri: boolean;
  user: any;
  users: any;
  userNew: any;
  tokenid: any;
  listAgences: any = [];
  currentSouscripteur = "Pour moi-même";
  currentDom = "activeLivraison";
  currentRel = "";
  currentGarantie = "";
  currentAssureur:any = "";
  loadContact: boolean = false;
  newDate = new Date();
  sendingRequestAssureur: boolean = false;
  datas: any;
  detailsPdf: any;
  message:any;
  uuId: any;
  pointRelais: any;
  currentId;
  assureurId;
  donnee;
  primeAss:any;
  updateRequest: boolean = false;
  assureurAEnvoye;
  vehiculeCategories = [];
  isSouscription:boolean;
  actif:any={};
  dateRappel = [{ name: "Lundi", value: "lundi"},{ name: "Mardi", value: "mardi"}, { name: "Mercredi", value: "mercredi"}, { name: "Jeudi", value: "jeudi"}, { name: "Vendredi", value: "vendredi"}, { name: "Samedi", value: "samedi"}, { name: "Dimanche", value: "dimanche"}];
heureRappel = [{ name: "08h:00", id: "08:00"}, { name: "09h:00", id: "09:00"}, { name: "10h:00", id: "10:00"}, { name: "11h:00", id: "11:00"}, { name: "12h:00", id: "12:00"}, { name: "13h:00", id: "13:00"}, { name: "14h:00", id: "14:00"}, { name: "15h:00", id: "15:00"}, { name: "16h:00", id: "16:00"}, { name: "17h:00", id: "17:00"}, { name: "18h:00", id: "18:00"}, { name: "19h:00", id: "19:00"}, { name: "20h:00", id: "20:00"}, { name: "21h:00", id: "21:00"}, { name: "22h:00", id: "22:00"}, { name: "23h:00", id: "23:00"}];

  @ViewChild('closeBTN') closeBTN: ElementRef;
  noAssureur: boolean = false;
  natureLogement: boolean = true;
  constantes = CONSTANTES;


  // resultmrh: any;
  constructor(private subscriptionService: SubscriptionService,
              private location: Location,
              private route: ActivatedRoute,
              private routerr: Router,
              private modalService: NgbModal,
              private utilsService: UtilsService,
              private accountService: AccountService, private genererPdfService: GenererPdfService) {

      this.datas = this.route.snapshot.params['data'];
      this.route.queryParams.subscribe(params => {
          this.datas = params.data;
          this.datas = 6;
      });
  }

    // Settings configuration
mySettings: IMultiSelectSettings = {
  enableSearch: true,
  checkedStyle: 'fontawesome',
  buttonClasses: 'btn btn-default btn-block',
  dynamicTitleMaxItems: 3,
  displayAllSelectedText: true
};

// Text configuration
myTexts: IMultiSelectTexts = {
checkAll: 'Selectionner Tout',
uncheckAll: 'Décocher Tout',
checked: 'Element Selectionné',
checkedPlural: 'Element Selectionné',
searchPlaceholder: 'Chercher',
searchEmptyResult: 'Aucun elément trouvé...',
searchNoRenderText: 'Type in search box to see results...',
defaultTitle: 'Select',
allSelected: 'Tout selectionné',
};

  get idAssurance() {
      return this._idAssurance;
  }

  @Input()
  set idAssurance(id) {
      this._idAssurance = id;
      // this.fireFunctions();
  }

  get typeAssurance() {
      return this._typeAssurance;
  }

  @Input() set typeAssurance(type: string) {
      this._typeAssurance = type;
  }

  ngOnInit(): void {
      this.genererPdfService.genererPdf(this.datas).subscribe((resp) => {
          this.detailsPdf = resp['data'];
          let id_ass = this.detailsPdf.assureur.id;
          let ass = this.detailsPdf.assureur;
          this.getprix(id_ass, ass);
      });
      this.user = JSON.parse(localStorage.getItem('microFinance'));
      this.users = JSON.parse(localStorage.getItem('microFinance'));

      this.userNew = JSON.parse(localStorage.getItem('microFinance'));

      if (this.user)
          this.tokenid = this.user.id_token;
      let time = new Date().getTime();
      let pro = new Date().getTime() + 123890989;
      this.numeroSous = "SN" + time + "SC";
      this.refPro = "SN" + pro + "PR";
      this.getPaymentMethods();
      this.getPacksmrh();
      this.getPacks();
      this.getAgences();
      this.getModelvoiture();

      this.date = this.today.day + '/' + this.today.month + '/' + this.today.year;
      // let data = (this.today.day + '/' + this.today.month + '/' + this.today.year);
      // this.mrhForm.get('packForm1').get('effet').setValue(new Date());
  }

  handleBorder(event,noMrh, i){
    this.actif[noMrh] =  this.actif[noMrh] == true ? false : true;
  }

  choixmrh() {
      this.gomrh = true;
      this.gomri = false;
      let token = JSON.parse(localStorage.getItem('microFinance'));
      if (token) {
          this.login = true;
          this.mrh = true;
          this.mri = false;
      } else {

          this.modalService.open(this.modalog, {
              ariaLabelledBy: 'modal-basic-title',
              size: <any>'lg'
          }).result.then((result) => {
              if (result == 'subscribe') {

              }
          }, (reason) => {
          });

      }
  }

  convertStringToNumber(val) {
      return parseFloat(val);
  }

  inscrireutil() {
      if (this.inscriptionForm.valid) {
          let data = {
              "telephone": this.inscriptionForm.get('tel').value,
              "email": this.inscriptionForm.get('email').value,
              "type": 5 + ""
          }
          this.loaderVisible = true;
          this.subscriptionService.sign(data).subscribe((resp) => {
              if (resp["responseCode"] == 200) {
                  this.utilsService.showToastSuccess("Les informations d'activation de votre compte vous ont été envoyées par email et/ou SMS.", "Inscription  réussie");
                  this.modalService.dismissAll(this.modasign);
                  this.loaderVisible = false;
                  this.inscriptionForm.reset();
              } else {
                  this.loaderVisible = false;
                  this.utilsService.showToastError(resp['message'], "Echec");
              }
              this.loaderVisible = false;
          }, error => {
              this.utilsService.showToastError(error.error.errors[0].message, "Echec");
              this.loaderVisible = false;
          });

      } else {
          this.checkValidity(this.inscriptionForm);
      }
  }

  checkValidity(g: FormGroup) {
      Object.keys(g.controls).forEach(key => {
          g.get(key).markAsDirty();
      });
      Object.keys(g.controls).forEach(key => {
          g.get(key).markAsTouched();
      });
      Object.keys(g.controls).forEach(key => {
          g.get(key).updateValueAndValidity();
      });
  }


  lanceforgot() {
      this.modalService.dismissAll(this.modalog);
      this.modalService.open(this.modaforgot, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'xl'
      }).result.then((result) => {
          if (result == 'subscribe') {

          }
      }, (reason) => {
      });
  }

  forgot() {
      let data = {
          // "telephone": this.inscriptionForm.get('tel').value,
          // "nom": this.inscriptionForm.get('nom').value,
          "email": this.inscriptionForm.get('email').value,
          // "type":""+this._idAssurance,
      }
      this.loaderVisible = true;
      this.subscriptionService.reset(data).subscribe((resp) => {
          if (resp["responseCode"] = 200) {
              this.utilsService.showToastSuccess("Un mail vous a été envoyé pour réinitialiser votre mot de passe", "");
              this.modalService.dismissAll(this.modasign);
          } else {
              this.utilsService.showToastError(resp['message'], "Echec");
          }
          this.loaderVisible = false;
      }, error => {
          let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
          if (error && error.error) {
              message = error.error.message;
          }
          this.utilsService.showToastError(message, "Echec");

          this.loaderVisible = false;
      })
  }

  choixmri() {
      this.modalService.dismissAll(this.modasign);
      this.gomri = true;
      this.gomrh = false;
      let token = JSON.parse(localStorage.getItem('microFinance'));
      if (token) {
          this.login = true;
          this.mri = true;
          this.mrh = false;
      } else {
          this.modalService.open(this.modalog, {
              ariaLabelledBy: 'modal-basic-title',
              size: <any>'lg'
          }).result.then((result) => {
              if (result == 'subscribe') {

              }
          }, (reason) => {
          });
      }
  }

  inscrire() {
      this.modalService.dismissAll(this.modalog);
      this.modalService.open(this.modasign, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'lg'
      }).result.then((result) => {
          if (result == 'subscribe') {

          }
      }, (reason) => {
      });
  }

  connecter() {
      this.userInconnu = "";
      if (this.loginform.valid) {
          this.loaderVisible = true;
          let data = {
              ...this.loginform.value
          }
          this.subscriptionService.login(data)
              .subscribe((res) => {
                  if (res['data']) {
                      const tokenObj = res['data'];
                      let infos = {
                          id: tokenObj.id,
                          matricule: tokenObj.matricule,
                          firstName: tokenObj.firstName,
                          lastName: tokenObj.lastName,
                          email: tokenObj.email,
                          telephone: tokenObj.telephone,
                          secteuractivite: tokenObj.secteuractivite,
                          profession: tokenObj.profession,
                          adresse: tokenObj.adresse,
                          genre:  tokenObj.genre
                      }
                      if (res['data']['access_token']) {
                          let detail = {
                              id_token: tokenObj.access_token,
                              refresh_token: tokenObj.refresh_token,
                              // roles: tokenObj.roles,
                              infos: infos,
                              authorities: tokenObj.authorities
                          };
                          localStorage.setItem('microFinance', JSON.stringify(detail));
                          localStorage.setItem('microFinanceBo', JSON.stringify(detail));
                          this.accountService.sendAccount(JSON.stringify(infos));
                          this.user = detail;
                          if (this.gomrh) {
                              this.mrh = true;
                              this.mri = false;
                          } else if (this.gomri) {
                              this.mri = true;
                              this.mrh = false;
                          }
                          this.login = true;
                          this.getPaymentMethods();
                          this.getPacksmrh();
                          this.getPacks();
                          this.getModelvoiture();
                          this.getAgences();
                          // this.loaderVisible = false;
                          this.utilsService.showToastSuccess("Effectuée avec succés", "Connexion");
                          this.scroll();
                          this.modalService.dismissAll(this.modalog);
                      } else {
                          if (this.gomrh) {
                              this.choixmrh();
                          } else if (this.gomri) {
                              this.choixmri();
                          }
                          this.userInconnu = res['message'];
                          this.loaderVisible = false;
                      }
                  } else {
                      this.loaderVisible = false;
                      this.userInconnu = res['message'];
                  }
                  this.loaderVisible = false;
              }, (err) => {
                  this.userInconnu = err['error']['message'];
                  this.loaderVisible = false;
              })
      } else {
          this.checkValidity(this.loginform);
      }

  }

  choixinit() {
      this.login = false;
      this.mri = false;
      this.mrh = false;
  }

  nextstep() {
      if (this.mrhForm.get('packForm1').get('qualite_souscripteur').invalid || this.mrhForm.get('packForm1').get('pieces').invalid || this.mrhForm.get('packForm1').get('valeur_contenu').invalid || this.mrhForm.get('packForm1').get('valeur_batiment').invalid || this.mrhForm.get('packForm1').get('surface').invalid || this.mrhForm.get('packForm1').get('effet').invalid) {
          this.invalid = true
          //  this.wizard.goToNextStep();
      } else {
          this.wizard.goToNextStep();
          window.scrollTo(500, 500);
          this.invalid = false;
      }
  }

  paymentform() {
      if (this.mrhForm.get('packForm').get('qualite_souscripteur').invalid || this.mrhForm.get('packForm').get('pieces').invalid || this.mrhForm.get('packForm').get('valeur_contenu').invalid || this.mrhForm.get('packForm').get('valeur_batiment').invalid || this.mrhForm.get('packForm').get('surface').invalid || this.mrhForm.get('packForm').get('effet').invalid) {
          this.invalid = true
          //  this.wizard.goToNextStep();
      } else {
          this.wizard.goToNextStep();
          window.scrollTo(500, 500);
          this.invalid = true;
      }
  }

  getPacksmrh() {

      this.subscriptionService.typegarantiemrh().subscribe((resp) => {
          let packofpack = resp['data'];
          this.packofpack = packofpack;
      });
  }

  readmore(sp) {
      document.getElementById("mrh" + sp).style.display = "block";
      document.getElementById("less" + sp).style.display = "inline";
      document.getElementById("less" + sp).style.color = "white";
      document.getElementById("more" + sp).style.display = "none";
  }

  domicile() {
      this.pointRelais = null;
      this.currentDom = "activeLivraison";
      this.currentRel = "";
      this.paiment = true;
      this.mrhForm.get('livraison').setValue('Domicile');
      this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
      this.mrhForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
  }

  relais() {
      this.currentRel = "activeLivraison";
      this.currentDom = "";
      this.mrhForm.get('livraison').setValue('Relais');
      this.paiment = true;
      this.paymentMethods = this.tmpPaymentMethods;
      this.mrhForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
      this.subscriptionService.getPointRelais(this.currentAssureur['id']).subscribe((resp) => {
          this.pointRelais = resp['data'];
      });
  }

  lessmore(sp) {
      document.getElementById("mrh" + sp).style.display = "none";
      document.getElementById("less" + sp).style.display = "none";
      document.getElementById("more" + sp).style.display = "inline";
      document.getElementById("more" + sp).style.color = "white";
  }

  getPacks() {
      this.subscriptionService.getPacks().subscribe((resp) => {
          let sPacks = resp['data'];
          if (sPacks && sPacks.length > 0) {
              this.pack = sPacks.find((p) => p.code == 'pack_auto_mrh');
          }


      });
  }

  scroll() {
    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  souscrire() {
      this.scroll();
      this.modalService.dismissAll(this.modasign);
      this.gomrh = false;
      this.gomri = true;
      let token = JSON.parse(localStorage.getItem('microFinance'));
      if (token) {
          this.login = true;
          this.mrh = false;
          this.mri = true;
      } else {
          this.modalService.open(this.modalog, {
              ariaLabelledBy: 'modal-basic-title',
              size: <any>'lg'
          }).result.then((result) => {
              if (result == 'subscribe') {

              }
          }, (reason) => {
          });
      }
  }
  getModelvoiture() {
      this.subscriptionService.getModel().subscribe((resp) => {
          this.model = resp['data'];
      });
  }

  getPrime() {
      this.sendingRequest = true;
      let data = {
          ...this.mrhForm.get('packForm').value,
          ...this.vehiculeForm.value,
          ...this.contractForm.value
      }

      this.subscriptionService.simulatePrime(data).subscribe((resp) => {
          if (resp['code'] == 200) {
              this.result = resp['data'];
              this.openModalResult();
          } else {
              this.utilsService.showToastError(resp['message'], "Données invalides");
          }
          this.sendingRequest = false;
      }, error => {
          this.sendingRequest = false;
          let msg = "Impossible de contacter le serveur"
          if (error && error.error && error.error.message)
              msg = error.error.message;

          this.utilsService.showToastError(msg, "Données invalides");
      });
  }

  openModalResult() {
      this.modalService.open(this.contentResult, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'lg'
      }).result.then((result) => {
          if (result == 'subscribe') {
              this.wizard.goToNextStep();
              window.scrollTo(500, 500);
              let user = this.user.infos;
              this.currentSouscripteur = "Pour moi-même";
              this.mrhForm.get('assure').get('nom').setValue(user.lastName);
              this.mrhForm.get('assure').get('prenom').setValue(user.firstName);
              this.mrhForm.get('assure').get('email').setValue(user.email);
              this.mrhForm.get('assure').get('numeroTelephone').setValue(user.telephone);
              this.mrhForm.get('assure').get('adresse').setValue(user.adresse);
              this.mrhForm.get('assure').get('profession').setValue(user.profession);
              this.mrhForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
          }
      }, (reason) => {
      });
  }
  packForm(code) {
      this.mrhForm.get('packForm').get('sub_package').setValue(code);
      let sousPack = this.mrhForm.get('packForm').get('sub_package').value;
      this.subPackag = this.pack.sous_packs.find((sp) => sp.code == sousPack);
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
  }

  next() {
      window.scrollTo(500, 500);
  }

  setAssure(n) {
    let user = this.userNew.infos;

    if (n === '1') {
      this.mrhForm.get('isSubscriber').setValue(true);
      this.currentSouscripteur = "Pour moi-même";
      this.mrhForm.get('assure').get('nom').setValue(user.lastName);
      this.mrhForm.get('assure').get('prenom').setValue(user.firstName);
      this.mrhForm.get('assure').get('email').setValue(user.email);
      this.mrhForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.mrhForm.get('assure').get('adresse').setValue(user.adresse);
      this.mrhForm.get('assure').get('profession').setValue(user.profession);
      this.mrhForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
    }
    else if(n === '2') {
      this.mrhForm.get('isSubscriber').setValue(false);
      this.currentSouscripteur = "Pour un tiers";
      this.mrhForm.get('assure').get('nom').setValue('');
      this.mrhForm.get('assure').get('prenom').setValue('');
      this.mrhForm.get('assure').get('email').setValue('');
      this.mrhForm.get('assure').get('numeroTelephone').setValue('');
      this.mrhForm.get('assure').get('adresse').setValue('');
      this.mrhForm.get('assure').get('profession').setValue('');
      this.mrhForm.get('assure').get('secteuractivite').setValue('');
    }
    else if(n === '3') {
      this.mrhForm.get('isSubscriber').setValue(false);
      // this.currentSouscripteur = "Pour un tiers";
      this.mrhForm.get('assure').get('nom').setValue('');
      this.mrhForm.get('assure').get('prenom').setValue('');
      this.mrhForm.get('assure').get('email').setValue(user.email);
      this.mrhForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.mrhForm.get('assure').get('adresse').setValue(user.adresse);
      this.mrhForm.get('assure').get('profession').setValue(user.profession);
      this.mrhForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
    }
  }

//   setAssure(n) {
//       let user = this.user.infos;
//       if (n == 1) {
//           this.mrhForm.get('isSubscriber').setValue(true);
//           this.currentSouscripteur = "Pour moi-même";
//           this.mrhForm.get('assure').get('nom').setValue(user.lastName);
//           this.mrhForm.get('assure').get('prenom').setValue(user.firstName);
//           this.mrhForm.get('assure').get('email').setValue(user.email);
//           this.mrhForm.get('assure').get('numeroTelephone').setValue(user.telephone);
//           this.mrhForm.get('assure').get('adresse').setValue(user.adresse);
//           this.mrhForm.get('assure').get('profession').setValue(user.profession);
//           this.mrhForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
//           this.mrhForm.get('assure').get('genre').setValue(user.genre);
//       } else {
//           this.mrhForm.get('isSubscriber').setValue(false);
//           this.currentSouscripteur = "Pour un tiers";
//           this.mrhForm.get('assure').get('nom').setValue('');
//           this.mrhForm.get('assure').get('prenom').setValue('');
//           this.mrhForm.get('assure').get('email').setValue('');
//           this.mrhForm.get('assure').get('numeroTelephone').setValue('');
//       }
//   }

  saveData() {
      let data = {
          ...this.mrhForm.get('packForm').value,
          ...this.vehiculeForm.value,
          ...this.contractForm.value,
          ...this.personForm.value,
          ...this.result,
          accessoires: 6000,
          prime_nette: this.result.prime_auto,
          prime_ttc: this.result.prime_total,
          garanties: this.garantie
      };
      this.sendingRequest = true;
      this.subscriptionService.getContract(data).subscribe((resp) => {
          if (resp['code'] == 200) {
              this.utilsService.showToastSuccess("Souscription effectuée avec succès", "Devis généré");
              window.open(resp['data'].url, '_blank');
              this.mrhForm.reset();
          } else {
              this.utilsService.showToastError(resp['message'], "Erreur souscription");
          }
          this.sendingRequest = false;
      }, error => {
          let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
          if (error && error.error) {
              message = error.error.errors[0].message;
          }
          this.utilsService.showToastError(message, "Echec");
          this.sendingRequest = false;
      });
  }

  get qualite_souscripteur() {
      return this.mrhForm.get('packForm').get('qualite_souscripteur');
  }

  triArray(data, type) {
      data.sort(function (a, b) {
          return a[type] - b[type];
      });
  }

  devis() {
      // if (this.garantie.length > 0) {
      this.resultass = [];
      var garantieCheck = this.garantie
      this.sendingRequest = true;
      if (this.garantie.length <= 0) {
          garantieCheck = null
      }
      let data = {
          "surface": this.mrhForm.get('packForm').get('surface').value,
          "nombrePiece": this.mrhForm.get('packForm').get('pieces').value,
          "valeur": this.mrhForm.get('packForm').get('valeur_batiment').value,
          "capitalContenu": this.mrhForm.get('packForm').get('valeur_contenu').value,
          "garanties": garantieCheck
      }
      this.subscriptionService.getassureurs(data).subscribe((resp) => {
          this.sendingRequest = false;
          if (resp) {
              let valResultass = resp['assureurs'];
              for (let resul of valResultass) {
                  let data = {
                      "surface": parseInt(this.mrhForm.get('packForm1').get('surface').value),
                      "nombrePiece": parseInt(this.mrhForm.get('packForm1').get('pieces').value),
                      "valeur": parseInt(this.mrhForm.get('packForm1').get('valeur_batiment').value),
                      // @ts-ignore
                      "capitalContenu": parseInt(this.mrhForm.get('packForm1').get('valeur_contenu').value),
                      "garanties": this.garantie,
                      "assureur": resul.id,
                  }
                  if (resul.code == 'NSIA') resul['img'] = "../../../../../assets/images/nsia.png";
                  else if (resul.code == 'AXA') resul['img'] = "../../../../../assets/images/axa.png";
                  else if (resul.code == 'AMSA') resul['img'] = "../../../../../assets/images/amsa.png";
                  else if (resul.code == 'SALAMA') resul['img'] = "../../../../../assets/images/salama.png";
                      // else if(resul.code == 'SALAMA') resul['img']="../../../../../assets/images/salama.png";
                  // else if(resul.code == 'SALAMA') resul['img']="../../../../../assets/images/salama.png";
                  else resul['img'] = "../../../../../assets/images/assurance.jpg";
                  this.subscriptionService.getprice(data).subscribe((resp: any) => {

                      let prix = 0;
                      let base = 0;
                      if (resp['assureur']) {
                          let detail = resp['assureur'];
                          if (detail.garanties) {
                              detail.garanties.forEach(element => {
                                  prix = prix + Number(element.primeTtc)
                              });
                              base = detail.parametrageMrh.prix;
                              prix = prix + base;
                              resul['prix'] = prix;
                          } else {
                              base = detail.parametrageMrh.prix;
                              prix = detail.parametrageMrh.prix;
                              resul['prix'] = prix;
                          }
                      } else resul['prix'] = 0;
                      this.resultass.push(resul);
                      this.triArray(this.resultass, 'prix');
                  }, error => {
                  });
              }
              this.wizard.goToNextStep();
              window.scrollTo(500, 500);

          } else {
              this.utilsService.showToastError(resp['message'], "Erreur souscription");
          }
          this.sendingRequest = false;
      }, error => {
          let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
          if (error && error.error) {
              message = error.error.errors[0].message;

              this.utilsService.showToastError(message, "Echec");
          }
          this.sendingRequest = false;
      })
  }

  goTo(step) {
      window.scroll(500, 500);
      this.wizard.goToStep(step);
  }

  retour() {
      this.login = true;
      window.scroll(500, 500);
  }

  enterStep(event) {
      for (let val of this.resultass) {
          if (val.code == 'NSIA') val['img'] = "../../../../../assets/images/nsia.png";
          else if (val.code == 'AXA') val['img'] = "../../../../../assets/images/axa.png";
          else if (val.code == 'AMSA') val['img'] = "../../../../../assets/images/amsa.png";
          else if (val.code == 'SALAMA') val['img'] = "../../../../../assets/images/salama.png";
              // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
          // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
          else val['img'] = "../../../../../assets/images/assurance.jpg";
      }

  }

  getprix(id_ass, ass) {
      this.devisend = true;
      let data;
      if (this.datas) {
          let garanties;
          if(this.detailsPdf.garantieMrh){
              garanties = this.detailsPdf.garantieMrh.map((el) => {
                   return el.type.id;
              });
          }
          let surface = Number(this.mrhForm.get('packForm1').get('surface').value);
          data = {
              "surface": surface,
              "nombrePiece": parseInt(this.mrhForm.get('packForm1').get('pieces').value),
              "valeur":     parseInt(this.mrhForm.get('packForm1').get('valeur_batiment').value),
              // @ts-ignore
              "capitalContenu": parseInt(this.mrhForm.get('packForm1').get('valeur_contenu').value),
              // @ts-ignore
              "garanties": this.garantie,
              "assureur": this.detailsPdf.assureur.id,
          }

          this.assureur = this.detailsPdf.assureur.id;
          this.currentAssureur = this.detailsPdf.assureur;
          this.getImageAssureur()
          let infos = {
              matricule: this.detailsPdf.souscripteur.matricule,
              firstName: this.detailsPdf.souscripteur.firstName,
              lastName: this.detailsPdf.souscripteur.lastName,
              email: this.detailsPdf.souscripteur.email,
              telephone: this.detailsPdf.souscripteur.telephone,
              secteuractivite: this.detailsPdf.souscripteur.secteuractivite,
              profession: this.detailsPdf.souscripteur.profession,
              adresse: this.detailsPdf.souscripteur.adresse
          };
          this.user = [];
          this.user.infos = infos;
      } else {
          data = {
              "surface": parseInt(this.mrhForm.get('packForm1').get('surface').value),
              "nombrePiece": parseInt(this.mrhForm.get('packForm1').get('pieces').value),
              "valeur": parseInt(this.mrhForm.get('packForm1').get('valeur_batiment').value),
              // @ts-ignore
              "capitalContenu": parseInt(this.mrhForm.get('packForm1').get('valeur_contenu').value),
              // @ts-ignore
              "garanties": this.garantie != [] ? this.garantie : 'null',
              "assureur": id_ass,
          }
          this.assureur = id_ass;
          this.currentAssureur = ass;

      }
      if(this.garantie.length != 0){
      this.subscriptionService.getprice(data).subscribe((resp) => {
          // this.sendingRequest = true;
          this.devisend = false;
          this.resultmrh = resp['assureur'];
          this.getdevis(this.resultmrh);
          //  this.wizard.goToNextStep();
          // this.sendingRequest = false;
          this.devisend = false;
      }, error => {
          let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
          if (error && error.error) {
              message = error.error.errors[0].message;

              this.utilsService.showToastError(message, "Echec");
              this.devisend = false;
          }
          this.devisend = false;
      });
    }
      if (this.datas) {
          //this.renderer.invokeElementMethod(this.contentResultass.elementRef.nativeElement, 'querySelector', ['#btnDownload']);
          //this.captureScreen();
      }
  }

  checkSouscripteur() {
      if (this.mrhForm.get('packForm1').get('qualite_souscripteur').value == 'locataire') {
          this.setValidatorsMethode(this.mrhForm.get('packForm1'), ['loyer_mensuel']);
          this.clearValidatorsMethode(this.mrhForm.get('packForm1'), ['valeur_batiment']);
      } else {
          this.setValidatorsMethode(this.mrhForm.get('packForm1'), ['valeur_batiment']);
          this.clearValidatorsMethode(this.mrhForm.get('packForm1'), ['loyer_mensuel']);
      }
  }
  checkNatureLogement(event){
      if (event.target.value === 'appartement') {
          this.natureLogement = false;
          this.setValidatorsMethode(this.mrhForm.get('packForm1'), ['numeroEtage']);
          this.clearValidatorsMethode(this.mrhForm.get('packForm1'), ['niveauImmeuble']);
      } else {
          this.natureLogement = true;
          this.setValidatorsMethode(this.mrhForm.get('packForm1'), ['niveauImmeuble']);
          this.clearValidatorsMethode(this.mrhForm.get('packForm1'), ['numeroEtage']);
      }
  }

  setValidatorsMethode(form, tab) {
      for (const key in form.controls) {
          if (tab.indexOf(key) != -1) {
              form.get(key).setValidators(Validators.required);
              form.get(key).updateValueAndValidity();
              form.get(key).setValue('');
          }
      }
  }

  clearValidatorsMethode(form, tab) {
      for (const key in form.controls) {
          if (tab.indexOf(key) != -1) {
              form.get(key).clearValidators();
              form.get(key).updateValueAndValidity();
              form.get(key).setValue('');
          }
      }
  }

  getdevis(devis) {
      this.prix = 0;
      if (devis.garanties) {
          devis.garanties.forEach(element => {
              this.prix = this.prix + Number(element.primeTtc)
          });
          this.base = devis.parametrageMrh.prix;

          this.prix = this.prix + this.base;
      } else {
          this.base = devis.parametrageMrh.prix;

          this.prix = devis.parametrageMrh.prix;
      }
      this.valeur = Number(this.mrhForm.get('packForm1').get('valeur_batiment').value);
      // this.prix=Number(devis.prix)
      this.capital = Number(this.mrhForm.get('packForm1').get('valeur_contenu').value);
      this.granddevis = devis;

      this.mrhForm.get('assureur').setValue(devis.id);
      this.modalService.open(this.contentResultass, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'lg'
      }).result.then((result) => {
          if (result == 'subscribe') {
              this.wizard.goToNextStep();
              window.scrollTo(500, 500);
              let user = this.user.infos;
              this.currentSouscripteur = "Pour moi-même";
              this.mrhForm.get('assure').get('nom').setValue(user.lastName);
              this.mrhForm.get('assure').get('prenom').setValue(user.firstName);
              this.mrhForm.get('assure').get('email').setValue(user.email);
              this.mrhForm.get('assure').get('numeroTelephone').setValue(user.telephone);
              this.mrhForm.get('assure').get('adresse').setValue(user.adresse);
              this.mrhForm.get('assure').get('profession').setValue(user.profession);
              this.mrhForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
              this.mrhForm.get('assure').get('genre').setValue(user.genre);
          }
      }, (reason) => {
      });
      if (this.datas) {
          if (this.modalService.hasOpenModals()) {
              setTimeout(() => {
                  this.captureScreen();
              }, 70000);
          }
      }

  }

  devispack(sp) {
      let data = {
          "surface": this.mrhForm.get('packForm').get('surface').value,
          "nombrePiece": this.mrhForm.get('packForm').get('pieces').value,
          "valeur": this.mrhForm.get('packForm').get('valeur_batiment').value,
          "capitalContenu": this.mrhForm.get('packForm').get('valeur_contenu').value,
          // "garanties":sp.typeGaranti.id
      }
      this.packmrh = sp.libelle;
      this.sendingRequest = true;
  }

  get vehiculeForm() {
      return this.mrhForm.get('vehiculeForm');
  }

  get contractForm() {
      return this.mrhForm.get('contractForm');
  }

  get personForm() {
      return this.mrhForm.get('personForm');
  }

  proprietaire() {
      this.mrhForm.get('packForm').get('qualite_souscripteur').setValue("proprietaire");

      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
  }

  locataire() {
      this.mrhForm.get('packForm').get('qualite_souscripteur').setValue("locataire");
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
  }

  onDateSelect(event, groupName, controlName) {

      if (event) {
          let data = (event.year + '/' + event.month + '/' + event.day);
          this.mrhForm.get('' + groupName).get('' + controlName).setValue(new Date(data));
          if (controlName == "effet") {
              this.Datemin = new Date(data);
              let dt = moment(this.mrhForm.get('packForm1').get('effet').value).subtract(1,'d');
              this.dateExpiration = moment(dt).add(1, 'years');
          }
      }
  }

  onDateSelect1(event, groupName, controlName) {
      if (event) {
          let dah = (event.year + '/' + event.month + '/' + event.day);
          if (controlName == "echeance") {
              this.Datemax = new Date(dah);
              if (this.Datemax > this.Datemin) {
                  this.mrhForm.get('' + groupName).get('' + controlName).setValue(new Date(dah));
                  this.datevalid = false;
              } else {
                  this.datevalid = true
              }
          }
      }


  }

  onDateSelect3(event, groupName, controlName) {
      if (event)
          this.mrhForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));

  }

  assuree() {
      if (this.mrhForm.get('assure').get('dateNaissance').invalid || this.mrhForm.get('assure').get('numeroTelephone').invalid || this.mrhForm.get('assure').get('prenom').invalid || this.mrhForm.get('assure').get('nom').invalid || this.mrhForm.get('assure').get('numeroCni').invalid || this.mrhForm.get('assure').get('adresse').invalid || this.mrhForm.get('assure').get('genre').invalid || this.mrhForm.get('assure').get('email').invalid) {
          this.invalid = true
          //  this.wizard.goToNextStep();
      } else {
        // @ts-ignore
          this.mrhForm.get('souscripteur').patchValue(this.mrhForm.get('assure').value);
          this.mrhForm.get('souscripteur').get('dateNaissance').setValue(this.mrhForm.get('assure').get('dateNaissance').value);
          this.mrhForm.get('souscripteur').get('telephone').setValue(this.mrhForm.get('assure').get('numeroTelephone').value);
          // this.saveDatamrh();
          this.wizard.goToNextStep();
          window.scrollTo(500, 500);
          this.invalid = false;
      }

  }

  selectPayment(pay) {
      this.mrhForm.get('moyenDePaiement').setValue(pay.code == this.mrhForm.get('moyenDePaiement').value ? "" : pay.code);
      this.paymentMethod = pay.nom.toLowerCase();
  }

  saveDatamrh() {
      this.paymentForm.amount = this.prix;
      let data = {
          ...this.mrhForm.get('packForm1').value,
          ...this.vehiculeForm.value,
          ...this.contractForm.value,
          ...this.personForm.value,
          ...this.result,
          accessoires: 6000,
          prime_nette: this.base,
          prime_ttc: this.prix,
      };

      let databis = {
          ...this.mrhForm.get('assure').value,
          ...this.mrhForm.get('souscripteur').value,
          ...this.mrhForm.get('packForm1').value,
          "pack": 'mrh',
          "assure": this.mrhForm.get('assure').value,
          "souscripteur": this.mrhForm.get('souscripteur').value,
          // "assureur": this.result.assureur.id,
          "assureur": this.assureur,
          "canal": 1,
          "moyenDePaiement": "paiement_agence",
          "prix": this.prix,
          "date_effet": this.mrhForm.get('packForm1').get('effet').value,
          "capitalGaranti": this.mrhForm.get('packForm1').get('valeur_contenu').value,
          "surface": this.mrhForm.get('packForm1').get('surface').value,
          "nombrePiece": this.mrhForm.get('packForm1').get('pieces').value,
          "garanties": this.garantie,
          "pointRelais": this.mrhForm.get('pointRelaisControl').value,
          "adresseLivraison": this.mrhForm.get('adresseLivraison').value,
      }

      this.sendingRequest = true;
      this.subscriptionService.saveSouscription(databis).subscribe((resp) => {
          this.sendingRequest = true;
          if (resp['responseCode'] == 200) {
              let type = '&meansType=CARTE_OTHER';
              this.initPaymentForm.montant = this.paymentForm.amount;
              this.initPaymentForm.requestId = this.dateTime;
              this.initPaymentForm.numeroSouscription = resp['data'].numeroSouscription;
              this.initPaymentForm.status = "PENDING"
              this.subscriptionService.initPaiementBack(this.initPaymentForm).subscribe((rep) => {
                  if (rep['responseCode'] != 200) {
                      this.utilsService.showToastError(resp['message'], "Echec");
                  }
                  this.sendingRequest = false;

              }, error => {
                  this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
                  this.sendingRequest = false;
              });
              this.uuId = resp['data'].id;
              let email = this.user.infos.email;
              this.message ="Bonjour Madame/Monsieur " + " , je vous prie de trouver ci-joint le lien de votre devis de votre souscription "+
                  " " +environment.frontWebUrl+"generer-pdf/"+this.uuId;
              let detailsEnvoi = {
                  'email': email,
                  'message': this.message
              }
              this.subscriptionService.sendMail(detailsEnvoi).subscribe((resp) => {
                  this.sendingRequest = true;
                  if (resp['responseCode'] == 200) {

                  }
                  this.sendingRequest = false;
              });
              if (this.paymentMethod == 'wallet') type = '&meansType=WALLET';
              let hash = this.paymentForm.timestamp + '' + this.paymentForm.amount + '' + this.paymentForm.timestamp + 'XOF' + this.key;
              this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);
              this.paymentForm.customerFirstName = this.mrhForm.get('souscripteur').get('prenom').value;
              this.paymentForm.customerLastName = this.mrhForm.get('souscripteur').get('nom').value;
              this.paymentForm.customerPhone = this.mrhForm.get('souscripteur').get('telephone').value;
              this.paymentForm.customerCountry = 'SN';
              this.paymentForm.customerCity = 'DAKAR';
              this.paymentForm.customerState = 'DAKAR';
              this.paymentForm.customerZipCode = '25455';
              this.paymentForm.customerAddress = 'DAKAR';
              this.paymentForm.customerEmail = this.mrhForm.get('souscripteur').get('email').value;
              this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {
                  if (respApg['code'] == '0000') {
                      this.utilsService.showToastSuccess("Votre souscription a été enregistrée avec succès. Vous allez être redirigé vers la page de paiement", "Souscription réussie")
                      // setTimeout(() => {
                      // alert("apg")
                      this.sendingRequest = false;
                      window.open(respApg['paymentUrl'] + type, '_blank');
                      this.wizard.goToStep(1);
                      // }, 3000);
                      // this.checkSubscription(this.initPaymentForm.numeroSouscription);
                  } else {
                      this.sendingRequest = false;
                      this.utilsService.showToastError(respApg['message'], "Echec");
                      // this.mrhForm.reset;
                      this.wizard.goToStep(1);
                      window.scroll(0, 0);
                  }
              }, error => {
                  this.sendingRequest = false;
                  this.utilsService.showToastError("Impossible de contacter le serveur", "Echec");

                  this.wizard.goToStep(1);
                  window.scroll(0, 0);
              });
          } else {
              this.sendingRequest = false;

              this.utilsService.showToastError(resp['message'], resp['message']);
              // this.mrhForm.reset;
              this.wizard.goToStep(1);
              window.scroll(0, 0);
          }
      }), (error) => {
          this.sendingRequest = false;
          window.scroll(0, 0);
          this.utilsService.showToastError(error.error.errors[0].message, "Echec")
      }
  }

  getAgences() {
      this.subscriptionService.getListAgences().subscribe((resp) => {
          this.listAgences = resp['data'];
      });
  }

  mail() {
      let user = this.user.infos;
      this.personcontactForm.get('last_name').setValue(user.lastName);
      this.personcontactForm.get('first_name').setValue(user.firstName);
      this.personcontactForm.get('email').setValue(user.email);
      this.personcontactForm.get('telephone').setValue(user.telephone);
      this.modalService.open(this.contentResultmail, {
          ariaLabelledBy: 'modal-basic-title',
          size: <any>'lg'
      }).result.then((result) => {
          if (result == 'subscribe') {
              this.utilsService.showToastSuccess("Votre demande de rappel à été enregistré avec success. Vous allez recvoir un mail de confirmation", "Demande réussie")
          }
          // this.wizard.goToNextStep();
      }, (reason) => {
      });
  }

  saveContact() {
      if (this.personcontactForm.invalid) {
          this.utilsService.showToastError("Veuillez remplir tous les champs obligatoire", "Attention !!!");
      } else {
          this.loadContact = true;
          let value = this.personcontactForm.value;
          // @ts-ignore
          value['jour'] = value['jour'].join();
          // @ts-ignore
          value['heure'] = value['heure'].join();
          this.subscriptionService.saveContact(value).subscribe(resp => {
              if (resp['responseCode'] == 200) {
                  this.closeBTN.nativeElement.click();
                  this.loadContact = false;
                  this.utilsService.showToastError("Votre demande a été enregistrer avec succès", "Demande réussi")
              } else {
                  this.loadContact = false;
                  this.utilsService.showToastError(resp['message'], "Erreur")
              }
          }, error => {
              this.loadContact = false;
              this.utilsService.showToastError(error.error['errors'][0]['message'], "Erreur")
          });
      }
  }

  checkSubscription(numberSouscription) {
      const source = interval(5000)
      const check = source.subscribe(x => {
          fetch(environment.baseApiUrl + 'souscriptions/check-subscription?numeroSouscription=' + numberSouscription)
              .then(response => {
                  response.json()
                      .then(data => {
                          if (data['responseCode'] == 200) {
                              this.routerr.navigateByUrl("success-payment");
                              check.unsubscribe();
                          }
                      });
              });
      });
  }

  getPaymentMethods() {
      this.subscriptionService.getPaymentMethods().subscribe((resp) => {
          this.paymentMethods = resp['data'];
          this.route.queryParams.subscribe(params => {
            this.donnee = params.donnee;

            if(this.donnee){
             this.souscrire();
            }
          });
          for (let pay of this.paymentMethods) {
              if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
              else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet.jfif";
              else if (pay.code == 'OM') pay['logo'] = "assets/images/om.jpg";
              else if (pay.code == 'FM') pay['logo'] = "assets/images/fm.png";
              else if (pay.code == 'WM') pay['logo'] = "assets/images/wm.jpg";
              else if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
              else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet2.png";
          }
          this.tmpPaymentMethods = this.paymentMethods;
          this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
          this.mrhForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
      });

  }

  onChangeCategory(event, id, gar) {
       if(event.isTrusted && !this.garantie.includes(id)) {
          this.garantie.push(id);
          this.currentGarantie = gar.libelle;
      } else {
          this.garantie.forEach((element, index) => {
              if (element == id) {
                  this.garantie.splice(index, 1);
              } else {
              }
          });
      }
  }

  openPDF(): void {
      let data = document.getElementById('mrhDownload');

      // html2canvas(data).then(canvas => {
      //   const contentDataURL = canvas.toDataURL('image/png')
      //   // let pdf =  new jsPDF('l', 'cm', 'a4'); //Generates PDF in landscape mode
      //   var imgWidth = 210;
      //   var pageHeight = 380;
      //   var imgHeight = canvas.height * imgWidth / canvas.width;
      //   var heightLeft = imgHeight;
      //   let pdf = new jsPDF('p', 'cm', 'a4');
      //   // Generates PDF in portrait mode
      //   pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      //   pdf.save('Filename.pdf');
      // });
      // let doc= new jsPDF('p', 'mm', 'a4');
      // doc.html(data, {
      //    callback: (doc) => {
      //      doc.setFontSize(12);
      //      doc.setDisplayMode("original");
      //      doc.save("devis.pdf");
      //    }
      // });

      // html2canvas(data).then(canvas => {
      //   // Few necessary setting options
      //   document.body.style.zoom = "10%";
      //   var imgWidth = 210;
      //   var pageHeight = 380;
      //   var imgHeight = canvas.height * imgWidth / canvas.width;
      //   var heightLeft = imgHeight;
      //   const contentDataURL = canvas.toDataURL('image/png', 1.0);
      //   const contentDataURL1 = canvas.toDataURL('image/png', 1.0);
      //   // let l={
      //   //   orientation: 'p',
      //   //                    unit: 'pt',
      //   //                    format: 'a4',
      //   //                    compress: true,
      //   //                    fontSize: 8,
      //   //                    lineHeight: 1,

      //   //                    printHeaders: true
      //   // }
      //   let pdf = new jsPDF('p', 'mm', 'a4',true,false); // A4 size page of PDF
      //   var position = 0;
      //   // pdf.addPage();
      //   pdf.addImage(contentDataURL,'PNG', 0,-150, imgWidth, imgHeight);
      //   pdf.addPage();
      //   pdf.addImage(contentDataURL1,'PNG', 0, -267.7, imgWidth, imgHeight+39,'SLOW');
      //   pdf.save('DetailsDevis.pdf'); // Generated PDF
      //   document.body.style.zoom = "100%";
      // });
      // html2canvas(data).then(canvas => {
      //   // this.canvas.nativeElement.src = canvas.toDataURL();
      //    let dah=canvas.toDataURL('image/png');
      //     let doc= new jsPDF('p', 'mm', 'a4',false,true);
      //     doc.html(dah, {
      //       x:0,
      //       y:0,
      //       callback: (doc) => {
      //         doc.save("devis.pdf");
      //       },

      //     });
      // });

      var pdf = new jsPDF();
      // @ts-ignore
      document.body.style.zoom = "70%";
      var options = {
          pagesplit: false,
          x: 0,
          y: 370,
          quality: 4,
          scale: 2,
      };
      pdf.setFont('Calibri');
      pdf.text(50, 50, 'Now this is Calibri');
      pdf.addHTML(data, options, function () {
              pdf.save("Devis_MRH.pdf");
          }
      );
      // @ts-ignore
      document.body.style.zoom = "100%";

      //   html2canvas(data).then(canvas => {
      //   var imgWidth = 210;
      //   var pageHeight = 295;
      //   var imgHeight = canvas.height * imgWidth / canvas.width ;
      //   var heightLeft = imgHeight;
      //   const contentDataURL = canvas.toDataURL('image/png', 1.0);
      //   var doc = new jsPDF('p', 'mm');
      //   var position = 0;

      //   doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      //   heightLeft -= pageHeight;

      //   while (heightLeft >= 0) {
      //     position = heightLeft - imgHeight;
      //     doc.addPage();
      //     doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      //     heightLeft -= pageHeight;
      //   }
      //   doc.save( 'file.pdf');
      // });
  }

  captureScreen(): void {
    var data = document.getElementById('mrhDownload');
    html2canvas(data, { backgroundColor: 'white', removeContainer: true, scale:1, scrollX: 0, scrollY: 0 }).then(canvas => {
      var imgWidth = 210;
      var pageHeight = 470;
      const contentDataURL = canvas.toDataURL('application/pdf')
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm', 'a4');

      var position = 0;

      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('Devis_MRH.pdf');
      if (this.datas) {
        this.modalService.dismissAll();
      }
    });
 }

//   captureScreen(): void {
//       const data = document.getElementById('mrhDownload');
//       html2canvas(data, { backgroundColor: 'white', removeContainer: true, scale:1, scrollX: 0, scrollY: 0 }).then(canvas => {
//         var imgWidth = 210;
//         var pageHeight = 470;
//           const contentDataURL = canvas.toDataURL('application/pdf')
//           var imgHeight = canvas.height * imgWidth / canvas.width;
//           var heightLeft = imgHeight;
//           let doc = new jsPDF('p', 'mm', 'a4');
//           var position = 0;
//           doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
//           heightLeft -= pageHeight;
//           while (heightLeft >= 0) {
//               position = heightLeft - imgHeight;
//               doc.addPage();
//               doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
//               heightLeft -= pageHeight;
//           }
//           doc.save('Devis_MRH.pdf');
//           if (this.datas) {
//               this.modalService.dismissAll();
//           }
//       });
//   }

  openPDFPwd(): void {
      let DATA = document.getElementById('mrhDownload');
      html2canvas(DATA, {scrollX: 0, scrollY: 0}).then(canvas => {
          var pdf = new jsPDF("l", "mm", "a4");
          var imgdata = canvas.toDataURL('image/jpeg');
          // due lack of documentation; try setting w/h based on unit
          pdf.addImage(imgdata, 'jpeg', 0, 0, 180, 150);
          // 180x150 mm @ (10,10)mm
          pdf.save('Devis_MRH.pdf');
      });
  }


  ngAfterViewInit(): void {

  }

  getImageAssureur() {
      if (this.currentAssureur['code'] == 'NSIA') this.currentAssureur['img'] = "../../../../../assets/images/nsia.png";
      else if (this.currentAssureur['code'] == 'AXA') this.currentAssureur['img'] = "../../../../../assets/images/axa.png";
      else if (this.currentAssureur['code'] == 'AMSA') this.currentAssureur['img'] = "../../../../../assets/images/amsa.png";
      else if (this.currentAssureur['code'] == 'SALAMA') this.currentAssureur['img'] = "../../../../../assets/images/salama.png";
          // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
      // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
      else this.currentAssureur['img'] = "../../../../../assets/images/assurance.jpg";
  }
  testselect(event){
  }

  //open modal
  openModal(modal){
      this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
          if (result == 'subscribe') {

          }
        }, (reason) => {
        });
  }
  updateConjoint(formControl: string){
      this.modalService.dismissAll(this.modalAssure);
  }

  devisParMail(type) {

      this.assureurId = type.id;
      this.sendingRequestAssureur = true;
      this.updateRequest = true;
      let val = Number(this.mrhForm.get('packForm1').get('valeur_batiment').value);
      // let contenu = Number(this.mrhForm.get('packForm1').get('valeur_contenu').value);
      let piece = Number(this.mrhForm.get('packForm1').get('pieces').value);
      let body ={
          "capitalContenu" : this.mrhForm.get('packForm1').get('valeur_contenu').value,
          "valeur" : val,
          "assureur": type.id,
          "nombrePiece" : piece,
          "garanties": this.garantie,
       }

      // Recupération du prix
      this.subscriptionService.getprice(body).subscribe((resp:any) => {
        if (resp) {

          //   if (resp.garanties) {
          //     resp.garanties.forEach(element => {
          //         this.prix = this.prix + Number(element.primeTtc)
          //     });
          //     this.base = resp.parametrageMrh.prix;
          //     this.prix = this.prix + this.base;
          // } else {
              this.prix = resp.assureur.parametrageMrh.prix;
          // }



          let data = {
            ...this.mrhForm.value,
            "assureur": type.id,
            "type": "mail",
            "prix": this.prix
          };

          // Envoi devis avec prix
          this.subscriptionService.envoiDevisMail(data).subscribe((resp) => {
            if (resp['responseCode'] = 200) {
              this.currentId = resp['data'].id;
              this.utilsService.showToastSuccess(resp['message'], "Devis envoyé avec succés");

              this.sendingRequestAssureur = false;
            } else {
              this.sendingRequestAssureur = false;
            }
          }, error => {
            this.sendingRequestAssureur = false;
            this.utilsService.showToastError(resp['message'], "erreur sur le server");
          })


        } else {
          this.utilsService.showToastError(resp['message'], "Données invalides");
        }
        this.sendingRequest = false;
      });
    }
    devisParWatshap(type) {
      this.assureurId = type.id;
      this.sendingRequestAssureur = true;
      this.updateRequest = true;
      let body = {
        ...this.mrhForm.value,
        "assureur": type.id
      };

      // Recuperation du prix
      this.subscriptionService.simulatePrime(body).subscribe((resp:any) => {
        if (resp) {
          let prix = resp.find(el=>{
           return el.key =='Prime Totale TTC'
          });

          let data = {
            ...this.mrhForm.value,
            "assureur": type.id,
            "type": "watshap",
            "prix": prix.value
          };

          // Envoi devis avec prix
          this.subscriptionService.envoiDevisMail(data).subscribe((resp) => {
            if (resp['responseCode'] = 200) {
              this.currentId = resp['data'].id;
              this.utilsService.showToastSuccess(resp['message'], "Devis envoyé avec succés");

              this.sendingRequestAssureur = false;
            } else {
              this.sendingRequestAssureur = false;
            }
          }, error => {
            this.sendingRequestAssureur = false;
            this.utilsService.showToastError(resp['message'], "erreur sur le server");
          })


        } else {
          this.utilsService.showToastError(resp['message'], "Données invalides");
        }
        this.sendingRequest = false;
      });
    }

    // Modal pour devis etre envoye
openModalEnvoyeDevis(type){
  this.assureurAEnvoye = type;
  this.modalService.open(this.devisAEnvoye, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
    if (result == 'subscribe') {
    }
  }, (reason) => {
  });
}
etreRappele() {
  this.sendingRequestAssureur = true;
  this.updateRequest = true;
  this.etrerappeleForm.get('code').setValue(this.assureurAEnvoye.code);
  if(this.etrerappeleForm.valid){
  this.subscriptionService.etreRappele(this.etrerappeleForm.value).subscribe((resp) => {
    if (resp['responseCode'] = 200) {
      this.utilsService.showToastSuccess("Votre demande de rappel a été enregistré avec succès", "Demande de rappel")
      this.sendingRequestAssureur = false;
      this.updateRequest = false
      this.modalService.dismissAll(this.devisAEnvoye);
    } else {
      this.updateRequest = false
      this.sendingRequestAssureur = false;
    }
  }, error => {
    this.updateRequest = false
    this.sendingRequestAssureur = false;
    this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
  });
}
}

//start rappeler
rappeler(){
    this.modalService.open(this.etreRappeller, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }

  checkDisponiblite(){
    let val = this.rappellerForm.get('disponibilite').value;
    if(val != ''){
      if(val=='Matin'){
        this.heureDisponibiliteListTemoin = this.heureDisponibiliteList.filter(el => el.filtre == 'Matin')
      }
      else{
        this.heureDisponibiliteListTemoin = this.heureDisponibiliteList.filter(el => el.filtre == 'Soir')
      }
    }
 }


 meRappeller() {
    this.sendingRequestRappel = true;
   if(this.rappellerForm.get('disponibilite').value=='Toute la journee'){
    this.rappellerForm.get('heureDisponibilite').setValue("Toute la journee")
   }
    if(this.rappellerForm.valid){
      this.subscriptionService.meRappeller(this.rappellerForm.value).subscribe((resp) => {
        if (resp['responseCode'] = 200) {
          this.utilsService.showToastSuccess("Votre demande de rappel a été enregistré avec succès", "Demande de rappel")
          this.sendingRequestRappel = false;
          this.modalService.dismissAll(this.etreRappeller);
          this.rappellerForm.reset();
        } else {
          this.sendingRequestRappel = false;
        }
      }, error => {
        this.sendingRequestRappel = false;
        this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
      });
    }
  }


//end rappeler



}
