import { Component, ElementRef, isDevMode, OnInit, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { DatePipe, Location } from "@angular/common";
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/service/utils.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { IAttachment } from './attachement.model';
import { WizardComponent } from 'angular-archwizard';
// import * as moment from 'moment';
import * as jsPDF from 'jspdf'
import { environment } from 'src/environments/environment';
import { from, interval } from 'rxjs';
import { element } from 'protractor';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { animate, style, transition, trigger } from '@angular/animations';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AccountService } from 'src/app/shared/service/account.service';
import { info } from 'console';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { CONSTANTES } from 'src/app/shared/model/constantes';
import { APP } from 'src/app/shared/model/model';



@Component({
  selector: 'app-home-voyage',
  templateUrl: './home-voyage.component.html',
  styleUrls: ['./home-voyage.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2500)
      ]),
      transition('* => void', [
        animate(2500),
        style({ opacity: 0 }),
      ])
    ]),
    trigger("slideInOut", [
      transition('void => *', [
        style({ transform: "translateX(60%)", opacity: 0 }),
        animate(
          "1500ms ease-in",
          // style({ transform: "translateX(0%)", opacity: 1 })
        ),
      ]),
      transition('* => void', [
        animate(
          "1500ms ease-out",
          // style({ transform: "translateX(60%)", opacity: 0 })
        ),
      ]),
    ]),
  ]
})
export class VoyagesComponent implements OnInit  {

  formules = [];
  pwd = 'password';
  isSouscription: boolean = false;
  @Input() attachments: IAttachment[] = [];
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  invalid: boolean = false;
  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  durees = [];
  paymentMethod: string = "Paiement par agence";
  valeurVenal: number;
  selectsg: any;
  valeurNeuf: number;
  paymentMethods = [];
  desc: any[];
  sendingRequest: boolean = false;

  individuel: boolean = false;
  flotte: boolean = false;
  apiResponse: any = { title: '', text: '', type: '' };
  phoneMask: string = "00 00 000 000";
  cinMask: string = "0 000 0000 00000";
  dateTime = Math.floor(Date.now() / 1000);
  @Input() valeur: number;
  simulationVehiculeFlotteForm = new FormGroup({
    formulaire: new FormControl("", [Validators.required]),
  });
  personcontactForm = new FormGroup({
    first_name: new FormControl("", [Validators.required]),
    designation: new FormControl("mrh", [Validators.required]),
    last_name: new FormControl("", [Validators.required]),
    telephone: new FormControl("", [Validators.required]),
    email: new FormControl("", [, Validators.pattern(this.emailPattern)]),
    heure: new FormControl("", [Validators.required]),
    jour: new FormControl("", [Validators.required]),
  })
  listJours = [{ name: 'Tous les jours' }, { name: 'Lundi' }, { name: 'Mardi' }, { name: 'Mercredi' }, { name: 'Jeudi' }, { name: 'Vendredi' }];
  listHeures = [{ name: "08h-10h" }, { name: "10h-12h" }, { name: "12h-14h" }, { name: "14h-16h" }, { name: "16h-18h" }];
  simulationVoyageForm = new FormGroup({
    livraison: new FormControl('Domicile', [Validators.required]),
    moyenDePaiement: new FormControl("paiement_agence", [Validators.required]),
    assureur: new FormControl("", [Validators.required]),
    canal: new FormControl(1, [Validators.required]),
    pack: new FormControl("voyage"),
    agence: new FormControl(""),
    isSubscriber: new FormControl(true),
    paysDepart: new FormControl("1", [Validators.required]),
    paysArrive: new FormControl("", [Validators.required]),
    dateDepart: new FormControl("", [Validators.required]),
    dateRetour: new FormControl("", [Validators.required]),
    dateNaissance: new FormControl("", [Validators.required]),
    assure: new FormGroup({
      choix: new FormControl("", [Validators.required]),
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      secteuractivite: new FormControl("", [Validators.required]),
      numeroTelephone: new FormControl("", [Validators.required]),
      profession: new FormControl("", [Validators.required]),
      adresse: new FormControl("", [Validators.required]),
      // lateralite: new FormControl("", [Validators.required]),
      genre: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)])
    }),
    souscripteur: new FormGroup({
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      genre: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      dateNaissance: new FormControl(""),
      telephone: new FormControl("", [Validators.required]),
      numeroCni: new FormControl("")
    }),
    pointRelaisControl: new FormControl("", [Validators.required]),
    adresseLivraison: new FormControl("", [Validators.required]),

  });
  loginform1 = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });
  etrerappeleForm = new FormGroup({
    date: new FormControl("", [Validators.required]),
    heure: new FormControl([], [Validators.required]),
    code: new FormControl("", [Validators.required])
  });

  rappellerForm = new FormGroup({
    nom: new FormControl("", [Validators.required]),
    prenom: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    telephone: new FormControl("", [Validators.required]),
    disponibilite: new FormControl("", [Validators.required]),
    heureDisponibilite: new FormControl("", [Validators.required])
  });
  sendingRequestRappel: boolean = false;
   heureDisponibiliteList = [{ name: "08H-10H", value: "08H-10H",filtre:'Matin'},{ name: "10H-12H", value: "10H-12H",filtre:'Matin'},{ name: "12H-14H", value: "12H-14H",filtre:'Matin'},{ name: "15H-16H", value: "15H-16H",filtre:'Soir'},{ name: "16H-17H", value: "16H-17H",filtre:'Soir'},{ name: "17H-18H", value: "17H-18H",filtre:'Soir'}];
   heureDisponibiliteListTemoin = []
   disponibiteList = [{ name: "Matin", value: "Matin"},{ name: "Soir", value: "Soir"},{ name: "Toute la journée", value: "Toute la journee"}]

  typeVehicule = "";
  tabElementVehicule = [{ name: "Poids", value: "poids", type: "text" }, { name: "Type de carburant", value: "typeMoteur", type: "text" }, { name: "Puissance", value: "puissance", type: "text" }, { name: "Nombre de places", value: "nombrePlace", type: "text" }, { name: "Immatriculation", value: "immatriculation", type: "text" }, { name: "Marque", value: "marque", type: "text" }, { name: "Modele", value: "modele", type: "text" }, { name: "Date de mise en circulation", value: "date_mis_en_circulation", type: "date" }, { name: "Date d'effet du contrat", value: "date_effet", type: "date" }, { name: "Durée du contrat", value: "duree", type: "text" }];
  tabElementAssure = [{ name: "Prénom", value: "prenom", type: "text" }, { name: "Nom", value: "nom", type: "text" }, { name: "Date de naissance", value: "dateNaissance", type: "date" }, { name: "Numéro téléphone", value: "numeroTelephone", type: "text" }, { name: "Numéro CNI", value: "numeroCni", type: "text" }, { name: "Email", value: "email", type: "text" }, { name: "Adresse", value: "adresse", type: "text" }];
  dateRappel = [{ name: "Lundi", value: "lundi"},{ name: "Mardi", value: "mardi"}, { name: "Mercredi", value: "mercredi"}, { name: "Jeudi", value: "jeudi"}, { name: "Vendredi", value: "vendredi"}, { name: "Samedi", value: "samedi"}, { name: "Dimanche", value: "dimanche"}];
  heureRappel = [{ name: "08h:00", id: "08:00"}, { name: "09h:00", id: "09:00"}, { name: "10h:00", id: "10:00"}, { name: "11h:00", id: "11:00"}, { name: "12h:00", id: "12:00"}, { name: "13h:00", id: "13:00"}, { name: "14h:00", id: "14:00"}, { name: "15h:00", id: "15:00"}, { name: "16h:00", id: "16:00"}, { name: "17h:00", id: "17:00"}, { name: "18h:00", id: "18:00"}, { name: "19h:00", id: "19:00"}, { name: "20h:00", id: "20:00"}, { name: "21h:00", id: "21:00"}, { name: "22h:00", id: "22:00"}, { name: "23h:00", id: "23:00"}];
  listDurees: any = [];
  currentAssureur:any = "";
  currentDom = "activeLivraison";
  currentRel = "";
  vielle: boolean = false;
  plafonne: boolean = false;
  age: number
  tierce: boolean = true;
  listDesGaranties = [];
  sendingRequestAssureur: boolean = false;
  today: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  minDate: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  maxDateNaissance = moment();
  subVehiculeCategories = [];
  products: any;
  allFormula: any = [];
  devisType: string;
  login: boolean = false;
  vehiculeCategories = [];
  fueltype = [];
  contractDuration = [3, 6, 12];
  garanties: any = [];
  currentGaranties: any = [];
  sous_garanties: any = [];
  activeGarantie: number = 0;
  selectedG = [];
  selectedGid = [];
  linkedCode = ['gtcom', 'gtcol', 'gdr'];
  resultSimulation = [];
  simulation = [];
  initPaymentForm = {
    requestId: 0,
    numeroSouscription: '',
    status: '',
    montant: '',
  }
  loginform = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });
  inscriptionForm = new FormGroup({
    tel: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.pattern(this.emailPattern)]),
    password: new FormControl(""),
  });
  forgotForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),

  });
  paymentForm = {
    timestamp: this.dateTime,
    returnUrl: environment.baseApiUrl + 'contrats/save-contrat',
    auth: '',
    amount: '',
    requestId: this.dateTime,
    // terminalNumber: 160820000101,
    terminalNumber: 423820000101,
    transactionCurrencyCode: 'XOF',
    customerLastName: '',
    customerFirstName: '',
    customerAddress: '',
    customerCountry: '',
    customerEmail: '',
    customerPhone: '',
    customerState: '',
    customerCity: '',
    customerZipCode: '',
    ville: '',
    montantSaisie: '',
  }
  listPays: any = [];
  //stepWa: NavigationMode = new NavigationMode();#
  @ViewChild('contentResultcontact', { static: false }) contentResultmail: any;
  @ViewChild('contentResult', { static: false }) contentResult: any;
  @ViewChild('contentResultass', { static: false }) contentResultass: any;
  @ViewChild('contentResult2', { static: false }) contentResultgrise: any;
  @ViewChild('contentResultlogin', { static: false }) modalog: any;
  @ViewChild('contentResultforgot', { static: false }) modaforgot: any;
  @ViewChild('contentResultsign', { static: false }) modasign: any;
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;
  @ViewChild('autoDownload') autoDownload: ElementRef;
  @ViewChild('devisAEnvoye', { static: false }) devisAEnvoye: any;
  @ViewChild('etreRappeller', { static: false }) etreRappeller: any;
  listMarques: any = [];
  // key = "676AC4CC226201685E89ADB0C9B4510D";
  key = "FC48ABF885F26C13B0BE46B54618BC";
  desc1: any;
  loadingFiles: number;
  listModeles: any = [];
  complete: boolean;
  collision: boolean;
  plafone: boolean;
  incendie: boolean;
  vol: boolean;
  assureurs: any = [];
  listAssureurs:any = [];
  moto: boolean;
  camion: boolean;
  vp: boolean;
  paiment: boolean = false;
  assureur1: any;
  loaderVisible: boolean;
  userInconnu: string;
  primerc: any;
  tot: any;
  _idAssurance: any;
  _typeAssurance: string;
  user: any;
  loadContact: boolean = false;
  tokenid: any;
  duree = 0;
  listAgences: any = [];
  currentSouscripteur = "Pour moi-même";
  numeroSous = "";
  refPro = "";
  newDate = new Date();
  dateExpiration: any;
  @ViewChild('closeBTN') closeBTN: ElementRef;
  tmpPaymentMethods: any[] = [];
  pointRelais: any;
  currentId;
  assureurId;
  assureurAEnvoye;
  updateRequest: boolean = false;
  app = APP
  logoImage = `/assets/image/img/Digit conseil logo/Logo Jpeg/CMJK pour l\'impression/${this.app.dci.logo}`;
  constantes = CONSTANTES;
  constructor(private subscriptionService: SubscriptionService,
    private location: Location, private datePipe: DatePipe,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    public router: Router, private accountService: AccountService) {
      this.maxDateNaissance = moment().subtract(18, 'years');
  }


      // Settings configuration
      mySettings: IMultiSelectSettings = {
        enableSearch: true,
        checkedStyle: 'fontawesome',
        buttonClasses: 'btn btn-default btn-block',
        dynamicTitleMaxItems: 3,
        displayAllSelectedText: true
      };

    // Text configuration
    myTexts: IMultiSelectTexts = {
      checkAll: 'Selectionner Tout',
      uncheckAll: 'Décocher Tout',
      checked: 'Element Selectionné',
      checkedPlural: 'Element Selectionné',
      searchPlaceholder: 'Chercher',
      searchEmptyResult: 'Aucun elément trouvé...',
      searchNoRenderText: 'Type in search box to see results...',
      defaultTitle: 'Select',
      allSelected: 'Tout selectionné',
    };
  ngOnInit(): void {
    this.devisType = this.route.snapshot.paramMap.get("type");
    // this.getGaranties();
    this.user = JSON.parse(localStorage.getItem('microFinance'));

    let time = new Date().getTime();
    let pro = new Date().getTime() + 123890989;
    this.numeroSous = "SN" + time + "SC";
    this.refPro = "SN" + pro + "PR";;
    this.getPays();
    this.assureur();
    this.getPaymentMethods();
    this.getDurees();
    this.domicile();
    this.getAgences();
    // this.get_all_devis()
  }
  scroll() {
    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  get idAssurance() {
    return this._idAssurance;
  }

  @Input()
  set idAssurance(id) {
    this._idAssurance = id;
    // this.fireFunctions();
  }

  get typeAssurance() {
    return this._typeAssurance;
  }

  @Input() set typeAssurance(type: string) {
    this._typeAssurance = type;
  }
  //  get simulationVehicule(){
  //   return this.simulationVoyageForm.controls;
  //  }
  //  validerchamp(){
  //    this.step1 != this.step1;
  //  }
  /*Simuler*/
  getDurees() {
    this.subscriptionService.getDurees().subscribe((resp) => {
      this.listDurees = resp['data'] || [];
    });
  }
  getVehiculeType() {
    this.subscriptionService.getVehiculeCategories().subscribe((resp) => {
      this.vehiculeCategories = resp['data'] || [];
    });
  }
  getPays() {
    this.subscriptionService.getPAys().subscribe((resp) => {
      this.listPays = resp['data'];
    });
  }
  marque() {
    let dah = this.simulationVoyageForm.get('marque').value;
    this.subscriptionService.getmodel(dah).subscribe((resp) => {
      this.listModeles = resp['data'];
    });
  }
  onBlurEvent(event: any) {
    // if (this.simulationVoyageForm.get('valeurNeuf').value && event.target.value) {
    //   this.valeurNeuf = Number(this.simulationVoyageForm.get('valeurNeuf').value.replace('', ' '));
    //   this.valeurVenal = event.target.value.replaceAll(' ', '')
    // }

  }
  individuelvoid() {
    this.individuel = true;
    this.flotte = false
  }
  flottevoid() {
    this.flotte = true;
    this.individuel = false;
  }
  retour() {
    this.flotte = false;
    this.login = true;
    this.individuel = false;
    window.scroll(500,500);
  }
  retour2() {
    this.flotte = false;
    this.login = false;
    this.individuel = false;
  }
  mail() {
    let user = this.user.infos;
    this.personcontactForm.get('last_name').setValue(user.lastName);
    this.personcontactForm.get('first_name').setValue(user.firstName);
    this.personcontactForm.get('email').setValue(user.email);
    this.personcontactForm.get('telephone').setValue(user.telephone);
    this.modalService.open(this.contentResultmail, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

        this.utilsService.showToastSuccess("Votre demande de rappel à été enregistré avec success. Vous allez recvoir un mail de confirmation", "Demande réussie")
      }
      // this.wizard.goToNextStep();
    }, (reason) => {

    });
  }
  goTo(step){
    window.scroll(500,500);
    this.wizard.goToStep(step);
  }
  saveContact() {
    if (this.personcontactForm.invalid) {
      this.utilsService.showToastError("Veuillez remplir tous les champs obligatoire", "Attention !!!");
    } else {
      this.loadContact = true;
      let value = this.personcontactForm.value;
      // @ts-ignore
      value['jour'] = value['jour'].join();
      // @ts-ignore
      value['heure'] = value['heure'].join();
      this.subscriptionService.saveContact(value).subscribe(resp => {
        if (resp['responseCode'] == 200) {
          this.closeBTN.nativeElement.click();
          this.loadContact = false;
          this.utilsService.showToastError("Votre demande a été enregistrer avec succès", "Demande réussi")
        } else {
          this.loadContact = false;
          this.utilsService.showToastError(resp['message'], "Erreur")
        }
      }, error => {
        this.loadContact = false;
        this.utilsService.showToastError(error.error['errors'][0]['message'], "Erreur")
      });
    }
  }
  addAttachment(e: any): void {
    this.loadingFiles = 0;
    if (e && e.target.files) {
      this.loadingFiles = e.target.files.length;
      for (let i = 0; i < this.loadingFiles; i++) {
        let file = e.target.files[i];
        let fileName = file.name;
        let attachment: IAttachment = {
          originalFilename: fileName,
          contentType: file.type,
          sizeInBytes: file.size,
          extension: this.getExtension(fileName),
          processing: true,
        };
        this.attachments.push(attachment);
        attachment.processing = false;
      }

    }
    // this.attachments.push(attachment.processing);
    e.target.value = '';
  }
  getExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.'));
  }
  inscrire() {
    this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modasign, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {

    });
  }
  checkValue(event) {
  }
  onBlurEventneuf(event: any) {
    if (this.simulationVoyageForm.get('valeurVenal').value && event.target.value) {
      // @ts-ignore
      this.valeurVenal = Number(this.simulationVoyageForm.get('valeurVenal').value.replace('', ' '));
      this.valeurNeuf = event.target.value.replaceAll(' ', '');

    }
  }
  triArray(data,type){
    data.sort(function(a, b) {
      return a[type] - b[type];
    });
  }

  getGaranties() {
    this.garanties = [];
    this.subscriptionService.getTypeGaranties().subscribe((resp) => {

      if (resp['responseCode'] == 200) {
        resp['data'].forEach(element => {
          if (element.parent_id == null) {
            this.garanties.push(element);
          }
        });
      }
    });
  }

  selectVehiculeCategories(idV: any) {
    let car = this.vehiculeCategories.find((v) => v.id == idV);

    if (car.libelle == "Véhicule de transport pour son propre compte" || car.libelle == "Véhicule de transport public de marchandises") {
      this.subVehiculeCategories = car;
      this.camion = true;
      this.moto = false;
      this.vp = false;
    }
    else if (car.libelle == "2 ou 3 Roues") {
      this.subVehiculeCategories = car;
      this.moto = true;
      this.vp = false;
      this.camion = false;
    }
    else {
      this.camion = false;
      this.vp = true;
      this.moto = false;
    }
    // @ts-ignore
    this.simulationVoyageForm.get("categorieVehicule").setValue(car.id);
    this.typeVehicule = car.libelle;
  }

  selectGarantie(g) {
    this.activeGarantie = g.id === this.activeGarantie ? 0 : g.id;
  }

  get categorieVehicule() {
    return this.simulationVoyageForm.get("categorieVehicule").value;
  }
  assuree() {
    if (this.simulationVoyageForm.get('assure').get('profession').invalid || this.simulationVoyageForm.get('assure').get('numeroTelephone').invalid || this.simulationVoyageForm.get('assure').get('prenom').invalid || this.simulationVoyageForm.get('assure').get('nom').invalid || this.simulationVoyageForm.get('assure').get('secteuractivite').invalid || this.simulationVoyageForm.get('assure').get('adresse').invalid || this.simulationVoyageForm.get('assure').get('genre').invalid || this.simulationVoyageForm.get('assure').get('email').invalid) {
      this.invalid = true
      //  this.wizard.goToNextStep();
    }
    else {
      // @ts-ignore
      this.simulationVoyageForm.get('souscripteur').patchValue(this.simulationVoyageForm.get('assure').value);
      this.simulationVoyageForm.get('souscripteur').get('telephone').setValue(this.simulationVoyageForm.get('assure').get('numeroTelephone').value);

      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      this.invalid = false;
    }
  }
  addGarantie(g: any, sg: any, sousGarantie?) {
    let line = {
      garanties_id: g.id,
      garanties_name: g.nom,
      garantie_categories_id: g.id,
      code: g.code
    }
    let gExist = this.selectedG.find((selec) => selec.garanties_id == g.id);
    if (gExist) {
      sg['selected'] = false;
      g['selected'] = false;
      this.selectedG.splice(this.selectedG.indexOf(gExist));

      if (gExist.code == 'gtcol')
        this.collision = false;

      if (gExist.code == 'gi')
        this.incendie = false;

      if (gExist.code == 'gv')
        this.vol = false;

      if (gExist.code == 'gtp')
        this.plafone = false;

      if (gExist.code == 'gtcom')
        this.complete = false;

      gExist = {};
    }
    let gExisttierceauto = this.selectedG.find((selec) => selec.code == "gtcol");
    let gExisttiercecomp = this.selectedG.find((selec) => selec.code == "gtcom");
    let gExisttiercepla = this.selectedG.find((selec) => selec.code == "gtp");

    if (!gExist && g.code != "gtp" && g.code != "gtcom" && g.code != "gtcol" && g.code != "gi" && g.code != "gv" && g.code != "gpt") {

      this.selectedG.push(line);
      sg['selected'] = true;
      g['selected'] = true;
    }
    else
      if (!gExisttierceauto && !gExisttiercepla && !gExist && g.code == "gtcom") {
        this.selectedG.push(line);
        this.complete = true;
        sg['selected'] = true;
        g['selected'] = true;
      } else
        if (!gExisttiercecomp && !gExisttiercepla && !gExist && g.code == "gtcol") {
          this.selectedG.push(line);
          this.collision = true;
          sg['selected'] = true;
          g['selected'] = true;
        } else
          if (!gExisttiercecomp && !gExisttierceauto && !gExist && g.code == "gtp") {
            this.selectedG.push(line);
            this.plafone = true;
            sg['selected'] = true;
            g['selected'] = true;
          }
          else
            if (!gExist && g.code == "gi") {
              this.selectedG.push(line);
              this.incendie = true;
              sg['selected'] = true;
              g['selected'] = true;
            }
            else
              if (!gExist && g.code == "gv") {
                this.selectedG.push(line);
                this.vol = true;
                sg['selected'] = true;
                g['selected'] = true;
              } else
                if (!gExist && g.code == "gpt") {
                  let gExistGPT = this.selectedG.find((selec) => selec.code == g.code);
                  if (gExistGPT) {
                    this.selectedG.splice(this.selectedG.indexOf(gExistGPT));
                    let tab = sousGarantie.filter(el => el.id == gExistGPT.garanties_id)
                    if (tab.length > 0)
                      tab[0]['selected'] = false;
                  }
                  this.selectedG.push(line);

                  sg['selected'] = true;
                  g['selected'] = true;
                }
                else
                  if (gExisttiercecomp && !gExist && (g.code == "gtcol" || g.code == "gtp")) {
                    this.utilsService.showToastError(" Veuillez désélectionner d'abord", "Impossible de sélectionner Garantie Tierce complète et Garantie Tierce collision et Garantie Tierce plafonnée en même temps");
                    sg['selected'] = false;
                    g['selected'] = false;

                  }
                  else
                    if (gExisttierceauto && !gExist && (g.code == "gtcom" || g.code == "gtp")) {
                      this.utilsService.showToastError(" Veuillez désélectionner d'abord", "Impossible de sélectionner Garantie Tierce complète et Garantie Tierce collision et Garantie Tierce plafonnée en même temps");
                      sg['selected'] = false;
                      g['selected'] = false;
                    }
                    else
                      if (gExisttiercepla && !gExist && (g.code == "gtcom" || g.code == "gtcol")) {
                        this.utilsService.showToastError(" Veuillez désélectionner d'abord", "Impossible de sélectionner Garantie Tierce complète et Garantie Tierce collision et Garantie Tierce plafonnée en même temps");
                        sg['selected'] = false;
                        g['selected'] = false;
                      }
  }

  setAssure(n) {
    let user = this.user.infos;
    if (n === '1') {
      this.simulationVoyageForm.get('isSubscriber').setValue(true);
      this.currentSouscripteur = "Pour moi-même";
      this.simulationVoyageForm.get('assure').get('nom').setValue(user.lastName);
      this.simulationVoyageForm.get('assure').get('prenom').setValue(user.firstName);
      this.simulationVoyageForm.get('assure').get('email').setValue(user.email);
      this.simulationVoyageForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.simulationVoyageForm.get('assure').get('adresse').setValue(user.adresse);
      this.simulationVoyageForm.get('assure').get('profession').setValue(user.profession);
      this.simulationVoyageForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
    }
    else if(n === '2') {
      this.simulationVoyageForm.get('isSubscriber').setValue(false);
      this.currentSouscripteur = "Pour un tiers";
      this.simulationVoyageForm.get('assure').get('nom').setValue('');
      this.simulationVoyageForm.get('assure').get('prenom').setValue('');
      this.simulationVoyageForm.get('assure').get('email').setValue('');
      this.simulationVoyageForm.get('assure').get('numeroTelephone').setValue('');
      this.simulationVoyageForm.get('assure').get('adresse').setValue('');
      this.simulationVoyageForm.get('assure').get('profession').setValue('');
      this.simulationVoyageForm.get('assure').get('secteuractivite').setValue('');
    }
    else if(n === '3') {
      this.simulationVoyageForm.get('isSubscriber').setValue(false);
      // this.currentSouscripteur = "Pour un tiers";
      this.simulationVoyageForm.get('assure').get('nom').setValue('');
      this.simulationVoyageForm.get('assure').get('prenom').setValue('');
      this.simulationVoyageForm.get('assure').get('email').setValue(user.email);
      this.simulationVoyageForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.simulationVoyageForm.get('assure').get('adresse').setValue(user.adresse);
      this.simulationVoyageForm.get('assure').get('profession').setValue(user.profession);
      this.simulationVoyageForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
    }
  }

  // setAssure(n) {
  //   let user = this.user.infos;
  //   if (n == 1) {
  //     this.simulationVoyageForm.get('isSubscriber').setValue(true);
  //     this.currentSouscripteur = "Pour moi-même";
  //     this.simulationVoyageForm.get('assure').get('nom').setValue(user.lastName);
  //     this.simulationVoyageForm.get('assure').get('prenom').setValue(user.firstName);
  //     this.simulationVoyageForm.get('assure').get('email').setValue(user.email);
  //     this.simulationVoyageForm.get('assure').get('numeroTelephone').setValue(user.telephone);
  //     this.simulationVoyageForm.get('assure').get('adresse').setValue(user.adresse);
  //     this.simulationVoyageForm.get('assure').get('profession').setValue(user.profession);
  //     this.simulationVoyageForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
  //   }
  //   else {
  //     this.simulationVoyageForm.get('isSubscriber').setValue(false);
  //     this.currentSouscripteur = "Pour un tiers";
  //     this.simulationVoyageForm.get('assure').get('nom').setValue('');
  //     this.simulationVoyageForm.get('assure').get('prenom').setValue('');
  //     this.simulationVoyageForm.get('assure').get('email').setValue('');
  //     this.simulationVoyageForm.get('assure').get('numeroTelephone').setValue('');
  //     this.simulationVoyageForm.get('assure').get('adresse').setValue('');
  //     this.simulationVoyageForm.get('assure').get('profession').setValue('');
  //     this.simulationVoyageForm.get('assure').get('secteuractivite').setValue('');
  //   }
  // }
  connecter() {
    this.userInconnu = "";
    if (this.loginform.valid) {
      this.loaderVisible = true;
      let data = {
        ... this.loginform.value
      }
      this.subscriptionService.login(data)
        .subscribe((res) => {
          if (res['data']) {
            const tokenObj = res['data'];
            let infos = {
              id: tokenObj.id,
              matricule: tokenObj.matricule,
              firstName: tokenObj.firstName,
              lastName: tokenObj.lastName,
              email: tokenObj.email,
              telephone: tokenObj.telephone,
              secteuractivite: tokenObj.secteuractivite,
              profession: tokenObj.profession,
              adresse: tokenObj.adresse
            }
            let detail = {
              id_token: tokenObj.access_token,
              refresh_token: tokenObj.refresh_token,
              // roles: tokenObj.roles,
              infos: infos,
              authorities: tokenObj.authorities
            };
            if (res['data']['access_token']) {
              localStorage.setItem('microFinance', JSON.stringify(detail));
              localStorage.setItem('microFinanceBo', JSON.stringify(detail));
              this.accountService.sendAccount(JSON.stringify(infos));
              this.user = detail;
              this.login = true;
              this.getPays();
              this.getVehiculeType();
              this.getGaranties();
              this.getPaymentMethods();
              this.getDurees();
              this.getAgences();
              // this.loaderVisible = false;
              this.scroll();
              this.modalService.dismissAll(this.modalog);
            }
            else {
              this.souscrire();
              this.userInconnu = res['message'];
              this.loaderVisible = false;
            }
          } else {
            this.loaderVisible = false;
            this.userInconnu = res['message'];
          }
          this.loaderVisible = false;
        }, (err) => {
          this.userInconnu = err['error']['message'] || err['error']['errors'][0]['message'];
          this.loaderVisible = false;
        });

    } else {
      this.checkValidity(this.loginform);
    }
  }
  valider() {
    this.sendingRequestAssureur = true;
    if (this.simulationVoyageForm.get('dateDepart').invalid || this.simulationVoyageForm.get('dateRetour').invalid || this.simulationVoyageForm.get('paysDepart').invalid || this.simulationVoyageForm.get('paysArrive').invalid || this.simulationVoyageForm.get('dateNaissance').invalid) {
      this.invalid = true;
    } else {
      this.invalid = false;
      let duree = moment(this.simulationVoyageForm.get('dateRetour').value).diff(this.simulationVoyageForm.get('dateDepart').value, 'days');
      let age = moment().diff(this.simulationVoyageForm.get('dateNaissance').value, 'years');
      if (age == 0)
        age = 1;
      for (let ass of this.listAssureurs) {
        let detail = {
          "paysArrive": this.simulationVoyageForm.get('paysArrive').value,
          "paysDepart": this.simulationVoyageForm.get('paysDepart').value,
          "age": age,
          "duree": duree,
          "assureur": ass.id,
          "pack": 'voyage'
        }
        this.subscriptionService.simulatePrimeVoyage(detail).subscribe(resp => {
          if (resp) {
            let value = resp["data"];
            let total = value.filter(el => el.key == 'Montant TTC');
            if (total.length != 0)
              ass['prix'] = total[0].value;
           } else ass['prix'] = 0;

           this.assureurs.push(ass);

           setTimeout(() => {
            this.sendingRequestAssureur = false;
          }, 1000);

          this.triArray(this.assureurs,'prix');
        }, error => {
          ass['prix'] = 0;
        });
      }
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
    }
  }
  removeGarantie(g: any, sg: any) {
    let line = {
      code: g.code,
      garanties_id: g.id,
      garantie_categories_id: sg.id
    }
    if (g.code == "gtcom") {
      this.complete = false;
    }
    else if (g.code == "gtcol") {
      this.collision = false;
    }
    else if (g.code == "gi") {
      this.incendie = false;
    }
    else if (g.code == "gpt") {
      this.collision = false;
      this.complete = false;
    }
    let index = this.selectedG.findIndex(gr => gr.garanties_id == g.id && gr.garantie_categories_id == sg.id);
    if (index >= 0) {
      this.selectedG.splice(index, 1);

      sg['selected'] = false;
      g['selected'] = false;

    }
  }

  checkSelectedG(code) {
    if (this.linkedCode.indexOf(code)) {
      let elm = this.selectedG.find((gr) => (gr.code == 'gtcom' || gr.code == 'gtcol' || gr.code == 'gdr'));
      if (elm && elm.code != code) {
        return true;
      }
    }
    return false;
  }
  get_all_devis() {

    let body = {
      'garanties': {},
      ...this.simulationVoyageForm.value
    }

    this.subscriptionService.simulatePrime(body).subscribe((resp) => {
      if (resp['code'] == 200 && !resp['error']) {
        this.simulation = resp['data'];
      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    });

  }

  getPaymentMethods() {
    this.subscriptionService.getPaymentMethods().subscribe((resp) => {
      this.paymentMethods = resp['data'];
      for (let pay of this.paymentMethods) {
        if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet.jfif";
        else if (pay.code == 'OM') pay['logo'] = "assets/images/om.jpg";
        else if (pay.code == 'FM') pay['logo'] = "assets/images/fm.png";
        else if (pay.code == 'WM') pay['logo'] = "assets/images/wm.jpg";
        else if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet2.png";
      }
      this.tmpPaymentMethods = this.paymentMethods;
      this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
      this.simulationVoyageForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
    });
  }
  // loginform(){
  //   let body={
  //     ...this.loginform1
  //   }
  //   this.subscriptionService.login().subscribe((resp) =>{
  //     if(resp['data'])
  //       localStorage.setItem('access_token_ma', resp['data']['access_token']);
  //   });
  // }

  getAgences() {
    this.subscriptionService.getListAgences().subscribe((resp) => {
      this.listAgences = resp['data'];
    });
  }

  processSimulation() {
    if (this.incendie || this.complete || this.collision || this.vol || this.plafone) {
      // if (this.simulationVoyageForm.get('valeurNeuf').valid || this.simulationVoyageForm.get('valeurVenal').valid) {
      //   this.wizard.goToNextStep();
      //   window.scrollTo(500,500);
      //   this.assureur();
      // }
      // else {
      //   this.utilsService.showToastError('Error', "Valeur vénal ou valeur à neuf obligatoire");
      // }
    }
    else {
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      this.assureur();
    }

  }
  assureur() {
    this.sendingRequestAssureur = true;
    this.subscriptionService.assureur().subscribe((resp) => {
      if (resp['responseCode'] = 200) {
        this.listAssureurs = resp['data'];

        for (let val of this.listAssureurs) {
          val.nom = val.nom.toLowerCase();
          if (val.code == 'NSIA') val['img'] = "../../../../../assets/images/nsia.png";
          else if (val.code == 'AXA') val['img'] = "../../../../../assets/images/axa.png";
          else if (val.code == 'AMSA') val['img'] = "../../../../../assets/images/amsa.png";
          else if (val.code == 'SALAMA') val['img'] = "../../../../../assets/images/salama.png";
          else val['img'] = "../../../../../assets/images/assurance.jpg";
        }
          this.sendingRequestAssureur = false;
      } else {
        this.sendingRequestAssureur = false;
        this.assureurs = [];
      }
    }, error => {
      this.sendingRequestAssureur = false;
      this.assureurs = [];
    })
  }
  onDateSelect3(event, groupName, controlName) {
    if (event)
      this.simulationVoyageForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
  }
  getprix(id_ass) {
  }


  openModalEnvoyeDevis(type){
    this.assureurAEnvoye = type;
    this.modalService.open(this.devisAEnvoye, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }

  devisParMail(type) {

    this.assureurId = type.id;
    this.sendingRequestAssureur = true;
    this.updateRequest = true;
   // let val = Number(this.mrhForm.get('packForm1').get('valeur_batiment').value);
    // let contenu = Number(this.mrhForm.get('packForm1').get('valeur_contenu').value);
   // let piece = Number(this.mrhForm.get('packForm1').get('pieces').value);
    // let body ={
    //     "capitalContenu" : this.mrhForm.get('packForm1').get('valeur_contenu').value,
    //     "valeur" : val,
    //     "assureur": type.id,
    //     "nombrePiece" : piece,
    //     "garanties": this.garantie,
    //  }
    let body;

    // Recupération du prix
    this.subscriptionService.getprice(body).subscribe((resp:any) => {
      if (resp) {

        //   if (resp.garanties) {
        //     resp.garanties.forEach(element => {
        //         this.prix = this.prix + Number(element.primeTtc)
        //     });
        //     this.base = resp.parametrageMrh.prix;
        //     this.prix = this.prix + this.base;
        // } else {
            // this.prix = resp.assureur.parametrageMrh.prix;
        // }



        // let data = {
        //   ...this.simulationVoyageForm.value,
        //   "assureur": type.id,
        //   "type": "mail",
        //   "prix": this.prix
        // };
      let data;
        // Envoi devis avec prix
        this.subscriptionService.envoiDevisMail(data).subscribe((resp) => {
          if (resp['responseCode'] = 200) {
            this.currentId = resp['data'].id;
            this.utilsService.showToastSuccess(resp['message'], "Devis envoyé avec succés");

            this.sendingRequestAssureur = false;
          } else {
            this.sendingRequestAssureur = false;
          }
        }, error => {
          this.sendingRequestAssureur = false;
          this.utilsService.showToastError(resp['message'], "erreur sur le server");
        })


      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    });
  }
  devisParWatshap(type) {
    this.assureurId = type.id;
    this.sendingRequestAssureur = true;
    this.updateRequest = true;
    let body = {
      ...this.simulationVoyageForm.value,
      "assureur": type.id
    };

    // Recuperation du prix
    this.subscriptionService.simulatePrime(body).subscribe((resp:any) => {
      if (resp) {
        let prix = resp.find(el=>{
         return el.key =='Prime Totale TTC'
        });

        let data = {
          ...this.simulationVoyageForm.value,
          "assureur": type.id,
          "type": "watshap",
          "prix": prix.value
        };

        // Envoi devis avec prix
        this.subscriptionService.envoiDevisMail(data).subscribe((resp) => {
          if (resp['responseCode'] = 200) {
            this.currentId = resp['data'].id;
            this.utilsService.showToastSuccess(resp['message'], "Devis envoyé avec succés");

            this.sendingRequestAssureur = false;
          } else {
            this.sendingRequestAssureur = false;
          }
        }, error => {
          this.sendingRequestAssureur = false;
          this.utilsService.showToastError(resp['message'], "erreur sur le server");
        })


      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    });
  }
  lanceforgot() {
    this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modaforgot, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {

    });
  }
  forgot() {
    let data = {
      "email": this.inscriptionForm.get('email').value,
    }
    this.loaderVisible = true;
    this.subscriptionService.reset(data).subscribe((resp) => {
      if (resp["responseCode"] = 200) {
        this.utilsService.showToastSuccess("Un mail vous a été envoyé pour réinitialiser votre mot de passe", "");
        this.modalService.dismissAll(this.modasign);
      }
      else {
        this.utilsService.showToastError(resp['message'], "Echec");
      }
      this.loaderVisible = false;
    }, error => {
      let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
      if (error && error.error) {
        message = error.error.message;
      }
      this.utilsService.showToastError(message, "Echec");

      this.loaderVisible = false;
    })
  }
  processSimulation2(ass, sous?) {
    if (sous)
      this.isSouscription = false;
    else this.isSouscription = true;
    this.assureur1 = ass.id;
    this.currentAssureur = ass;
    this.selectedGid = [];
    this.simulationVoyageForm.get('assureur').setValue(ass.id);
    let duree = moment(this.simulationVoyageForm.get('dateRetour').value).diff(this.simulationVoyageForm.get('dateDepart').value, 'days');
    let age = moment().diff(this.simulationVoyageForm.get('dateNaissance').value, 'years');
    if (age == 0)
      age = 1;
    this.age = age;
    this.duree = duree;
    let body = {
      'age': age,
      'duree': duree,
      "paysArrive": this.simulationVoyageForm.get('paysArrive').value,
      "paysDepart": this.simulationVoyageForm.get('paysDepart').value,
      "assureur": ass.id,
      "pack": 'voyage'
    };
    this.sendingRequest = true;
    this.subscriptionService.simulatePrimeVoyage(body).subscribe((resp: any) => {
      if (resp && !resp['error']) {
        this.resultSimulation = resp['data'];
        let total = this.resultSimulation.filter(el => el.key == 'Montant TTC');
        if (total.length != 0)
          this.tot = total[0].value;
        this.openModalResult();

      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    }, error => {

      this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");

      this.sendingRequest = false;
    });

  }
  inscrireutil() {
    if (this.inscriptionForm.valid) {
      let data = {
        "telephone": this.inscriptionForm.get('tel').value,
        "email": this.inscriptionForm.get('email').value,
        "type": 5 + ""
      }
      this.loaderVisible = true;
      this.subscriptionService.sign(data).subscribe((resp) => {
        if (resp["responseCode"] == 200) {
          this.utilsService.showToastSuccess("Les informations d'activation de votre compte vous ont été envoyées par email et/ou SMS.", "Inscription  réussie");
          this.modalService.dismissAll(this.modasign);
          this.loaderVisible = false;
          this.inscriptionForm.reset();
        }
        else {
          this.loaderVisible = false;
          this.utilsService.showToastError(resp['message'], "Echec");
        }
        this.loaderVisible = false;
      }, error => {
        this.utilsService.showToastError(error.error.errors[0].message, "Echec");
        this.loaderVisible = false;
      });

    } else {
      this.checkValidity(this.inscriptionForm);
    }
  }

  checkValidity(g: FormGroup) {
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsDirty();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsTouched();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).updateValueAndValidity();
    });
  }

  openModalResult() {
    this.modalService.open(this.contentResultass, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {

      if (result == 'subscribe')
        //this.stepWa.goToStep(this.wizard, 4)
        this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      let user = this.user.infos;
      this.currentSouscripteur = "Pour moi-même";
      this.simulationVoyageForm.get('assure').get('nom').setValue(user.lastName);
      this.simulationVoyageForm.get('assure').get('prenom').setValue(user.firstName);
      this.simulationVoyageForm.get('assure').get('email').setValue(user.email);
      this.simulationVoyageForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.simulationVoyageForm.get('assure').get('adresse').setValue(user.adresse);
      this.simulationVoyageForm.get('assure').get('profession').setValue(user.profession);
      this.simulationVoyageForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
    }, (reason) => {

    });
  }
  convertStringToNumber(val) {
    return parseFloat(val);
  }
  getNamePaysById(id) {
    for (let pays of this.listPays) {
      if (pays.id == id) {
        return pays.nom;
      }
    }
  }
  verifymodal() {
    this.modalService.open(this.contentResultgrise, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {
        this.wizard.goToNextStep();
        window.scrollTo(500, 500);
      }
      else {

      }
    }, (reason) => {

    });
  }
  selectPayment(pay) {
    this.simulationVoyageForm.get('moyenDePaiement').setValue(pay.code == this.simulationVoyageForm.get('moyenDePaiement').value ? "" : pay.code);
    this.paymentMethod = pay.nom.toLowerCase();
  }
  saveData() {
    // if(this.simulationVoyageForm.get('first_name').invalid || this.simulationVoyageForm.get('last_name').invalid || this.simulationVoyageForm.get('first_name').invalid || this.simulationVoyageForm.get('phone').invalid || this.simulationVoyageForm.get('email').invalid){
    //   this.invalid = true;
    // }
    // else{

    let data = {
      ...this.simulationVoyageForm.value,
      "assureur": this.assureur1,
      "nombreJour": this.duree,
      "age": this.age,
      "date_effet": this.simulationVoyageForm.get('dateDepart').value
    };
    this.sendingRequest = true;
    let d = this.resultSimulation.map((res) => {
      if (res.key == 'Montant TTC') {
        data['prix'] = res.value;
        this.primerc = res.value;
        this.paymentForm.amount = res.value;
      }
      return res;
    });
    this.subscriptionService.saveSouscription(data).subscribe((resp) => {
      this.sendingRequest = true;
      if (resp['responseCode'] == 200) {
        let type = '&meansType=CARTE_OTHER';
        this.initPaymentForm.montant = this.paymentForm.amount;
        this.initPaymentForm.requestId = this.dateTime;
        this.initPaymentForm.numeroSouscription = resp['data'].numeroSouscription;
        this.initPaymentForm.status = "PENDING"
        this.subscriptionService.initPaiementBack(this.initPaymentForm).subscribe((rep) => {
          if (rep['responseCode'] != 200) {
            this.utilsService.showToastError(resp['message'], "Echec");
          }
          this.sendingRequest = false;

        }, error => {
          this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
          this.sendingRequest = false;
          window.scrollTo(500, 500);
        });
        if (this.paymentMethod == 'wallet') type = '&meansType=WALLET';
        let hash = this.paymentForm.timestamp + '' + this.paymentForm.amount + '' + this.paymentForm.timestamp + 'XOF' + this.key;
        this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);
        this.paymentForm.customerFirstName = this.simulationVoyageForm.get('souscripteur').get('prenom').value;
        this.paymentForm.customerLastName = this.simulationVoyageForm.get('souscripteur').get('nom').value;
        this.paymentForm.customerPhone = this.simulationVoyageForm.get('souscripteur').get('telephone').value;
        this.paymentForm.customerCountry = 'SN';
        this.paymentForm.customerCity = 'DAKAR';
        this.paymentForm.customerState = 'DAKAR';
        this.paymentForm.customerZipCode = '25455';
        this.paymentForm.customerAddress = 'DAKAR';
        this.paymentForm.customerEmail = this.simulationVoyageForm.get('souscripteur').get('email').value;
        this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {

          if (respApg['code'] == '0000') {
            this.utilsService.showToastSuccess("Votre souscription a été enregistrée avec succès. Vous allez être redirigé vers la page de paiement", "Souscription réussie")
            setTimeout(() => {

              this.wizard.goToStep(1);
              window.open(respApg['paymentUrl'] + type, '_blank');

            }, 2000);
          } else {
            this.sendingRequest = false;
            this.utilsService.showToastError(respApg['message'] || "Impossible de contacter le serveur", "Echec");
            // this.simulationVoyageForm.reset;
            this.wizard.goToStep(1);
            window.scrollTo(500, 500);
          }
        }, error => {
          this.sendingRequest = false;
          this.utilsService.showToastError("Impossible de contacter le serveur", "Echec");
          // this.simulationVoyageForm.reset;
          this.wizard.goToStep(1);
          window.scrollTo(500, 500);
        });
      } else {
        this.sendingRequest = false;
        window.scrollTo(500, 500);
        this.utilsService.showToastError(resp['message'], resp['message']);
        // this.simulationVoyageForm.reset;
      }
    }, (error) => {
      this.sendingRequest = false;
      window.scrollTo(500, 500);
      this.utilsService.showToastError(error.error.errors[0].message, "Echec")
    });


  }
  checkSubscription(numberSouscription) {
    const source = interval(5000)
    const check = source.subscribe(x => {
      fetch(environment.baseApiUrl + 'souscriptions/check-subscription?numeroSouscription=' + numberSouscription)
        .then(response => {
          response.json()
            .then(data => {
              if (data['responseCode'] == 200) {
                this.router.navigateByUrl("success-payment");
                check.unsubscribe();
              }
            });
        });
    });
  }
  souscrire() {
    this.scroll();
    this.modalService.dismissAll(this.modasign);
    let token = JSON.parse(localStorage.getItem('microFinance'));
    if (token) {
      this.login = true;

    } else {
      this.modalService.open(this.modalog, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
        if (result == 'subscribe') {

        }
      }, (reason) => {

      });
    }
  }

  domicile() {
    this.pointRelais = null;
    this.currentDom = "activeLivraison";
    this.currentRel = "";
    this.paiment = true;
    this.simulationVoyageForm.get('livraison').setValue('Domicile');
    this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
    if(this.paymentMethods.length != 0){
      this.simulationVoyageForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
    }

  }
  relais() {
    this.currentRel = "activeLivraison";
    this.currentDom = "";
    this.simulationVoyageForm.get('livraison').setValue('Relais');
    this.paiment = true;
    this.paymentMethods = this.tmpPaymentMethods;
    this.simulationVoyageForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
    this.subscriptionService.getPointRelais(this.currentAssureur['id']).subscribe((resp) => {
      this.pointRelais = resp['data'];
  });
  }

  onDateSelect(event, controlName) {
    if (event) {
      this.simulationVoyageForm.get('' + controlName).setValue(new Date(event.year + "/" + event.month + "/" + event.day));
      if (controlName == "dateDepart") {
        this.minDate = {
          year: new Date(this.simulationVoyageForm.get('' + controlName).value).getFullYear(),
          month: new Date(this.simulationVoyageForm.get('' + controlName).value).getMonth() + 1,
          day: new Date(this.simulationVoyageForm.get('' + controlName).value).getDate()
        };
        this.dateExpiration = moment(this.simulationVoyageForm.get('' + controlName).value).add(1, 'years');
      }
    }
  }
  onDateSelectRappel(event, controlName) {
    if (event)
      this.etrerappeleForm.get('' + controlName).setValue(new Date(event.year + "/" + event.month + "/" + event.day));
  }
  etreRappele() {

    this.etrerappeleForm.get('code').setValue(this.assureurAEnvoye.code);
    if(this.etrerappeleForm.valid){
      this.sendingRequestAssureur = true;
      this.updateRequest = true;
    this.subscriptionService.etreRappele(this.etrerappeleForm.value).subscribe((resp) => {
      if (resp['responseCode'] = 200) {
        this.utilsService.showToastSuccess("Votre demande de rappel a été enregistré avec succès", "Demande de rappel")
        this.sendingRequestAssureur = false;
        this.updateRequest = false
        this.modalService.dismissAll(this.devisAEnvoye);
      } else {
        this.updateRequest = false
        this.sendingRequestAssureur = false;
      }
    }, error => {
      this.updateRequest = false
      this.sendingRequestAssureur = false;
      this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
    });
  }
  }
  onDateSelect2(event, controlName) {

    if (event)
      this.simulationVoyageForm.get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));

  }
  openPDF(): void {
    let data = document.getElementById('autoDownload');

    // html2canvas(data).then(canvas => {
    //   const contentDataURL = canvas.toDataURL('image/png')
    //   // let pdf =  new jsPDF('l', 'cm', 'a4'); //Generates PDF in landscape mode
    //   var imgWidth = 210;
    //   var pageHeight = 380;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let pdf = new jsPDF('p', 'cm', 'a4');
    //   // Generates PDF in portrait mode
    //   pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
    //   pdf.save('Filename.pdf');
    // });
    // let doc= new jsPDF('p', 'mm', 'a4');
    // doc.html(data, {
    //    callback: (doc) => {
    //      doc.setFontSize(12);
    //      doc.setDisplayMode("original");
    //      doc.save("devis.pdf");
    //    }
    // });

    // html2canvas(data).then(canvas => {
    //   // Few necessary setting options
    //   document.body.style.zoom = "10%";
    //   var imgWidth = 210;
    //   var pageHeight = 380;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   const contentDataURL = canvas.toDataURL('image/png', 1.0);
    //   const contentDataURL1 = canvas.toDataURL('image/png', 1.0);
    //   // let l={
    //   //   orientation: 'p',
    //   //                    unit: 'pt',
    //   //                    format: 'a4',
    //   //                    compress: true,
    //   //                    fontSize: 8,
    //   //                    lineHeight: 1,

    //   //                    printHeaders: true
    //   // }
    //   let pdf = new jsPDF('p', 'mm', 'a4',true,false); // A4 size page of PDF
    //   var position = 0;
    //   // pdf.addPage();
    //   pdf.addImage(contentDataURL,'PNG', 0,-150, imgWidth, imgHeight);
    //   pdf.addPage();
    //   pdf.addImage(contentDataURL1,'PNG', 0, -267.7, imgWidth, imgHeight+39,'SLOW');
    //   pdf.save('DetailsDevis.pdf'); // Generated PDF
    //   document.body.style.zoom = "100%";
    // });
    // html2canvas(data).then(canvas => {
    //   // this.canvas.nativeElement.src = canvas.toDataURL();
    //    let dah=canvas.toDataURL('image/png');
    //     let doc= new jsPDF('p', 'mm', 'a4',false,true);
    //     doc.html(dah, {
    //       x:0,
    //       y:0,
    //       callback: (doc) => {
    //         doc.save("devis.pdf");
    //       },

    //     });
    // });

    var pdf = new jsPDF();
    // @ts-ignore
    document.body.style.zoom = "70%";
    var options = {
      pagesplit: false,
      x: 0,
      y: 370,
      quality: 4,
      scale: 2,
    };
    pdf.setFont('Calibri');
    pdf.text(50, 50, 'Now this is Calibri');
    pdf.addHTML(data, options, function () {
      pdf.save("Devis_Automobile.pdf");
    }
    );
    // @ts-ignore
    document.body.style.zoom = "100%";

    //   html2canvas(data).then(canvas => {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width ;
    //   var heightLeft = imgHeight;
    //   const contentDataURL = canvas.toDataURL('image/png', 1.0);
    //   var doc = new jsPDF('p', 'mm');
    //   var position = 0;

    //   doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;

    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     doc.addPage();
    //     doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save( 'file.pdf');
    // });
  }

  //start rappeler
  rappeler(){
    this.modalService.open(this.etreRappeller, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }

 checkDisponiblite(){
    let val = this.rappellerForm.get('disponibilite').value;
    if(val != ''){
      if(val=='Matin'){
        this.heureDisponibiliteListTemoin = this.heureDisponibiliteList.filter(el => el.filtre == 'Matin')
      }
      else{
        this.heureDisponibiliteListTemoin = this.heureDisponibiliteList.filter(el => el.filtre == 'Soir')
      }
    }
 }

 meRappeller() {
  this.sendingRequestRappel = true;
 if(this.rappellerForm.get('disponibilite').value=='Toute la journee'){
  this.rappellerForm.get('heureDisponibilite').setValue("Toute la journee")
 }
  if(this.rappellerForm.valid){
    this.subscriptionService.meRappeller(this.rappellerForm.value).subscribe((resp) => {
      if (resp['responseCode'] = 200) {
        this.utilsService.showToastSuccess("Votre demande de rappel a été enregistré avec succès", "Demande de rappel")
        this.sendingRequestRappel = false;
        this.modalService.dismissAll(this.etreRappeller);
        this.rappellerForm.reset();
      } else {
        this.sendingRequestRappel = false;
      }
    }, error => {
      this.sendingRequestRappel = false;
      this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
    });
  }
}
  //end rappeler

}
