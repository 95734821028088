import { Component, OnInit } from '@angular/core';
import { CONSTANTES } from 'src/app/shared/model/constantes';
import { APP } from 'src/app/shared/model/model';

@Component({
  selector: 'app-contact-dci',
  templateUrl: './contact-dci.component.html',
  styleUrls: ['./contact-dci.component.scss']
})
export class ContactDciComponent implements OnInit {

  constructor() { }
  constantes = CONSTANTES;
  app = APP;


  ngOnInit(): void {
  }

}
