import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-etude-conseil-details',
  templateUrl: './etude-conseil-details.component.html',
  styleUrls: ['./etude-conseil-details.component.scss']
})
export class EtudeConseilDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
