<app-header-four></app-header-four>

<div class="main-banner-section item-bg16">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>SIMULATION ASSURANCE VIE</h1>
                    <p>Elle couvre la prise en charge des frais médicaux pharmaceutiques et d’hospitalisation à la suite d’une maladie. La dite maladie étant définie comme une altération de l’état de santé dûment constatée par une autorité médicale.</p>

                    <div class="btn-box" *ngIf="!login">
                        <!-- <div (click)="souscrire()" class="default-btn btn1 c-btnSimulation">Simulation</div> -->
                        <div (click)="souscrire()" class="default-btn default-btn1">Faire une simulation<span></span></div>
                    </div>
                    <div class="btn-box" *ngIf="login">
                        <div (click)="souscrire()" class="default-btn default-btn1">Faire une simulation<span></span></div>
                        <!-- <div (click)="Souscription()" class="default-btn btn1 c-btnSimulation">Simulation</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/car.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/umbrella-white.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/home.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/heart.png" alt="image"></div>
</div>

<!-- <section class="quote-boxes-area" *ngIf="!login">
    <div class="container">
        <div class="quote-boxes-content">
            <h2>Connexion à votre compte</h2>

            <form [formGroup]="loginform">
                <div class="row align-items-center aligCen">
                    <div class="form col-lg-5 col-md-5" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                        <label for="nomUser" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                    </div>
                    <div class="text-red col-lg-1 col-md-6" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                        Le nom d'utilisateur est requis
                    </div>

                    <div class="form col-lg-5 col-md-5" ngbPopover="Mot de passe" triggers="mouseenter:mouseleave">
                        <i class="fas fa-lock form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                        <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                    </div>
                    <div class="text-red col-lg-1 col-md-6" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                        Le mot de passe est requis
                    </div>


                    <div class="col-lg-12 col-md-12">
                        <button (click)="connecter()" type="submit" class="default-btn">Connecter-vous <i class="flaticon-right-chevron"></i><span></span></button>
                    </div>
                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                        <p class="text-center ">
                            Connexion en cours
                        </p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section> -->






<div class="row desc container-row justify-content-center" *ngIf="login">
    <div class="col-12 droite">
        <form [formGroup]="simulationVehiculeForm" class="auto">
            <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">



                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Vehicule">
                    <div class="form-container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                    <option value="" disabled selected>Catégorie de voiture</option>
                    <option *ngFor="let v of vehiculeCategories" [value]="v.id">{{v?.libelle}}</option>
                  </select>
                                    <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">

                                <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-balance-scale form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" formControlName="poids" id="poidsVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="poidsVehicule" class="form__label">Poids du véhicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">

                                <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-gas-pump form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="product" formControlName="typeMoteur">
                    <option value="" disabled selected="selected">Type de carburant</option>
                      <option value="essence">Essence</option>
                      <option value="gasoil">Gasoil</option>
                      <option value="electrique">Electrique</option>
                      <option value="Hybride">Hybride</option>
                  </select>
                                    <label for="cars" class="form__label">Type de carburant<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">

                                <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-balance-scale form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                                    <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                                    <label for="place" class="form__label">Nombre de places<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">

                                <div class="form" ngbPopover="Puissance fiscale" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-bolt form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" min="5" formControlName="puissance" id="puissanceV" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="puissanceV" class="form__label">Puissance<span class="text-red"> *</span></label>
                                </div>

                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                                    Ce champ est obligatoire
                                </div>

                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">

                                <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="immatriculation" id="immatriculationV" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="immatriculationV" class="form__label">Immatriculation du véhicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">

                                <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                    <option value="" disabled selected="selected"> Marque du vehicule</option>
                    <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}</option>
                  </select>
                                    <label for="mySelect" class="form__label">Marque du vehicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule)&& simulationVehiculeForm.get('marque').valid ">

                                <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="cars" formControlName="modele">
                    <option value="" disabled selected="selected"> Modèle du vehicule</option>
                    <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}</option>
                  </select>
                                    <label for="cars" class="form__label">Modèle du vehicule<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">

                                <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateCirculation" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker"
                                        (click)="debut.toggle()" />
                                    <label for="dateCirculation" class="form__label">Date de mise en circulation<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                                    Ce champ est obligatoire
                                </div>

                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">

                                <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                                        (click)="dateEffet.toggle()" />
                                    <label for="effet" class="form__label">Date d'effet du contrat<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <div>

                                    <div class="form" placement="top" ngbPopover="Durée du contrat" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-hourglass-2 form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select placeholder=" " class="form__select" id="cars" (change)="checkDuree()" name="contract-duration" formControlName="duree">
                    <option value="" disabled selected>Durée du contrat</option>
                    <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">{{d.displayMe}}</option>
                  </select>
                                        <label for="cars" class="form__label">Durée du contrat<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('duree').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="valider()">Suivant <i
                  class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Garanties">
                    <div class="form-container c-form-container">
                        <div class="row">
                            <div class="col-12 text-center p-2 mrhgarantie">
                                <i class="fa fa-check-circle mr-2 text-success"></i>
                                <b class="c-respon">Responsabilité civile des conducteurs (Garantie par défaut)</b>
                            </div>
                        </div>
                        <div *ngIf="garanties?.length > 0 " class="row">
                            <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                                <div class="value" *ngIf="g.sousGarantie.length==0">

                                    <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                    <label for="{{g.code}}"> {{g?.nom}} </label>
                                    <span class="garantDescription" *ngIf="g.code === 'gtcom'" placement="right" [ngbPopover]="g.description"> ?</span>
                                    <span class="garantDescription" *ngIf="g.code === 'bris' && (complete || collision || plafone)" placement="right"> (Garantie gratuite)</span>
                                </div>
                                <div class="value" *ngIf="g.sousGarantie.length>0">
                                    <label for="" class="label-SG"> {{g?.nom}}</label>
                                    <div *ngFor="let sg of g.sousGarantie" class="sgGarantie">
                                        <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                        <label for="{{g.code}}"> {{sg?.nom}}</label>
                                        <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> <i class="fa fa-hand-o-right form__icon_option"></i>   <label for="{{g.description}}"> {{sg?.description}}</label></span>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                            Chargemet en cours ...
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete || collision || plafone">
                                <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-money form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (blur)="setValeurVenale()" (keyup)="onBlurEventneuf($event)" />
                                    <label for="neuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                </div>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                Ce champ est obligatoire
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                    <label for="venal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                    Ce champ est obligatoire
                                </div>
                                <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                    La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="popValue">
                        <ng-template #popValeur>
                            <div class="card" *ngIf="incendie || collision || vol || plafone || complete">
                                <div class="card-header">
                                    Veuillez renseigner le(s) champs suivant(s)
                                </div>
                                <div class="card-body">
                                    <div *ngIf="complete">
                                        <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-money form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                            <label for="neuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div *ngIf="incendie || collision || vol || plafone">
                                        <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-money form__icon c-icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                            <label for="venal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                        <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                            La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn " awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="processSimulation()"><i
                  class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i> Suivant <i
                  class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>


                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur">
                    <div class="">
                        <div class="container justify-content-center">
                            <div class="noAssureur" *ngIf="sendingRequestAssureur">
                                <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                <p class="text-center text-ass">Chargement en cours...</p>
                            </div>
                            <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.</div>
                            <ng-select *ngIf="false" class="select-ass" multiple="true" [(ngModel)]="selectedAss" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" (change)="selectAssureur()" [dropdownPosition]="'bottom'" [ngModelOptions]="{standalone: true}">
                                <ng-option *ngFor="let ass of listAssureurs" [value]="ass.id"> <img [src]="ass.img" class="img-assureur" [alt]="ass.nom"> <span class="text-assureur">{{ass.nom}}</span> </ng-option>
                            </ng-select>
                            <div class="justify-content-center">
                                <div class="row">

                                    <div class="row col-md-12 col-lg-12 col-sm-6 col-xs-12 card-assureur" *ngFor="let res of listAssureurs">


                                        <div class="row col-md-3 col-lg-3 col-sm-3 col-xs-3" id="ads" ngbPopover="Cliquer pour choisir {{res.nom}}" triggers="mouseenter:mouseleave">
                                            <div class="card rounded" (click)="processSimulation2(res)">
                                                <div class="card-image">
                                                    <img class="img-fluid" [src]="res.img" alt="{{res.nom}}" height="177px" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row col-md-2 col-lg-2 col-sm-2 col-xs-2  mb-3">

                                            <!-- <div class="row c-col6 col-6 col-lg-6"> -->
                                            <div class="c-col6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="openModalEnvoyeDevis(res)">
                                                         <i class="fa fa-phone mt-2 mr-2 c-ico" aria-hidden="true"></i>
                                                      <span>Etre rappelé</span>
                                                     </button>
                                            </div>
                                            <div class="c-col6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="processSimulation2(res)">
                                                        <i class="fa fa-cloud-download mt-2 mr-2 c-ico" aria-hidden="true"></i>
                                                        <span>Télécharger</span>
                                                        </button>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                        <div class="row mb-3 col-md-2 col-lg-2 col-sm-2 col-xs-2">

                                            <div class="c-col6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="devisParMail(res)">
                                                <i class="fa fa-envelope mt-2 mr-2 c-ico" aria-hidden="true"></i>
                                                <span>Devis par E-mail</span>
                                                </button>
                                            </div>

                                            <div class="c-col6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="devisParWatshap(res)">
                                                <i class="fa fa-whatsapp mt-2 mr-2 c-ico" aria-hidden="true"></i>
                                                <span>Devis par watsapp</span>
                                             </button>
                                            </div>
                                        </div>

                                        <div class="row col-md-3 col-lg-3 col-sm-3 col-xs-3 posiPrix">
                                            <div class="c-card-ass my-price text-center">
                                                <h6 class="nom">Prix:&nbsp; <span *ngIf="res.prix"> {{res.prix |number:'1.0-0':'fr'}} &nbsp;
                                                   FCFA</span> <span *ngIf="!res.prix" class="fa fa-spinner fa-pulse"> </span></h6>
                                            </div>
                                        </div>
                                        <div class="row col-2 col-lg-2 posiPrix">
                                            <button type="button" class="mb-3 btn btn-assureurSouscripteur c-btn-prev c-roundAssureurSouscrip" (click)="goToNext(res)">
                                                <span>Souscrire en ligne</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div class="noAssureur" *ngIf="sendingRequest">
                                    <i class="fa fa-spinner fa-pulse"></i><br>
                                    <p class="text-center">Chargement devis en cours...</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                    class="fa fa-chevron-left"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="recap-form-container" *ngIf="false" #autoDownload id="autoDownload">
                        <div class="mesImage">
                            <div class="imgLogo">
                                <img [src]="currentAssureur.img" alt="">
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title"> Proposition automobile</div>
                                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références du souscripteur1</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.email}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.telephone}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.adresse}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.profession}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.secteuractivte}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span> Références Proposition</span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.adresse}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm.get('date_effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        Identification du véhicule
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('marque').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('puissance').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        <span class="fa fa-check"></span>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                        {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('poids').value}} </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('modele').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                                        {{simulationVehiculeForm.get('valeurVenal').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="proposition2">
                                <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
                            </div>
                            <div class="row c-row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        Nature des garanties
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                {{garantie.key}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Capitaux garanties
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Franchises par sinistre
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                <span class="c-col-val-neant">Néant</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="description-ass">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                  {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.key}}</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.key}}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                                                <div class="val" *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.value | number:'1.0-0':'fr'}} FCFA</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}
                            FCFA</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="disposition">
                                <div class="title-disp">
                                    Dispositions complémentaires
                                </div>
                                <div class="desc-disp">
                                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.</div>
                                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer-devis row">
                            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                <div class="fist-line">
                                    <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                    number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                                    <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à : <b>Mermoz Pyrotechnie Lot 3</b>.
                                </div>
                                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                                    <b>{{currentAssureur.email}}</b> </div>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                <img class="footer-img" [src]="currentAssureur.img" alt="">
                            </div>
                        </div>
                        <!-- <div> -->
                        <div class="row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                                <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn">Retour
                                        <i class="fa fa-chevron-left"></i>
                                    </button>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 text-center c-btnWidth">
                                <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger
                                        <i class="fa fa-download"></i>
                                    </button>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 c-btnWidth">
                                <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn">Souscrire
                                        <i class="fa fa-angle-double-right"></i>
                                    </button>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré">
                    <div class="form-container" formGroupName="assure">
                        <div class="row assurance-for justify-content-right">

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Assuré" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-intersex form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" formControlName="choix" placeholder=" " class="form__select" name="radioFruit" (change)="setAssure(simulationVehiculeForm.get('assure').get('choix').value)">
                    <option selected value="1">Pour moi-même</option>
                    <option value="2">Pour un tiers</option>
                    <option value="3">Pour le compte de</option>
                  </select>
                                    <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                        </div>
                        <div class="row no-margin">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="prenom" id="prenom" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="nom" id="nom" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-phone form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" id="telephone" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="telephone" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-envelope form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="email" id="email" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                                    Ce champ est obligatoire ou invalide
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-intersex form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="genre">
                    <option value="" selected disabled>Sexe</option>
                    <option value="M">Masculin</option>
                    <option value="F">Féminin</option>
                  </select>
                                    <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">

                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="profession" id="profession" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="adresse" id="adresse" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="secteuractivite" id="secteur" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="secteur" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="assuree()">Suivant</button>
                        </div>
                    </div>

                </aw-wizard-step>
                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                        <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                            <div class="badge">
                                Domicile
                            </div>
                            <img width="135px" src="assets/images/domicile.jpg" alt="">
                        </div>
                        <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                            <img width="135px" src="assets/images/relais.jfif" alt="">
                            <div class="badge">
                                Point relais
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="adresseLivraison" class="form__label">Adresse<span class="text-red"> *</span></label>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                        <div class="form-group">
                            <div class="input-group relais" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                </div>
                                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                  <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                  <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                </select>

                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>

                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
                        <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                            <div class="badge">
                                {{pay?.nom}}
                            </div>
                            <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                  class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>

                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement">


                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                        <div class="mt-4 p-0 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                            <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
                            <div class="badge">
                                Domicile
                            </div>
                        </div>
                        <div class="mt-4 p-0 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">

                            <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
                            <div class="badge">
                                Point relais
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                        <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                            <i class="fas fa-map-marked-alt form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <select id="mySelect" placeholder=" " class="form__select optionRelais" formControlName="pointRelaisControl">
                <option value="" selected disabled>Sélectionner un point de relais</option>
                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
              </select>
                            <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                        <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                            <i class="fa fa-home form__icon"></i>
                            <div class="form__separator">
                            </div>
                            <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                            <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>

                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
                        <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                            <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
                            <div class="badge">
                                {{pay?.nom}}
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                  class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>


                </aw-wizard-step>


                <aw-wizard-step [navigationSymbol]="{ symbol: '6' }" stepTitle="Récapitulatif">
                    <div class="recap-form-container">
                        <div class="mesImage col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt="">
                            </div>
                            <div class="imgLogoR">
                                <img [src]="logoImage" alt="">
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title">Devis automobile</div>
                                <div class="sub-title">{{numeroSous}}</div>

                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Souscripteur</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm?.get('assure')?.get('nom')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('email')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('profession')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('secteuractivite')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.nom}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références Proposition</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModalIV()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm.get('assure').get('nom').value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        <span>Caractéristiques du véhicule</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos du véhicule" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('marque').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('puissance').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('valeurNeuf').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        <span class="fa fa-check"></span>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                        {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('poids').value}} </div>
                                                </div>
                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('modele').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                                        {{simulationVehiculeForm.get('valeurVenal').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="proposition2">
                                <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
                            </div>
                            <div class="row c-row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span> Nature des garanties</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les garanties" triggers="mouseenter:mouseleave" (click)="openModalGaran()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">

                                                {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Capitaux garanties
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Franchises par sinistre
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                <span class="c-col-val-neant">Néant</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="description-ass">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key == 'Prime RC' ? 'Responsabilité Civile' : item?.key}} (FCFA)</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC (FCFA)</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div class="val" *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.value | number:'1.0-0':'fr'}}</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="disposition row">
                                <div class="title-dispo-footerL">
                                    Assureur : {{currentAssureur?.nom}}
                                </div>
                                <div class="title-dispo-footerR">
                                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }} E-MAIL : info@optima.com
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                  class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                        </div>
                    </div>
                </aw-wizard-step>

            </aw-wizard>
        </form>
    </div>
</div>




<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/images/assets-images/entete41.jpg" alt="image">

                    <div class="text">
                        Plus de <span>10 ans</span> d'expérience
                    </div>
                    <div class="shape">
                        <img src="assets/img/dot.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>ASSURANCE VIE</h2>
                    <p>Automobiles Votre véhicule vous est indispensable ! Choisissez la formule la plus adaptée grâce à l’assistance de votre conseiller. Nous proposons les formules suivantes :<br> – Tous risques<br> – Tierce collision<br> – Tiers simple
                        ou complète.</p>
                    <a routerLink="/about" class="default-btn">Plus<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pb-70 bg-fafafa bgf">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center my-5">Nos Garanties</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let garantie of garanties">
                <div class="services-box">
                    <div class="image" *ngIf="garantie?.code == 'gtcol'">
                        <img src=" assets/images/assets-images/entete5.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'gtcom'">
                        <img src=" assets/images/assets-images/entete9.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'gtp'">
                        <img src=" assets/images/assets-images/entete7.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'gi'">
                        <img src=" assets/images/assets-images/entete8.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'gv'">
                        <img src=" assets/images/assets-images/entete10.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'gpt'">
                        <img src=" assets/images/assets-images/entete14.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'gar'">
                        <img src=" assets/images/assets-images/entete11.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'bris'">
                        <img src=" assets/images/assets-images/entete13.jpg" alt="image">
                    </div>
                    <div class="image" *ngIf="garantie?.code == 'gdf'">
                        <img src=" assets/images/assets-images/entete12.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/home-insurance">{{garantie?.nom}}</a></h3>
                        <h3>Avantages:</h3>
                        <p>{{garantie?.description}}</p>

                        <!-- <a class="mb-5"><b>Prix</b></a><br>
                        <p>{{garantie?.montantFranchise | number : '1.0-0':'fr' }} FCFA</p> -->

                        <div class="promotion-price">
                            <div class="promotion-price-desc"> À partir de </div>
                            <div class="promotion-price-text">{{garantie?.montantFranchise | number : '1.0-0':'fr' }} FCFA
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img2.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/business-insurance">Business Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/business-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img3.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/health-insurance">Health Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/health-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img4.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/travel-insurance">Travel Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/travel-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img5.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/car-insurance">Car Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/car-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="image">
                        <img src="assets/img/services-image/img6.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a routerLink="/life-insurance">Life Insurance</a></h3>
                        <p>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod incididunt sed ut incididunt labore et dolore.</p>
                        <a routerLink="/life-insurance" class="read-more-btn">Learn More <i class="flaticon-right-chevron"></i></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="find-agent-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>GROUPE INSURTECH</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3>Assurances</h3>
                                <p>Le Groupe INSURTECH possède plusieurs agences d’assurance Vie et Non Vie réparties dans 12 pays de l’Afrique Centrale et Occidentale.</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3>Banque & Finance</h3>
                                <p>Les banques du Groupe INSURTECH sont des banques commerciales de proximité. Elles offrent sur leurs marchés respectifs des solutions adaptées au grand public.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="find-agent-contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="flaticon-call"></i>
                            </div>

                            <span>Parlez à notre agent</span>
                            <a href="tel:{{constantes.TEL}}">{{constantes.TEL}}</a>
                        </li>

                        <li>
                            <cite>Ou</cite>
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>

                            <span>Envoyez-nous un e-mail</span>
                            <a href="mailto:{{constantes.EMAIL}}">{{constantes.EMAIL}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says About Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-item-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img1.jpg" alt="image">
                    <h3>David Luis</h3>
                    <span>Founder & CEO</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img3.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <span>Designer</span>
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit volupt accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae illo.</p>
                </div>

                <div class="client-info">
                    <img src="assets/img/client-image/img4.jpg" alt="image">
                    <h3>James Anderson</h3>
                    <span>Manager</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="achievements-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="achievements-content">
                    <div class="title">
                        <span class="sub-title">INSURTECH</span>
                        <h2>Nos réalisations</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-flag"></i>
                                <h3><span class="odometer" data-count="65">00</span></h3>
                                <p>Pays</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-group"></i>
                                <h3><span class="odometer" data-count="107">00</span> <span class="sign-icon">m</span></h3>
                                <p>Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-12 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-medal"></i>
                                <h3><span class="odometer" data-count="150">00</span></h3>
                                <p>Récompenses gagnantes</p>
                            </div>
                        </div>
                    </div>

                    <div class="bg-dot"><img src="assets/img/bg-dot.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="achievements-image-slides owl-carousel owl-theme">
                    <div class="single-achievements-image bg1">
                        <img src="assets/img/achievements-img1.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg2">
                        <img src="assets/img/achievements-img2.jpg" alt="image">
                    </div>

                    <div class="single-achievements-image bg3">
                        <img src="assets/img/achievements-img3.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Agent</span>
            <h2>Meet Our Experts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img2.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Lee Munroe</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img3.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Calvin Klein</h3>
                    <span>Underwriter</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img4.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Sarah Taylor</h3>
                    <span>Agent</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/img/team-image/img1.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Alastair Cook</h3>
                    <span>Risk Manager</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="partner-area bg-black-color">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/img/partner-image/img5.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Blog</span>
            <h2>News And Insights</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 14, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">2020 Insurance Trends And Possible Challenges</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 10, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Global Trends in the Life Insurance Industry</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Sep 13, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">The Best Car Insurance Companies in 2020</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/blog-right-sidebar">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="join-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="join-image text-center">
                    <img src="assets/img/woman.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="join-content">
                    <h2>Great Place to Work</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/contact" class="default-btn">Join Now <i class="flaticon-right-chevron"></i><span></span></a>
                </div>
            </div>
        </div>
    </div>
</section> -->


<!-- Modal pour la page login -->
<ng-template #contentResultlogin let-modal>
    <div class="modal-body login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <!-- <img id="logo" src="../../../../../assets/images/logo.png"> </div> -->
                                        <!-- <h6 class="msg-info">Veuillez renseigner votre email ou votre nom d'utilisateur et votre mot de passe!
                  </h6> -->
                                        <h3 class="monCompte"> Mon compte!</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <!-- <div class="form-group"> <label class="form-control-label text-muted">Nom d'utilisateur</label> <input
                      type="text" id="email" name="email" class="form-control" formControlName="username">
                    <div class="text-red ml-3"
                      *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                      Le nom d'utilisateur est requis</div>
                  </div> -->
                                    <!-- <div class="form-group "> <label class="form-control-label text-muted">Mot de passe</label> <input
                      type="password" id="psw" class="form-control" formControlName="password">
                    <div class="text-red ml-3"
                      *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                      Le mot de passe est requis</div>
                  </div> -->
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="nomUser" class="form__label">Nom d'utilisateur<span class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required">
                                        Le nom d'utilisateur est requis
                                    </div>

                                    <div class="form" ngbPopover="Mot de passe" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}</div>
                                    <div> <button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                    <!-- <div class="row justify-content-center my-2"> <a href="#" sm-text><small class="text-danger"
                        [disabled]="loaderVisible">Mot de passe oublié?</small></a> </div> -->
                                </div>
                            </div>
                            <div class="bottom text-center mb-3">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>
                        <!-- <div class="card card2">
                    <div class="my-auto mx-md-5 px-md-5 right">
                        <h3 class="text-white">We are more than just a company</h3> <small class="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                    </div>
                </div> -->
                    </div>
                </div>
            </div>
        </form>

    </div>

</ng-template>


<!-- Modal pour la sousctiption automobile -->

<ng-template #contentSouscription let-modal>
    <div class="modal-body login1" *ngIf="login">
        <!-- <div class="row desc container-row justify-content-center" *ngIf="login"> -->
        <div class="col-12 droite">
            <form [formGroup]="simulationVehiculeForm" class="auto">
                <div class="container px-4 py-5 mx-auto">
                    <div class="card card0">
                        <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                            <span aria-hidden="true">&times;</span>
                        </a>
                        <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">


                            <!-- Step 1  Vehicule-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Vehicule">
                                <div class="form-container">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                            <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                            <option value="" disabled selected>Catégorie de voiture</option>
                            <option *ngFor="let v of vehiculeCategories" [value]="v.id">{{v?.libelle}}</option>
                          </select>
                                                <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">
                                            <!-- <div class="form-group">
                                            <div class="input-group" placement="top" ngbPopover="Poids du vehicule"
                                                triggers="mouseenter:mouseleave">
                                                <div class="input-group-append">
                                                <i class="fa fa-balance-scale text-white"> <span class="text-red">*</span></i>
                                                </div>
                                                <input type="number" formControlName="poids" class="form-control" placeholder="Poids du véhicule">
                                            </div>
                                        </div> -->
                                            <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-balance-scale form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="number" formControlName="poids" id="poidsVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="poidsVehicule" class="form__label">Poids du véhicule<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Type de Carburant"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-bus text-white"> <span class="text-red">*</span></i>
                            </div>
                            <select class="form-control" name="product" formControlName="typeMoteur">
                              <option value="" disabled selected="selected">Type de carburant</option>
                              <option value="essence">Essence</option>
                              <option value="gasoil">Gasoil</option>
                              <option value="electrique">Electrique</option>
                              <option value="Hybride">Hybride</option>
                            </select>
                          </div>
                        </div> -->
                                            <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                                                <i class="fas fa-gas-pump form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="cars" placeholder=" " class="form__select" name="product" formControlName="typeMoteur">
                            <option value="" disabled selected="selected">Type de carburant</option>
                              <option value="essence">Essence</option>
                              <option value="gasoil">Gasoil</option>
                              <option value="electrique">Electrique</option>
                              <option value="Hybride">Hybride</option>
                          </select>
                                                <label for="cars" class="form__label">Type de carburant<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Nombre de places"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-users text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="number" class="form-control" formControlName="nombrePlace"
                              placeholder="Nombre de place " *ngIf="categorieVehicule && !vp" value="">
                            <input type="number" class="form-control" formControlName="nombrePlace"
                              placeholder="Nombre de place " *ngIf="categorieVehicule && vp" value="5">

                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-balance-scale form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                                                <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                                                <label for="place" class="form__label">Nombre de places<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Puissance fiscale"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-bolt text-white"><span class="text-red">*</span></i>
                            </div>
                            <input type="number" min="5" formControlName="puissance" class="form-control "
                              placeholder="Puissance">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Puissance fiscale" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-bolt form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="number" min="5" formControlName="puissance" id="puissanceV" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="puissanceV" class="form__label">Puissance<span class="text-red"> *</span></label>
                                            </div>
                                            <!-- <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('puissance').value && (simulationVehiculeForm.get('puissance').value < 1 || simulationVehiculeForm.get('puissance').value > 1000) ">
                                            La valeur doit être comprise entre 1 et 1000 ch
                                        </div> -->
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                            <!-- <div class="dateImage">
                                            <span class="dateImageText">Voir la puissance de la voiture:</span>
                                            <span class="puissanceImageNew"> Nouvelle</span>
                                            <span class="barre">|</span>
                                            <span class="puissanceImageOld"> Ancienne</span>
                                        </div> -->
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Immatriculation"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-users text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" formControlName="immatriculation"
                              placeholder="Immatriculation du véhicule">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                                                <i class="fas fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="immatriculation" id="immatriculationV" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="immatriculationV" class="form__label">Immatriculation du véhicule<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Marque du vehicule"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-car text-white"> <span class="text-red">*</span></i>
                            </div>
                            <select class="form-control" id="mySelect" formControlName="marque" (change)="marque()">
                              <option value="" disabled selected="selected"> Marque du vehicule</option>
                              <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}</option>
                            </select>
                          </div>
                        </div> -->
                                            <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select placeholder=" " class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                            <option value="" disabled selected="selected"> Marque du vehicule</option>
                            <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}</option>
                          </select>
                                                <label for="mySelect" class="form__label">Marque du vehicule<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule)&& simulationVehiculeForm.get('marque').valid ">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top"
                            ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-car text-white"> <span class="text-red">*</span></i>
                            </div>
                            <select class="form-control" formControlName="modele">
                              <option value="" disabled selected="selected"> Modèle du vehicule</option>
                              <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}</option>
                            </select>
                          </div>
                        </div> -->
                                            <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select placeholder=" " class="form__select" id="cars" formControlName="modele">
                            <option value="" disabled selected="selected"> Modèle du vehicule</option>
                            <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}</option>
                          </select>
                                                <label for="cars" class="form__label">Modèle du vehicule<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Date de mise en circulation"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today"
                              (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker"
                              (click)="debut.toggle()" placeholder="Date de mise en circulation">
                          </div>
                        </div> -->
                                            <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-calendar form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" id="dateCirculation" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker"
                                                    (click)="debut.toggle()" />
                                                <label for="dateCirculation" class="form__label">Date de mise en circulation<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                            <!-- <div class="dateImage">
                                            <span class="dateImageText">Voir la date 1ère mise en circulation:</span>
                                            <span class="dateImageNew"> Nouvelle</span>
                                            <span class="barre">|</span>
                                            <span class="dateImageOld"> Ancienne</span>
                                        </div> -->
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Date d'effet " triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today"
                              (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker"
                              [value]="date" (click)="dateEffet.toggle()" placeholder="Date d'effet du contrat">
                          </div>
                        </div> -->
                                            <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-calendar form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                                                    (click)="dateEffet.toggle()" />
                                                <label for="effet" class="form__label">Date d'effet du contrat<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <div>
                                                <!-- <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Durée du contrat"
                              triggers="mouseenter:mouseleave">
                              <div class="input-group-append">
                                <i class="fa fa-hourglass-2  text-white"> <span class="text-red">*</span></i>
                              </div>
                              <select class="form-control" (change)="checkDuree()" name="contract-duration"
                                formControlName="duree">
                                <option value="" disabled selected>Durée du contrat</option>
                                <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">{{d.displayMe}}</option>
                              </select>
                            </div>
                          </div> -->
                                                <div class="form" placement="top" ngbPopover="Durée du contrat" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-hourglass-2 form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <select placeholder=" " class="form__select" id="cars" (change)="checkDuree()" name="contract-duration" formControlName="duree">
                            <option value="" disabled selected>Durée du contrat</option>
                            <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">{{d.displayMe}}</option>
                          </select>
                                                    <label for="cars" class="form__label">Durée du contrat<span class="text-red"> *</span></label>
                                                </div>
                                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('duree').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i
                          class="fa fa-chevron-left"></i> Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="valider()">Suivant <i
                          class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </aw-wizard-step>

                            <!-- Step 2  Garanties-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Garanties">
                                <div class="form-container c-form-container">
                                    <div class="row">
                                        <div class="col-12 text-center p-2 mrhgarantie">
                                            <i class="fa fa-check-circle mr-2 text-success"></i>
                                            <b>Responsabilité civile des conducteurs (Garantie par défaut)</b>
                                        </div>
                                    </div>
                                    <div *ngIf="garanties?.length > 0 " class="row">
                                        <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                                            <div class="value" *ngIf="g.sousGarantie.length==0">
                                                <!-- <input class="inputCheck" [checked]="!g.selected" *ngIf="!g.selected" (click)="addGarantie(g, sg)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}"> -->
                                                <!-- <span placement="top" [ngbPopover]="popValeur"
                          triggers="mouseenter:mouseleave" > -->
                                                <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                                <label for="{{g.code}}"> {{g?.nom}} </label>
                                                <span class="garantDescription" *ngIf="g.code === 'gtcom'" placement="right" [ngbPopover]="g.description"> ?</span>
                                                <span class="garantDescription" *ngIf="g.code === 'bris' && (complete || collision || plafone)" placement="right"> (Garantie gratuite)</span>
                                                <!-- </span> -->
                                            </div>
                                            <div class="value" *ngIf="g.sousGarantie.length>0">
                                                <label for="" class="label-SG"> {{g?.nom}}</label>
                                                <div *ngFor="let sg of g.sousGarantie" class="sgGarantie">
                                                    <!-- <input class="inputCheck" [checked]="!g.selected" *ngIf="!g.selected" (click)="addGarantie(g, sg)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}"> -->
                                                    <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                                    <label for="{{g.code}}"> {{sg?.nom}}</label>
                                                    <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> <i class="fa fa-hand-o-right form__icon_option"></i>   <label for="{{g.description}}"> {{sg?.description}}</label></span>
                                                    <!-- <div class="descriptionOption"></div> -->
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                                        Chargemet en cours ...
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete || collision || plafone">
                                            <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-money form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (blur)="setValeurVenale()" (keyup)="onBlurEventneuf($event)" />
                                                <label for="neuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                            </div>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                            <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                                <label for="venal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                            <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                                La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popValue">
                                    <ng-template #popValeur>
                                        <div class="card" *ngIf="incendie || collision || vol || plafone || complete">
                                            <div class="card-header">
                                                Veuillez renseigner le(s) champs suivant(s)
                                            </div>
                                            <div class="card-body">
                                                <div *ngIf="complete">
                                                    <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-money form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                                        <label for="neuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div *ngIf="incendie || collision || vol || plafone">
                                                    <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-money form__icon c-icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                                        <label for="venal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                    <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                                        La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                          class="fa fa-chevron-left"></i> Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="processSimulation()"><i
                          class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i> Suivant <i
                          class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </aw-wizard-step>


                            <!-- Step 3  Assureur-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur">
                                <div class="">
                                    <div class="container justify-content-center">
                                        <div class="noAssureur" *ngIf="sendingRequestAssureur">
                                            <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                            <p class="text-center text-ass">Chargement en cours...</p>
                                        </div>
                                        <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.</div>
                                        <ng-select *ngIf="false" class="select-ass" multiple="true" [(ngModel)]="selectedAss" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" (change)="selectAssureur()" [dropdownPosition]="'bottom'" [ngModelOptions]="{standalone: true}">
                                            <ng-option *ngFor="let ass of listAssureurs" [value]="ass.id"> <img [src]="ass.img" class="img-assureur" [alt]="ass.nom"> <span class="text-assureur">{{ass.nom}}</span> </ng-option>
                                        </ng-select>
                                        <div class="justify-content-center">

                                            <!--  liste des assureurs -->
                                            <div class="row">

                                                <div class="row col-md-12 col-lg-12 col-sm-6 col-xs-12 card-assureur" *ngFor="let res of listAssureurs">

                                                    <!-- Partie Assureur -->

                                                    <div class="col-3" id="ads" ngbPopover="Cliquer pour choisir {{res.nom}}" triggers="mouseenter:mouseleave">
                                                        <div class="card rounded" (click)="processSimulation2(res)">
                                                            <div class="card-image">
                                                                <img class="img-fluid" [src]="res.img" alt="{{res.nom}}" height="177px" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- les boutons -->
                                                    <div class="col-2 mb-3">

                                                        <div class="col-6">
                                                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="openModalEnvoyeDevis(res)">
                                        <i class="fa fa-phone mt-2 mr-2" aria-hidden="true"></i>
                                        <span>Etre rappelé</span>
                                      </button>
                                                            </div>
                                                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                                <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="processSimulation2(res)">
                                      <i class="fa fa-cloud-download mt-2 mr-2" aria-hidden="true"></i>
                                      <span>Télécharger</span>
                                    </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 mb-3">
                                                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                            <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="devisParMail(res)">
                                      <i class="fa fa-envelope mt-2 mr-2" aria-hidden="true"></i>
                                      <span>Devis par E-mail</span>
                                  </button>
                                                        </div>
                                                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                                            <button type="button" class="btn btn-assureur c-btn-prev c-roundAssureur" (click)="devisParWatshap(res)">
                                      <i class="fa fa-whatsapp mt-2 mr-2" aria-hidden="true"></i>
                                      <span>Devis par watsapp</span>
                                  </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 posiPrix">
                                                        <div class="c-card-ass my-price text-center">
                                                            <h6 class="nom">Prix:&nbsp; <span *ngIf="res.prix"> {{res.prix |number:'1.0-0':'fr'}} &nbsp;
                                      FCFA</span> <span *ngIf="!res.prix" class="fa fa-spinner fa-pulse"> </span></h6>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 posiPrix">
                                                        <button type="button" class="mb-3 btn btn-assureurSouscripteur c-btn-prev c-roundAssureurSouscrip" (click)="goToNext(res)">
                                    <span>Souscrire en ligne</span>
                                </button>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="noAssureur" *ngIf="sendingRequest">
                                                <i class="fa fa-spinner fa-pulse"></i><br>
                                                <p class="text-center">Chargement devis en cours...</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                            <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                            class="fa fa-chevron-left"></i></button>
                                        </div>
                                    </div>
                                    <!-- <button type="button" class="btn btn-primary btn-phone pull-right c-roundbtn" (click)="mail()">Me faire rappeler <i
                            class="fa fa-phone c-phone"></i></button> -->
                                </div>
                                <div class="recap-form-container" *ngIf="false" #autoDownload id="autoDownload">
                                    <div class="mesImage">
                                        <div class="imgLogo">
                                            <img [src]="currentAssureur.img" alt="">
                                        </div>
                                    </div>
                                    <div class="info-devis">
                                        <div class="proposition">
                                            <div class="pro-title"> Proposition automobile</div>
                                            <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span>Références du souscripteur1</span>
                                                    <!-- (click)="modal.close('close')" -->
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.email}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.telephone}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.adresse}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.profession}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.secteuractivte}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur.nom}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span> Références Proposition</span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.adresse}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                                                        </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                                        <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Mouvement</div>
                              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Affaire nouvelle  </div>
                              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Barème</div>
                              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Temporaire  </div>
                              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type</div>
                              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Mono  </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                                <div class="title-div">
                                                    Identification du véhicule
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('marque').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('puissance').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    <span class="fa fa-check"></span>
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                                    {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('poids').value}} </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('modele').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                                                    {{simulationVehiculeForm.get('valeurVenal').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="proposition2">
                                            <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
                                        </div>
                                        <div class="row c-row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    Nature des garanties
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                            {{garantie.key}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Capitaux garanties
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                            <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Franchises par sinistre
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                            <span class="c-col-val-neant">Néant</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="description-ass">
                                            Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                          {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                            <div *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item.key}}</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.key}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                                                            <div class="val" *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item.value | number:'1.0-0':'fr'}} FCFA</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}
                                    FCFA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="disposition">
                                            <div class="title-disp">
                                                Dispositions complémentaires
                                            </div>
                                            <div class="desc-disp">
                                                La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                                                <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                                <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.</div>
                                                <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer-devis row">
                                        <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                            <div class="fist-line">
                                                <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                                                <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à : <b>Mermoz Pyrotechnie Lot 3</b>.
                                            </div>
                                            <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                                                <b>{{currentAssureur.email}}</b> </div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                            <img class="footer-img" [src]="currentAssureur.img" alt="">
                                        </div>
                                    </div>
                                    <!-- <div class="row justify-content-center">
                      <div class="col-5">
                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                            class="fa fa-chevron-left"></i> Retour</button>
                      </div>
                      <div class="col-5">
                        <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" [swal]="confirmSubscription2"
                          [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                      </div>
                    </div> -->
                                    <div>
                                        <div class="row">
                                            <div class="col-4">
                                                <!-- (click)="modal.close('close')" -->
                                                <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn"><i
                              class="fa fa-chevron-left"></i></button>
                                            </div>
                                            <div class="col-4 text-center">
                                                <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger <i
                              class="fa fa-download"></i></button>
                                            </div>
                                            <div class="col-4">
                                                <!-- (click)="modal.close('subscribe')" -->
                                                <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn">
                            Souscrire <i class="fa fa-angle-double-right"></i>
                          </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aw-wizard-step>

                            <!-- Step 4  Assuré-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré">
                                <div class="form-container" formGroupName="assure">
                                    <div class="row assurance-for justify-content-right">
                                        <!-- <h6 class="col-3">Je  souscris ?</h6> -->
                                        <!-- <div class="radio-toolbar col-12">
                        <input type="radio" id="moi" name="radioFruit" value="1" checked>
                        <label for="moi" (click)="setAssure(1)">Pour moi-même</label>
                        <input type="radio" id="tiers" name="radioFruit" value="2">
                        <label for="tiers" (click)="setAssure(2)">Pour un tiers</label>
                      </div> -->

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" placement="top" ngbPopover="Assuré" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-intersex form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="cars" formControlName="choix" placeholder=" " class="form__select" name="radioFruit" (change)="setAssure(simulationVehiculeForm.get('assure').get('choix').value)">
                            <!-- <option value="" selected disabled>Assuré</option> -->
                            <option selected value="1">Pour moi-même</option>
                            <option value="2">Pour un tiers</option>
                            <option value="3">Pour le compte de</option>
                          </select>
                                                <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row no-margin">
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" formControlName="prenom" placeholder="Prénom">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-user form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="prenom" id="prenom" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="prenom" class="form__label">Prénom<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-user form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="nom" id="nom" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Numéro de téléphone"
                            triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-phone text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" prefix="+225 " [mask]="phoneMask"
                              formControlName="numeroTelephone" placeholder="Numéro téléphone">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-phone form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" id="telephone" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="telephone" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Email" triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-envelope text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" formControlName="email" placeholder="Email ">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-envelope form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="email" id="email" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                                                Ce champ est obligatoire ou invalide
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <div class="form-group">
                            <div class="input-group" placement="top"
                              ngbPopover="Veuillez renseigner le numéro de carte d'identité" triggers="mouseenter:mouseleave"
                              popoverTitle="CNI">
                              <div class="input-group-append">
                                <i class="fa  fa-vcard text-white"> <span class="text-red">*</span></i>
                              </div>
                              <input type="text" class="form-control" [mask]="cinMask" formControlName="numeroCni"
                                placeholder="Numéro CNI">
                            </div>
                          </div>
                          <div class="error min-max text-red"
                            *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroCni').invalid">
                            Ce champ est obligatoire ou invalide
                          </div>
                        </div> -->

                                        <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <div class="form-group">
                            <div class="input-group" placement="top" ngbPopover="Veuillez renseigner date de naissance"
                              triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                              <div class="input-group-append">
                                <i class="fa fa-calendar text-white"> <span class="text-red">*</span></i>
                              </div>
                              <input type="text" class="form-control" [maxDate]="today"
                                [minDate]="{year: 1900, month: 1, day: 1}"
                                (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker
                                #dateAsss="ngbDatepicker" (click)="dateAsss.toggle()" placeholder="Date de naissance" value=""
                                name="dateNaissance">

                            </div>
                          </div>
                          <div class="error min-max text-red"
                            *ngIf="invalid && simulationVehiculeForm.get('assure').get('dateNaissance').invalid">
                            Ce champ est obligatoire
                          </div>
                        </div> -->

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-intersex form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="genre">
                            <option value="" selected disabled>Sexe</option>
                            <option value="M">Masculin</option>
                            <option value="F">Féminin</option>
                          </select>
                                                <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-user text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" formControlName="profession" placeholder="Profession">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-user form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="profession" id="profession" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="profession" class="form__label">Profession<span class="text-red"> *</span></label>
                                            </div>
                                            <!-- <div class="error min-max text-red"
                            *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                            Ce champ est obligatoire
                          </div> -->
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="top" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                            <div class="input-group-append">
                              <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                            </div>
                            <input type="text" class="form-control" formControlName="adresse" placeholder="Adresse">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-home form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="adresse" id="adresse" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="adresse" class="form__label">Adresse<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <!-- <div class="form-group">
                          <div class="input-group" placement="left" ngbPopover="Veuillez renseigner votre secteur d'activité"
                            triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                            <div class="input-group-append">
                              <i class="fa fa-briefcase text-white">
                              </i>
                            </div>
                            <input type="text" class="form-control" formControlName="secteuractivite"
                              placeholder="Secteur activité">
                          </div>
                        </div> -->
                                            <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                                <i class="fa fa-briefcase form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="secteuractivite" id="secteur" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="secteur" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                                            </div>
                                            <!-- <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('souscripteur').get('prenom').invalid">
                          Ce champ est obligatoire
                        </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                          class="fa fa-chevron-left"></i> Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="assuree()">Suivant</button>
                                    </div>
                                </div>

                            </aw-wizard-step>

                            <!-- Step 5  Paiement-->
                            <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                                    <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                        <div class="badge">
                                            Domicile
                                        </div>
                                        <img width="135px" src="assets/images/domicile.jpg" alt="">
                                    </div>
                                    <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                        <img width="135px" src="assets/images/relais.jfif" alt="">
                                        <div class="badge">
                                            Point relais
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form-group">
                                        <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-home form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                            <label for="adresseLivraison" class="form__label">Adresse<span class="text-red"> *</span></label>
                                        </div>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                                    <div class="form-group">
                                        <div class="input-group relais" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                            <div class="input-group-append">
                                                <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                            </div>
                                            <!-- <label for="email">Marque <span class="text-red">*</span></label> -->
                                            <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                          <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                          <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                        </select>
                                            <!-- <input type="text" class="form-control" formControlName="marque" placeholder="Marque voiture "> -->
                                        </div>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center">Choisir un moyen de paiement</h6>
                                    <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                                        <div class="badge">
                                            {{pay?.nom}}
                                        </div>
                                        <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                          class="fa fa-chevron-left"></i> Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                          class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>

                            </aw-wizard-step>

                            <!-- Step 5  Paiement -->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement">
                                <!-- <div class="row mb-4 justify-content-center">
                    <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                    <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                      <div class="badge" >
                        Domicile
                      </div>
                      <img width="135px" src="assets/images/domicile.jpg" alt="">
                    </div>
                    <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                      <div class="badge" >
                        Point relais
                      </div>
                      <img width="135px" src="assets/images/relais.jpg" alt="">
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais"
                       *ngIf="pointRelais?.length > 0 ||  pointRelais">
                    <div class="form-group">
                      <div class="input-group relais" placement="top" ngbPopover="Point de relais"
                           triggers="mouseenter:mouseleave">
                        <div class="input-group-append">
                          <i class="fa fa-home text-white"> <span class="text-red"> *</span></i>
                        </div>
                        <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                          <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                          <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && mrhForm.get('pointRelaisControl').invalid">
                      Ce champ est obligatoire
                    </div>
                  </div> -->
                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center">Choisir le type de livraison</h6>
                                    <div class="mt-4 p-0 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                        <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
                                        <div class="badge">
                                            Domicile
                                        </div>
                                        <!-- <img width="135px" src="assets/images/domicile.jpg" alt=""> -->
                                    </div>
                                    <div class="mt-4 p-0 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                        <!-- <i class="fa fa-check-circle mr-2 text-success"></i> -->
                                        <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
                                        <div class="badge">
                                            Point relais
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                                    <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-map-marked-alt form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="mySelect" placeholder=" " class="form__select optionRelais" formControlName="pointRelaisControl">
                        <option value="" selected disabled>Sélectionner un point de relais</option>
                        <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                      </select>
                                        <label for="mySelect" class="form__label">Point de relais<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                                    <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-home form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="adresseLivraison" class="form__label">Adresse de livraison<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center c-titlePaiement">Choisir un moyen de paiement</h6>
                                    <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                                        <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
                                        <div class="badge">
                                            {{pay?.nom}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                          class="fa fa-chevron-left"></i> Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i
                          class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>
                                <!-- <button type="button" class="btn btn-warning btn-prev pull-left"  awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i></button> -->
                                <!-- <button type="button" class="btn btn-success btn-next pull-right" [disabled]="sendingRequest" [swal]="confirmSubscription" awNextStep><i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button> -->

                            </aw-wizard-step>

                            <!-- Step 6  Récapitulatif -->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '6' }" stepTitle="Récapitulatif">
                                <div class="recap-form-container">
                                    <div class="mesImage">
                                        <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                            <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt="">
                                        </div>
                                        <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                            <img [src]="logoImage" alt="">
                                        </div>
                                    </div>
                                    <div class="info-devis">
                                        <div class="proposition">
                                            <div class="pro-title">Devis automobile</div>
                                            <!-- <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) -->
                                            <div class="sub-title">{{numeroSous}}</div>
                                            <!-- </div> -->
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span>Souscripteur</span>
                                                    <!-- (click)="modal.close('close')" -->
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm?.get('assure')?.get('nom')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('email')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('profession')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('secteuractivite')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.nom}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span>Références Proposition</span>
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModalIV()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm.get('assure').get('nom').value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                                <div class="title-div">
                                                    <span>Caractéristiques du véhicule</span>
                                                    <!-- (click)="modal.close('close')" -->
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos du véhicule" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('marque').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('puissance').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('valeurNeuf').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    <span class="fa fa-check"></span>
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                                    {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('poids').value}} </div>
                                                            </div>
                                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                                        </div>

                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('modele').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                                                    {{simulationVehiculeForm.get('valeurVenal').value}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="proposition2">
                                            <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
                                        </div>
                                        <div class="row c-row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span> Nature des garanties</span>
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les garanties" triggers="mouseenter:mouseleave" (click)="openModalGaran()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                            <!-- {{garantie.key}} -->
                                                            {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Capitaux garanties
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                            <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Franchises par sinistre
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                            <span class="c-col-val-neant">Néant</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="description-ass">
                                            Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                            <div *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key == 'Prime RC' ? 'Responsabilité Civile' : item?.key}} (FCFA)</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC (FCFA)</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                            <div class="val" *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item.value | number:'1.0-0':'fr'}}</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="disposition">
                        <div class="title-disp">
                          Dispositions complémentaires
                        </div>
                        <div class="desc-disp">
                          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au
                          disposition de l'article 13
                          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions
                            générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
                            disposition des conditions générales (CG) qui leurs sont contraires.</div>
                          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des
                            visas de l'assureur et du souscripteur. </div>
                        </div> -->
                                        <div class="disposition row">
                                            <div class="title-dispo-footerL">
                                                Assureur : {{currentAssureur?.nom}}
                                            </div>
                                            <div class="title-dispo-footerR">
                                                Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }} E-MAIL : info@optima.com
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="footer-devis row">
                      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                        <div class="fist-line">
                          <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec
                          conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                          <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le
                          code des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
                        </div>
                        <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
                          &nbsp;<b>{{currentAssureur.email}}</b> </div>
                      </div>
                      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                        <img class="footer-img" [src]="currentAssureur.img" alt="">
                      </div>
                    </div> -->

                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i
                          class="fa fa-chevron-left"></i> Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"> <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                                    </div>
                                </div>
                            </aw-wizard-step>

                        </aw-wizard>
                    </div>
                </div>
            </form>
            <!-- </div> -->
        </div>
        <!-- </div> -->

    </div>
</ng-template>



<!-- Modal pour pdf de souscription -->
<ng-template #contentResultass let-modal>
    <div class="modal-body  bg-white  autodown" #autoDownload1 id="autoDownload">
        <div class="mesImage row col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt="">
            </div>
            <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <img [src]="logoImage" alt="">
            </div>
        </div>
        <div class="info-devis container">
            <div class="proposition">
                <div class="pro-title">Devis automobile</div>
                <!-- <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div> -->
                <div class="sub-title">{{numeroSous}}</div>
            </div>
            <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span>Souscripteur</span>
                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages"
                      triggers="mouseenter:mouseleave" (click)="openModal()" (click)="modal.close('close')"></span> -->
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.firstName}} {{user?.infos?.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.email}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.telephone}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.profession}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.secteuractivte}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.nom}} </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span> Références Proposition</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>


                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                <!-- {{ simulationVehiculeForm?.get('assure')?.get('prenom')?.value}}  {{ simulationVehiculeForm?.get('assure')?.get('nom')?.value}} -->
                                {{user?.infos?.firstName}} {{user?.infos?.lastName}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.adresse}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.email}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.telephone}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{dateExpiration | date:'dd/MM/yyyy'}} à Minuit
                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm?.get('duree')?.value }} mois </div>
                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Mouvement</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Affaire nouvelle  </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Barème</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Temporaire  </div>
              <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Type</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Mono  </div> -->
                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
              <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        Caractéristiques du véhicule
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('immatriculation')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('marque')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('puissance')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur à neuf</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('valeurNeuf')?.value | number:'1.0-0':'fr'}} </div>
                                    <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel</div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                    <span class="fa fa-check"></span>
                  </div> -->
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                        {{simulationVehiculeForm?.get('typeMoteur')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('nombrePlace')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('poids')?.value}} </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value |date:'dd/MM/yyyy'}} </div>

                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('modele')?.value}}
                                    </div>
                                    <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                  <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                  </div> -->
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Valeur vénale</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">

                                        {{simulationVehiculeForm.get('valeurVenal')?.value | number:'1.0-0':'fr'}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value | date:'dd/MM/yyyy'}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> SENEGAL </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proposition2">
                <div class="pro-title"> Garanties souscrites et capitaux assures (en FCFA)</div>
            </div>
            <div class="row c-row">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        Nature des garanties
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Capitaux garanties
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                <span> {{garantie?.value |number:'1.0-0':'fr'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                    <div class="title-div">
                        Franchises par sinistre
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                <span class="c-col-val-neant">Néant</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="description-ass">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
          {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
            </div>
            <div class="row ">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                <div *ngFor="let item of primeAss">
                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key  == 'Prime RC' ? 'Responsabilité Civile' : item?.key }} FCFA</span>
                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC FCFA</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="val" *ngFor="let item of primeAss">
                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item?.value | number:'1.0-0':'fr'}}</span>
                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item?.value | number:'1.0-0':'fr'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="disposition row">
                <div class="title-dispo-footerL">
                    Assureur : {{currentAssureur?.nom}}
                </div>
                <div class="title-dispo-footerR">
                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }}
                    <span class="emailmarg"> E-MAIL : info@optima.com</span>
                </div>
                <!-- <div class="desc-disp">
          La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de
          l'article 13
          <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales
            jointes, les conditions particulières ainsi que les closes en annexe.</div>
          <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute
            disposition des conditions générales (CG) qui leurs sont contraires.</div>
          <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de
            l'assureur et du souscripteur. </div>
        </div> -->
            </div>
        </div>
        <!-- <div class="footer-devis row">
      <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
        <div class="fist-line">
          <b class="name-devis">{{currentAssureur.nom}}</b> est une <b>{{currentAssureur.typeSociete}}</b> avec conseil
          d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
            number:"1.0-0":'fr'}}</b>. Elle a été créée en <b>{{currentAssureur.anneeCreation}}</b>. NINEA
          <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code
          des assurances CIMA. Son siège est situé à <b>{{currentAssureur.adresse}}</b>.
        </div>
        <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email:
          &nbsp;<b>{{currentAssureur.email}}</b> </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
        <img class="footer-img" [src]="currentAssureur.img" alt="">
      </div>
    </div> -->
    </div>

    <!-- </div> -->
    <div *ngIf="isSouscription">
        <div class="row">
            <div class="col-4">
                <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn" (click)="modal.close('close')"><i
            class="fa fa-chevron-left"></i></button>
            </div>
            <div class="col-4 text-center">
                <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger <i
            class="fa fa-download"></i></button>
            </div>
            <div class="col-4">
                <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" (click)="modal.close('subscribe')">
          Souscrire <i class="fa fa-angle-double-right"></i>
        </button>
            </div>
        </div>
    </div>
    <div *ngIf="!isSouscription" class="btnSous">

        <button type="button" class="btn btn-primary btn-tel pull-left" (click)="openPDF()"><i
        class="fa fa-download"></i></button>

    </div>
</ng-template>


<swal #confirmSubscription2 title="Confirmation" text="Voulez-vous vraiment proceder à la souscription ?" icon="question" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SURE'" [cancelButtonText]="'NON, ANNULER'" (confirm)="saveData()">
</swal>
