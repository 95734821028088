export const APP = {
    dci:{
        logo: 'logo_2_3-06-removebg-preview.png',
        email: 'contact@dci.com',
        telephone: '+225 27 22 23 68 83',
        fax: '+225 27 22 23 68 83',
        menu:[
            {
                titre: 'Accueil',
                url: '/',
                sousMenu:[]
            },
            {
                titre: 'Conseil',
                url: '/etude-conseil-details',
                sousMenu:[]
            },
            {
                titre: 'Courtage',
                url: '/courtage-details',
                sousMenu:[]
            },
            {
                titre: 'Accompagnements',
                url: '/accompagnement',
                sousMenu:[
                    {
                        titre: 'Accompagnements en stratégie d\'entreprise',
                        url: '/accompagnement-entreprise-details'
                    },
                    {
                        titre: 'Accompagnements en processus de transformation digitale',
                        url: '/accompagnement-digital-details'
                    }
                ]
            },
            {
                titre: 'Formation',
                url: '/formation',
                sousMenu:[
                    {
                        titre: 'Parcours Management',
                        url: '/management'
                    },
                    {
                        titre: 'Parcours Communication',
                        url: '/communication'
                    },
                    {
                        titre: 'Parcours Intelligence Financière',
                        url: '/intelligence-financiere'
                    },
                    {
                        titre: 'Parcours Gestion de mutuelle sociale',
                        url: '/gestion-mutuelle-sociale'
                    },
                    {
                        titre: 'Parcours Gestion de projet',
                        url: '/gestion-projet'
                    },
                    {
                        titre: 'Parcours Informatique',
                        url: '/informatique'
                    }
                ]
            },
            {
                titre: 'Assurance',
                url: '/assurance',
                sousMenu:[
                    {
                        titre:'Souscription',
                        url:'',
                        sousMenu:[
                            {
                                titre: 'Assurance auto',
                                url: '/devis/5'
                            },
                            // {
                            //     titre: 'Assurance MRH',
                            //     url: '/devis/6'
                            // },
                            {
                                titre: 'Assurance santé',
                                url: '/devis/7'
                            },
                            {
                                titre: 'Assurance voyage',
                                url: '/devis/8'
                            }
                        ]
                    },
                    {
                        titre: 'Déclaration Sinstre',
                        url: '/declaration-sinistre',
                        // sousMenu:[]
                    }
                ]
            },
            {
                titre: 'Contact',
                url: '/contact',
                sousMenu:[]
            }
        ],
        espacePro: {
            url: 'https://bo.dci.suntelecoms.com/',
            label: 'Espace Personnel'
        },
        headers:[
            {
                active: true,
                image: '23e233ff41.jpeg',
                text:'Innover',
                sousTitre :'Digit Conseil International s’engage à rester en tête de l\'innovation technologique, afin de proposer à nos clients et partenaires des solutions novatrices et personnalisées qui répondent à leurs besoins. Notre approche résolument avant-gardiste nous permet de rester à l\'avant-garde du progrès, assurant ainsi une valeur ajoutée exceptionnelle à nos services.'
            },
            {
                active: false,
                image: '23e233ff411.png',
                text:'Conseiller',
                sousTitre :'Nous offrons des conseils stratégiques d\'experts aux entreprises, les guidant de manière éclairée dans leur prise de décision afin d\'atteindre leurs objectifs ambitieux. Notre approche avisée et notre expertise pointue vous permettront de prendre des décisions judicieuses et de saisir les opportunités qui vous propulseront vers le succès.'
            },
            {
                active: false,
                image: '23e233ff412.png',
                text:'Former',
                sousTitre :'Digit Conseil International vous aide à développez les compétences essentielles pour vous adapter avec agilité aux fluctuations du marché et libérez tout votre potentiel pour atteindre une performance optimale.'
            },
            {
                active: false,
                image: '23e233ff413.png',
                text:'Assurer',
                sousTitre :'Digit Conseil International est un spécialiste du conseil en assurance et en réassurance ; notre objectif est d\'optimiser les couvertures d\'assurance et le budget de nos clients et partenaires, en leur offrant une expertise inégalée et des solutions sur mesure pour une protection complète et une tranquillité d\'esprit totale.'
            }
        ],
        parteniares : [
            {
                image: '/assets/images/assets-images/partenaire115.jpg',
                url: 'https://unacoopec.com/'
            },
            {
                image: '/assets/images/assets-images/partenaire114.jpg',
                url: 'https://stepinter.net/'
            },
            {
                image: '/assets/images/assets-images/partenaire113.jpg',
                url: 'https://laposte.ci.post/'
            },
            {
                image: '/assets/images/assets-images/partenaire112.jpg',
                url: 'https://moon-drive.business.site/'
            },
            {
                image: '/assets/images/assets-images/partenaire111.png',
                url: 'https://baobab.com/ci/'
            }
        ],
        commentaire : [
            {
                image: '/assets/images/assets-images/entete255.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'Jonus Nathan',
                fonction: 'CEO at Envato'
            },
            {
                image: '/assets/images/assets-images/entete25555.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'Sadio Finn',
                fonction: 'CEO at FlatIcon'
            },
            {
                image: '/assets/images/assets-images/entete25.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'Tom Olivar',
                fonction: 'CEO at ThemeForest'
            },
            {
                image: '/assets/images/assets-images/entete2555.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'James Finn',
                fonction: 'CEO at GitLab'
            },
            {
                image: '/assets/images/assets-images/entete255.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'John Lucy',
                fonction: 'CEO at Linkedin'
            },
            {
                image: '/assets/images/assets-images/entete25555.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'Sarah Taylor',
                fonction: 'CEO at Twitter'
            },
            {
                image: '/assets/images/assets-images/entete25.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'James Anderson',
                fonction: 'CEO at Facebook'
            },
            {
                image: '/assets/images/assets-images/entete2555.jpg',
                text: 'Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables',
                nom: 'Steven Smith',
                fonction: 'CEO at EnvyTheme'
            }
        ],
        membres: [
            {
                time:'0.1s',
                image: 'team-1.png',
                nom: 'Vance ABISSA',
                fonction: 'CEO',
                reseauSociaux:[
                    {
                        url: 'https://www.twitter.com',
                        icon: 'fab fa-twitter'
                    },
                    {
                        url: 'https://www.facebook.com',
                        icon: 'fab fa-facebook-f'
                    },
                    {
                        url: 'https://www.youtube.com',
                        icon: 'fab fa-youtube'
                    },
                    {
                        url: 'https://www.linkedin.com',
                        icon: 'fab fa-linkedin-in'
                    }
                ]
            },
            {
                time:'0.3s',
                image: 'team-2.png',
                nom: 'Abdoulaye FALL',
                fonction: 'Directeur',
                reseauSociaux:[
                    {
                        url: 'https://www.twitter.com',
                        icon: 'fab fa-twitter'
                    },
                    {
                        url: 'https://www.facebook.com',
                        icon: 'fab fa-facebook-f'
                    },
                    {
                        url: 'https://www.youtube.com',
                        icon: 'fab fa-youtube'
                    },
                    {
                        url: 'https://www.linkedin.com',
                        icon: 'fab fa-linkedin-in'
                    }
                ]
            },
            {
                time:'0.5s',
                image: 'team-3.png',
                nom: 'Fatou NIANG',
                fonction: 'Conseillère',
                reseauSociaux:[
                    {
                        url: 'https://www.twitter.com',
                        icon: 'fab fa-twitter'
                    },
                    {
                        url: 'https://www.facebook.com',
                        icon: 'fab fa-facebook-f'
                    },
                    {
                        url: 'https://www.youtube.com',
                        icon: 'fab fa-youtube'
                    },
                    {
                        url: 'https://www.linkedin.com',
                        icon: 'fab fa-linkedin-in'
                    }
                ]
            },
            {
                time:'0.7s',
                image: 'team-4.png',
                nom: 'Yacine FAYE',
                fonction: 'Assistante',
                reseauSociaux:[
                    {
                        url: 'https://www.twitter.com',
                        icon: 'fab fa-twitter'
                    },
                    {
                        url: 'https://www.facebook.com',
                        icon: 'fab fa-facebook-f'
                    },
                    {
                        url: 'https://www.youtube.com',
                        icon: 'fab fa-youtube'
                    },
                    {
                        url: 'https://www.linkedin.com',
                        icon: 'fab fa-linkedin-in'
                    }
                ]
            }
        ],
        rendezVous:{
            titre:'',
            description:'',
            formullaire:{
                champs:[
                    {
                        type: 'text',
                        label: 'Nom',
                        placeholder: 'Votre Nom',
                        name: 'nom',
                        value: ''
                    },
                    {
                        type: 'email',
                        label: 'Email',
                        placeholder: 'Votre Email',
                        name: 'email',
                        value: ''
                    },
                    {
                        type: 'tel',
                        label: 'Téléphone',
                        placeholder: 'Votre Téléphone',
                        name: 'telephone',
                        value: ''
                    },
                    {
                        type: 'select',
                        label: 'Type de service',
                        placeholder: 'Type de service',
                        name: 'typeService',
                        value: '',
                        options:[
                            {
                                value: 'service1',
                                text: 'Service 1'
                            },
                            {
                                value: 'service2',
                                text: 'Service 2'
                            },
                            {
                                value: 'service3',
                                text: 'Service 3'
                            }
                        ]
                    },
                    {
                        type: 'textarea',
                        label: 'Message',
                        placeholder: 'Message',
                        name: 'message',
                        value: ''
                    }
                ],
            }
        },
        raisons:{
            titre: '',
            description : '',
            image: '',
            types : [
                {
                    icon: '',
                    titre: '',
                },
                {
                    icon: '',
                    titre: '',
                },
                {
                    icon: '',
                    titre: '',
                },
                {
                    icon: '',
                    titre: '',
                }
            ]
        },
        stats:{
            titre : '',
            description : '',
            chiffres : [
                {
                    titre: '',
                    nombre: ''
                },
                {
                    titre: '',
                    nombre: ''
                },
                {
                    titre: '',
                    nombre: ''
                },
                {
                    titre: '',
                    nombre: ''
                }
            ]
        },
        formations:[
            {
                url:'/management',
                nom: 'PARCOURS MANAGEMENT',
                image: 'management.png',
                description:'Une formation en management et leadership vous permet d\'acquérir certaines compétences en matière de prise de décision. Le manager apprend à évaluer les avantages et les risques afin d\'envisager les résultats possibles. Le leadership ne consiste pas seulement à diriger une équipe et à déléguer des tâches.',
                programmePedagogique: ['Découvrir les différents types de personnalités','Connaître le rôle du manager','Découvrir votre style de management','Adapter son management selon les proﬁls collaborateurs','Motiver ses collaborateurs','Fixer des objectifs','Préparer, animer et évaluer une réunion d’équipe','Savoir gérer les conﬂits','Mener un entretien'],
                objectifs: ['Se positionner dans sa fonction pour développer une réelle dimension de manager en cohérence avec la stratégie et les valeurs de son organisation ;','Manager les hommes, entrainer,motiver, responsabiliser.'],
                methodes: ['Nous évaluons les besoins de formation de votre équipe et concevons des modules adaptés.Notre équipe expérimentée réalise la formation,vous accompagnant dans le suivi. De plus, nous organisonsetanimons cohérentsavecles des besoins team-building identiﬁés pour renforcer la cohésion au sein de votre organisation.'],
                duree:['En continu sur 1 semaine ;','En séquence d’une journée','En séquence d’une demi-journée.']
            },
            {
                url:'/communication',
                nom: 'PARCOURS COMMUNICATION',
                image: 'communication.png',
                description:'La Filière Communication intègre ainsi les activités allant de l\'étude des enjeux et des publics visés jusqu\'à la mesure de l\'efficacité des actions mises en place, en passant par le conseil en stratégie de communication, la mise en œuvre de moyens, de médias, la conception et la création des actions de communication, ...',
                programmePedagogique: ['Mieux se connaitre et développer sa connaissance en soi','Identiﬁer et comprendre son mode de fonctionnement;','Préparer ses dynamiques de vie ;','Être un participant actif ;','S’affirmer sans s’imposer.','Développer sa conﬁance en soi','Développer son aisance relationnelle ;','Réussir ses interventions orales.','Découvrir la communication constructive','Le schéma de la communication (PNL) ;','Les états du Moi (AnalyseTransactionnelle) ;','Les positions de vie (Analyse Transactionnelle) ;','L’écoute active','La reformulation'],
                objectifs: ['Acquérir une meilleure connaissance de soi ;','Apprendre à utiliser de meilleures techniques pourmieux communiquer ;','Ajuster sa communication non-verbale ;','Distinguer les 5 niveaux d’écoute.','Prendre la parole en réunion avec aisance.'],
                methodes: ['Utilisation d’outils interactifs ;','Alternance d’exposés, d’étude de cas, de travaux sur documents et de jeux de rôles','Document récapitulatif de la formation'],
                duree:['En continu sur 1 semaine ;','En séquence d’une journée','En séquence d’une demi-journée.']
            },
            {
                url:'/intelligence-financiere',
                nom: 'PARCOURS INTELLIGENCE FINANCIÈRE',
                image:'finance.png',
                description:'L\'intelligence financière désigne communément la capacité des cadres et des employés à comprendre et à exécuter les principes comptables. Il est ici essentiel que les cadres et les employés non officiellement formés à la finance ou à la comptabilité comprennent néanmoins les principes financiers de base.',
                programmePedagogique: ['Familiarisation avec les familles de comptes','Construction de compte de résultat','Construction de bilan','Construction de plan de trésorerie','Construction de budget (fonctionnement \/ investissement)','Analyse de proﬁtabilité & impact sur les fonds propres'],
                objectifs: ['Renforcer les capacités des participants en gouvernance de mutuelle sociale','Renforcer les capacités des participants en gestion de mutuelle sociale','Se familiariser avec les indicateurs de performance'],
                methodes: ['Utilisation d’outils interactifs ;','Alternance d’exposés, d’étude de cas, de travaux sur documents et de jeux de rôles','Document récapitulatif de la formation'],
                duree:['En continu sur 1 semaine ;','En séquence d’une journée','En séquence d’une demi-journée.']
            },
            {
                url:'/gestion-mutuelle-sociale',
                nom:'PARCOURS GESTION DE MUTUELLE SOCIALE',
                image:'social.png',
                description:'C\'est une assurance complémentaire, qui va vous rembourser partiellement ou totalement ce que la sécurité sociale ne finance pas. La partie qui reste à votre charge après le remboursement de l\'assurance maladie obligatoire s\'appelle le ticket modérateur.',
                programmePedagogique:['Montage d’un projet de mutuelle sociale ;','Gestion de mutuelle sociale ;','Gestion financière d\'une mutuelle sociale','Conception de produits sociaux ;','Déﬁnition d’indicateurs de performance.'],
                objectifs:['Renforcer sa culture ﬁnancière ;','Développer sa capacité à analyser ﬁnancièrement une affaire ;','Faire la différence entre Bilan, Compte de Résultat, Budget, Plan de trésorerie etc.'],
                methodes:['Utilisation d’outils interactifs ;','Alternance d’exposés, d’étude de cas, de travaux sur documents et de jeux de rôles','Document récapitulatif de la formation'],
                duree:['En continu sur 1 semaine ;','En séquence d’une journée','En séquence d’une demi-journée.']
            },
            {
                url:'/gestion-projet',
                nom:'PARCOURS GESTION DE PROJET',
                image:'projet.png',
                description:'Une formation en management de projet vise à assimiler toutes les phases d\'un projet et à assurer leur mise en œuvre. Comme son nom l\'indique, la gestion de projet est avant tout liée au respect de l\'organisation, pour atteindre les résultats attendus par le client.',
                programmePedagogique:['Déﬁnition d’un arbre à problèmes/solutions','Construction du cadre logique d’un projet','Rédaction des termes de référence d’un projet','Déﬁnition de la note de cadrage d’un projet','Techniques & Outils de gestion d’un projet','Techniques & Outils de suivi-évaluation d’un projet.'],
                objectifs:['Renforcer ses capacités en montage de projet ;','Renforcer ses capacités en gestion de projet','Renforcer ses capacités suivi-évaluation de projet.'],
                methodes:['Utilisation d’outils interactifs ;','Alternance d’exposés, d’étude de cas, de travaux sur documents et de jeux de rôles','Document récapitulatif de la formation'],
                duree:['En continu sur 1 semaine ;','En séquence d’une journée','En séquence d’une demi-journée.']
            },
            {
                url:'/informatique',
                nom:'PARCOURS INFORMATIQUE',
                image:'inf.png',
                description:'L\'informatique est une discipline basée sur le traitement d\'informations via des moyens électroniques (ordinateurs, systèmes numériques). Le terme couvre deux branches : l\'une est théorique (définition de concepts et modèles), l\'autre est pratique (techniques de mise en œuvre).',
                programmePedagogique:['Conception d’un support de présentation à partir de Powerpoint ;','Microsoft Excel : base & perfectionnement ;',' Réalisation d’un publipostage.'],
                objectifs:['Renforcer ses capacités dans l’utilisation des outils du pack office ;','Apprendre à construire un tableau de bord.'],
                methodes:['Utilisation d’outils interactifs ;','Alternance d’exposés, d’étude de cas, de travaux sur documents et de jeux de rôles','Document récapitulatif de la formation'],
                duree:['En continu sur 1 semaine ;','En séquence d’une journée','En séquence d’une demi-journée.']
            }
        ],
        mission:'Chez Digit Conseil International, notre mission primordiale est d\'être le partenaire incontournable de nos clients afin de débloquer leur plein potentiel de performance. Grâce à notre approche dynamique et méthodique, nous sommes passionnément engagés à guider les entreprises vers l\'accomplissement de leurs objectifs de rentabilité et de profitabilité. Avec notre expertise inégalée et notre engagement indéfectible envers votre succès, nous sommes prêts à vous propulser vers des sommets inégalés. Faites équipe avec nous et découvrez une nouvelle ère de réussite et de croissance exponentielle',
        vision:'Notre vision audacieuse est de devenir le partenaire privilégié des entreprises du monde entier dans leur quête inlassable de performance exceptionnelle, de croissance durable et d\'adaptation continue. Nous nous efforçons de devenir le catalyseur incontesté de l\'innovation dynamique, du conseil stratégique de premier ordre, de la gestion optimisée des risques et de la formation de pointe. En collaborant avec nous, vos ambitions deviendront réalité et votre réussite sera inévitable. Rejoignez-nous et découvrez une nouvelle dimension de prospérité et d\'excellence.',
        propos:'Digit Conseil International est une entreprise spécialisée dans le conseil,l’accompagnement stratégique, l’assurance, la gestion de risques et la formation à l’échelle mondiale. Fort de notre engagement à apporter de l’innovation à forte valeur ajoutée à nos clients, nous travaillons à leur permettre d’atteindre leur plein potentiel. Notre équipe d\'experts chevronnés propose des solutions sur mesure, allant de l\'optimisation des processus à la mise en œuvre de stratégies gagnantes, aﬁn d\'assurer la réussite et la croissance durable de nos partenaires .',
        services:{
            description:'Notre processus débute par une analyse approfondie de vos besoins, nous permettant de vous comprendre pleinement. Ensuite, nous élaborons une planification stratégique précise et mettons en œuvre des solutions personnalisées. Notre engagement envers l\'excellence opérationnelle assure une prestation de services de haute qualité, répondant ainsi à vos attentes les plus élevées.',
            services:[
                {
                    icon: '',
                    titre: '',
                    description: ''
                },
                {
                    icon: '',
                    titre: '',
                    description: ''
                },
                {
                    icon: '',
                    titre: '',
                    description: ''
                },
                {
                    icon: '',
                    titre: '',
                    description: ''
                },
                {
                    icon: '',
                    titre: '',
                    description: ''
                }
            ],
        },
        valeurs:[
            {
                libelle:'Leadership',
                icon:'',
            },
            {
                libelle:'Expertise',
                icon:''
            },
            {
                libelle:'Générosité intellectuelle',
                icon:''
            },
            {
                libelle:'Éthique',
                icon:''
            },
        ]
    }
}
