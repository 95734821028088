<app-header-one></app-header-one>

<div class="home-area home-slides owl-carousel owl-theme">

    <div class="main-banner item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <h1 class="titreSlide">Assurance MRH</h1>
                        <p class="titreSlide">Une assurance habitation est une assurance destinée aux habitations de particulier et leurs annexes. Son objet principal est de couvrir les locaux, leur contenu et la responsabilité civile de ses occupants.</p>
                        <br>
                        <div class="btn-box titreSlide">
                            <a (click)="mrh('mrh')" class="default-btn btnO">Obtenir un devis<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <h1 class="titreSlide">Assurance AUTOMOBILE</h1>
                        <p class="titreSlide">La RC Auto couvre la responsabilité civile des conducteurs d'un véhicule. Concrètement, elle prend en charge les dommages corporels et matériels subis par les victimes de l'accident que vous avez causé avec votre véhicule.</p>
                        <br>
                        <div class="btn-box titreSlide">
                            <a (click)="automobile('auto')" class="default-btn btnO">Obtenir un devis<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg5">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <h1 class="titreSlide">Assurance SANTE</h1>
                        <p class="titreSlide">Elle couvre la prise en charge des frais médicaux pharmaceutiques et d’hospitalisation à la suite d’une maladie. La dite maladie étant définie comme une altération de l’état de santé dûment constatée par une autorité médicale.</p>
                        <div class="btn-box titreSlide">
                            <a (click)="sante('sante')" class="default-btn btnO">Obtenir un devis<span></span></a>
                            <a class="optional-btn souscription">Remonter une plainte<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<section class="services-boxes-area bg-f8f8f8">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="image">
                        <img class="boderImage" src="assets/images/assets-images/auto1.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a (click)="automobile('auto')" class="cTitre">Assurance Automobile</a></h3>
                        <p>
                            L'assurance automobile vous apporte un soutien financier pour les réparations et les indemnités à verser si votre responsabilité civile est engagée.
                        </p>
                        <div class="btn-box mt-3">
                            <a (click)="automobile('auto')" class="optional-btn souscription">Souscrire<span></span></a>
                        </div>

                        <div class="icon">
                            <img src="assets/images/assets-images/sedan-car-front.png" alt="image">
                            <!-- <i class="flaticon-car-insurance"></i> -->
                        </div>
                        <div class="shape">
                            <img src="assets/img/umbrella.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="image">
                        <img class="boderImage" src="assets/images/assets-images/entete2.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a (click)="sante('sante')" class="cTitre">Assurance Santé</a></h3>
                        <p>
                            Elle couvre la prise en charge des frais médicaux pharmaceutiques et d’hospitalisation à la suite d’une maladie.
                        </p>
                        <div class="btn-box mt-3">
                            <a (click)="sante('sante')" class="optional-btn souscription">Souscrire<span></span></a>
                        </div>
                        <div class="icon">
                            <img src="assets/images/assets-images/health-insurance.png" alt="image">
                        </div>
                        <div class="shape">
                            <img src="assets/img/umbrella.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="image">
                        <img class="boderImage" src="assets/images/assets-images/mrh1.jpg" alt="image">
                    </div>

                    <div class="content">
                        <h3><a (click)="mrh('mrh')" class="cTitre">Assurance Mrh</a></h3>
                        <p>
                            L'assurance Multirisque habitation ou MRH, permet de protéger l'ensemble de son patrimoine familial et vous indemnise en cas de sinistre.
                        </p>
                        <div class="btn-box mt-3">
                            <a (click)="mrh('mrh')" class="optional-btn souscription">Souscrire<span></span></a>
                        </div>

                        <div class="icon">
                            <img src="assets/images/assets-images/guarantee.png" alt="image">
                        </div>
                        <div class="shape">
                            <img src="assets/img/umbrella.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="about-area bg-f8f8f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 mt-4 mb-4">
                <div class="about-image">
                    <img src="assets/images/assets-images/test2.jpg" alt="image">
                    <img src="assets/images/assets-images/test1.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>A propos de nous</span>
                    <p>Nous croyons que l'interaction avec la marque est la clé de la communication. De vraies innovations et une expérience client positive sont au cœur d'une communication réussie. Pas de faux produits et services. Le client est roi, sa
                        vie et ses besoins sont l'inspiration.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="about-area ptb-100 bg-f8f8f8">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/images/assets-images/test2.jpg" alt="image">
                    <img src="assets/images/assets-images/test1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>Qui sommes-nous ?</span>
                    <h2 class="c-abidja">Digit Conseil International</h2>
                    <p>Digit Conseil International est un cabinet de conseil et de courtage en assurance créé en octobre 2017.
                    </p>
                    <p>Bénéficiant d’une solidité financière avérée par son appartenance à un groupe d’actionnaire privé et panafricain de notoriété internationale, nous sommes dotés d’une expertise reconnue eu égard les personnes qui l’animent et d’une
                        innovation en matière de solution d’assurance.</p>
                    <p>
                        Qui plus est, il affiche un ancrage dans le paysage des assurances en Côte d’Ivoire grâce à sa qualité de service et sa politique de challenger confirmée, lui conférant une position d’acteur de référence du marché des Assurances.
                    </p>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="about-text-box">
                        <h3>INNOVATION</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="about-text-box">
                        <h3>PROXIMITE</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3 offset-sm-3 col-sm-6">
                    <div class="about-text-box">
                        <h3>PERFORMANCE</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3 offset-sm-3 col-sm-6">
                    <div class="about-text-box">
                        <h3>CITOYENNETE</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram ctetur ipsum.</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="why-choose-us-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="why-choose-us-slides owl-carousel owl-theme">
                    <div class="why-choose-us-image bg1">
                        <img src="assets/images/assets-images/imageSlide1.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg2">
                        <img src="assets/images/assets-images/imageSlide2.jpg" alt="image">
                    </div>

                    <div class="why-choose-us-image bg3">
                        <img src="assets/images/assets-images/imageSlide2.jpg" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <div class="title">
                            <!-- <span class="sub-title">Nos Valeurs</span> -->
                            <h2>Digit Conseil International</h2>
                            <!-- <p>Choisissez votre fils nous tissons votre avenir.</p> -->
                        </div>

                        <ul class="features-list">
                            <li>
                                <div class="icon">
                                    <i class="flaticon-like"></i>
                                </div>
                                <span>Engagement</span> Digit Conseil International favorise l’implication de tous les collaborateurs dans la performance globale de l’entreprise.
                            </li>

                            <li>
                                <div class="icon">
                                    <i class="flaticon-like"></i>
                                </div>
                                <span>Professionnalisme</span> Digit Conseil International accomplit son métier avec le plus grand savoir-faire.
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-team"></i>
                                </div>
                                <span>Proximité</span> Digit Conseil International est en permanence à l’écoute de ses clients pour répondre au plus près à leurs besoins.
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-education"></i>
                                </div>
                                <span>Loyauté</span> Digit Conseil International reste fidèle à ses engagements pour mieux servir ses clients.
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-education"></i>
                                </div>
                                <span>Intégrité</span> Digit Conseil International agit avec transparence.
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-education"></i>
                                </div>
                                <span>Performance</span> Digit Conseil International rechercher en permanence à produire les meilleurs résultats.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area">
    <div class="container">
        <div class="partner-title">
            <h2 class="partenaire">Nos Partenaires</h2>
        </div>

        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire4.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire5.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire6.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire7.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire9.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire10.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire11.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire12.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire13.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire14.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire15.png" alt="image">
                </a>
            </div>
            <div class="single-partner-item">
                <a href="#" target="_blank">
                    <img src="assets/images/assets-images/partenaire16.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="temoignage">Témoignages Clients</h2>
            <p>Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables..</p>
        </div>

        <div class="feedback-item-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables.</p>
                </div>

                <div class="client-info">
                    <img src="assets/images/assets-images/entete25555.jpg" alt="image">
                    <h3>Sokhna fall</h3>
                    <!-- <span>Félicitation</span> -->
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables.</p>
                </div>

                <div class="client-info">
                    <img src="assets/images/assets-images/entete25.jpg" alt="image">
                    <h3>Fatou sylla</h3>
                    <!-- <span>Félicitation</span> -->
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables.</p>
                </div>

                <div class="client-info">
                    <img src="assets/images/assets-images/entete255.jpg" alt="image">
                    <h3>Birahim diop</h3>
                    <!-- <span>Félicitation</span> -->
                </div>
            </div>

            <div class="single-feedback-item">
                <div class="feedback-desc">
                    <p>Le Client est au coeur de toute notre démarche. Nous le considérons comme un partenaire stratégique avec lequel nous souhaitons établir et entretenir les relations les plus durables.</p>
                </div>

                <div class="client-info">
                    <img src="assets/images/assets-images/entete2555.jpg" alt="image">
                    <h3>Souleymane ndiaye</h3>
                    <!-- <span>Félicitation</span> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Feedback</span>
            <h2>What Client Says</h2>
            <h3>Mot du Directeur</h3>
        </div>

        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <div class="item">
                        <div class="single-feedback">
                            <p>Chers partenaires,<br> “C’est avec un immense plaisir que nous vous accueillons sur notre nouveau site institutionnel, pensé et conçu pour répondre à vos besoins mais aussi consolider notre relation. Ayant pour but de vous accompagner et de vous alléger des contraintes inhérentes au quotidien, nous en profitons pour mettre en évidence notre expertise, notre mission, nos réalisations, la qualité de nos services et la confiance que vous, clients et partenaires, nous témoignez. Sur ce nouveau site institutionnel, vous pouvez consulter nos différents services et désormais discuter en temps réel avec un conseiller, et recevoir votre devis en moins de 24h.”</p>
                        </div>
                    </div>

                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>

                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>

                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>

                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>

                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>

                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>

                    <div class="item">
                        <div class="single-feedback">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna. Quis ipsum suspendisse ultrices gravida.”</p>
                        </div>
                    </div>
                </div>

                <button class="prev-arrow slick-arrow">
                    <i class='flaticon-left-chevron'></i>
                </button>

                <button class="next-arrow slick-arrow">
                    <i class='flaticon-right-chevron'></i>
                </button>
            </div>

            <div class="client-thumbnails">
                <div>
                    <div class="item">
                        <div class="img-fill"><img src="assets/images/assets-images/entete25.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Jonus Nathan</h3>
                            <span>CEO at Envato</span>
                        </div>
                    </div>

                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img4.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Sadio Finn</h3>
                            <span>CEO at FlatIcon</span>
                        </div>
                    </div>

                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Tom Olivar</h3>
                            <span>CEO at ThemeForest</span>
                        </div>
                    </div>

                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        <div class="title">
                            <h3>James Finn</h3>
                            <span>CEO at GitLab</span>
                        </div>
                    </div>

                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img1.jpg" alt="client"></div>
                        <div class="title">
                            <h3>John Lucy</h3>
                            <span>CEO at Linkedin</span>
                        </div>
                    </div>

                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at Twitter</span>
                        </div>
                    </div>

                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img5.jpg" alt="client"></div>
                        <div class="title">
                            <h3>James Anderson</h3>
                            <span>CEO at Facebook</span>
                        </div>
                    </div>

                    <div class="item">
                        <div class="img-fill"><img src="assets/img/client-image/img3.jpg" alt="client"></div>
                        <div class="title">
                            <h3>Steven Smith</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="our-mission-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-image bg-1">
                    <img src="assets/img/mission-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>

                    <h3>Our Mission</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <a routerLink="/about" class="default-btn">Learn More <span></span></a>

                    <div class="shape"><img src="assets/img/bg-dot2.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-image bg-2">
                    <img src="assets/img/mission-img2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-3 col-md-6 p-0">
                <div class="mission-text">
                    <div class="icon">
                        <i class="flaticon-medal"></i>
                    </div>

                    <h3>Our History</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <a routerLink="/about" class="default-btn">Learn More <span></span></a>

                    <div class="shape"><img src="assets/img/bg-dot2.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>NOTRE RÉSEAU</h2>
            <p>Nous opérons dans dix (10) Pays de l'Afrique de l'Ouest et Centrale,
                francophone, anglophone et lusophone, avec 25 filiales dont deux bancaires ..</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-box">
                <div class="image">
                    <img src="assets/images/assets-images/entete26.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Pays:</h3>
                    <span>Responsable</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/images/assets-images/entete27.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Pays:</h3>
                    <span>Responsable</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/images/assets-images/entete28.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Pays:</h3>
                    <span>Responsable</span>
                </div>
            </div>

            <div class="single-team-box">
                <div class="image">
                    <img src="assets/images/assets-images/entete29.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="content">
                    <h3>Pays:</h3>
                    <span>Responsable</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="achievements-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="achievements-content">
                    <div class="title">
                        <span class="sub-title">INSURTECH</span>
                        <h2>Nos réalisations</h2>
                        <p>Le Groupe INSURTECH dispose d’un important réseau Assurances en Afrique de l’Ouest et Centrale, et de cinq (5) Banques en Afrique de l’Ouest. Nous affirmons notre ambition de devenir le leader de la bancassurance dans la zone CIMA par le développement de la banque de détail et par la mise en place d’un suivi de l’impact environnemental et social des projets financés et/ou assurés. Aujourd’hui, le groupe INSURTECH a pour coeur de métier l’assurance et la banque, mais exerce également dans les secteurs de la finance, l’immobilier et des technologies.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-flag"></i>
                                <h3><span class="odometer" data-count="65">00</span></h3>
                                <p>Pays</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-6 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-group"></i>
                                <h3><span class="odometer" data-count="107">00</span> <span class="sign-icon">m</span></h3>
                                <p>Clients</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12 col-sm-4">
                            <div class="single-funfact">
                                <i class="flaticon-medal"></i>
                                <h3><span class="odometer" data-count="150">00</span></h3>
                                <p>Récompenses gagnantes</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-dot"><img src="assets/img/bg-dot.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="achievements-image-slides owl-carousel owl-theme">
                    <div class="single-achievements-image bg1">
                        <img src="assets/images/assets-images/entete30.jpg" alt="image">
                    </div>
                    <div class="single-achievements-image bg2">
                        <img src="assets/images/assets-images/entete31.jpg" alt="image">
                    </div>
                    <div class="single-achievements-image bg3">
                        <img src="assets/images/assets-images/entete32.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Blog</span>
            <h2>News And Insights</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 14, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">2020 Insurance Trends And Possible Challenges</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Oct 10, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Global Trends in the Life Insurance Industry</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                        <div class="date"><i class="flaticon-timetable"></i> Sep 13, 2020</div>
                    </div>

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">The Best Car Insurance Companies in 2020</a></h3>
                        <p>Luis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <a routerLink="/blog-details" class="default-btn">Read More <span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-notes">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/blog-grid">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="join-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="join-image text-center">
                    <img src="assets/img/woman.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="join-content">
                    <h2>Great Place to Work</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/contact" class="default-btn">Join Now <i class="flaticon-right-chevron"></i><span></span></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
