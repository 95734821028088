import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-accompagnement-digital-details',
  templateUrl: './accompagnement-digital-details.component.html',
  styleUrls: ['./accompagnement-digital-details.component.scss']
})
export class AccompagnementDigitalDetailsComponent implements OnInit {
  lienBrute: any;
  constructor(private route: ActivatedRoute) {
    this.lienBrute = this.route.snapshot['_routerState'].url;
   }

  ngOnInit(): void {
  }

}
