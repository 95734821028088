import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP } from 'src/app/shared/model/model';

@Component({
  selector: 'app-parcours-formation',
  templateUrl: './parcours-formation.component.html',
  styleUrls: ['./parcours-formation.component.scss']
})
export class ParcoursFormationComponent implements OnInit {
  lienBrute: string;
  app = APP;
  formation:any;
  image: string;
  formations = this.app.dci.formations;
  constructor(private route: ActivatedRoute) {
    this.lienBrute = this.route.snapshot['_routerState'].url;
    this.formation = this.formations.find(f => f.url === this.lienBrute);
    this.image = `assets/image/img/${this.formation.image}`;
   }

  ngOnInit(): void {
  }

}
