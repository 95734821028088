export const CONSTANTES = {
    LOCALSTORAGE_NAME : 'microFinanceBo',

    TYPE_VALEUR : {
        BOOLEAN:'Boolean',
        TEXT:'Text',
        DOUBLE:'Double',
        COULEUR:'Couleur',
        IMAGE:'Image'
    },
    CODE_VALEUR : {
        LOGO_IMAGE:'logo-image',
        LOGO_IMAGE_PDF:'logo-image-pdf',
        DEFAULT_COLOR:'default-color',
        PRIMARY_COLOR:'primary-color',
        SECONDARY_COLOR:'secondary-color',
        TERTIARY_COLOR:'tertiary-color'
    },
    CODE_CLASSE : {
        COLOR_DEFAULT:'--ColorDefault',
        COLOR_PRIMARY:'--ColorPrimary',
        COLOR_SECONDARY:'--ColorSecondary',
        COLOR_TERTIARY:'--ColorTertiary'
    },
    DEFAUL_CODE_COULEUR : {
        DEFAULT:'#0090e5',
        PRIMARY:'#004d72',
        SECONDARY:'#0f172a',
        TERTIARY:'#CCCCCC'
    },

    EMAIL:'contact@dci.com',
    TEL:'+225 27 22 23 68 83',
    FAX:'+225 27 22 23 68 83',
    LOCATION:'Abidjan, Cocody Riviera Palmeraie, Immeuble le WALEBO I appartement N°12D (République de Côte d\'Ivoire).',
    EP:'https://bo.abidjanaise.suntelecoms.com/'
}
